import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";

/**
 * @typedef MommyAnimalData
 * @type {array}
 * @property AnmID {string}         ID zwierzecia
 * @property GrID {string}          ID grupy do ktorej nalezy
 * @property Piglets {number}       Ilość prosiąt
 */

/**
 * @typedef MommyData
 * @type {object}
 * @property maxPiglets {number}            maksymalna ilość prosiąt, ponieważ na lambdzie jest trudniej wyliczyć
 * @property animals {MommyAnimalData}      tablica zawierająca dane zwierzęcia
 * @property EvTime {number}                czas wykonania zdarzeniia (możliwe, że przejdzie do tablicy animals)
 * @property Comment {string}               komentarz
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowej mamki
 * @param data {MommyData}    Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createMommy(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createMommy({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createMommyNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Meblowanie",
        message: "Pomyślnie dodano prosiaki do macior",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createMommyNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Meblowanie",
        message: "Nie udało się dodać prosiąt do macior",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}