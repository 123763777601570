import NewIOT from "../NewIOT";
import {DispenserDriverCommandTypes} from "../../constans/mqttMessages";
import store from "../../store/store";
import {addNotification} from "reapop";
import {getIOTAttributes} from "../../utils/IOTUtils";
import i18next from "i18next";

export function getConfiguration(dev, onSuccess, onFailure, onSend) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_CONFIGURATION, null, null, onSuccess, onFailure, onSend);
    }
}

/**
 *
 * @param dev urządzenie
 * @param data konfiguracja urzadzenia
 * @param data.maxSingularDose - ilość paszy na maksymalne pojedyncze podanie zakres 50-1000g krok 50g
 * @param data.timeBetweenDoses - czas pomiedzy karmieniami 1-255s
 * @param data.globalLock - globabla blokada true/false
 * @param data.maxCurrentFluctuation - 0-2550 mA step 10
 * @param data.maxOverfluctuationTime - 0-2550 ms step 10
 * @param data.overloadDetection - true/false
 * @param data.autoFinish - true/false -  tutaj zawsze dajemy na false gdyz istnial/istnieje problem z dozownikami
 * uwagi: w jednej komendzie mamy ms oraz s, trzeba by to bylo ujednolicic
 * @returns {string}
 */
export function setConfiguration(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_CONFIGURATION, {
                ...data,
                autoFinish: false
            }
            , {
                loading: {
                    title: i18next.t("configuration"),
                    message: i18next.t("IOT.setConfiguration", {name: Name}),
                    status: 'loading',
                    dismissible: false,
                    dismissAfter: 0
                },
                success: {
                    message: i18next.t("IOT.setConfigurationDone", {name: Name}),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "success"
                },
                error: {
                    message: i18next.t("IOT.setConfigurationFailed", {name: Name}),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "error"
                },
                DevID: DevID
            });
    }

}

export function getParamOuts(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_PARAM_OUTS);
    }
}

/**
 * @deprecated use setAnimal from DispenserNRFFunctions
 * @param dev
 * @param data {array}
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @return {string}
 */
export function setParamOuts(dev, data, onSuccess, onFailure, hideNotification = false) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_PARAM_OUTS, data, !hideNotification ? {
            loading: {
                title: i18next.t("IOT.paramsOut"),
                message: i18next.t("IOT.setParamsOut", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setParamsOutDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setParamsOutFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }: null, onSuccess, onFailure);
    }
}

export function getBlockOuts(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_BLOCK_OUTS);
    }
}

export function setBlockOuts(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_BLOCK_OUTS, data, {
            loading: {
                title: i18next.t("IOT.outputLocks"),
                message: i18next.t("IOT.setOutputLocks", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setOutputLocksDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setOutputLocksFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function getRTC(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_RTC);
    }
}

export function setRTC(dev, dateTime) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            dateTime: dateTime
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_RTC, data, {
            loading: {
                title: i18next.t("IOT.clock"),
                message: i18next.t("IOT.setClock", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setClockDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setClockFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getMotorOvercurrents(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_MOTOR_OVERCURRENTS);
    }
}

export function setMotorOvercurrents(dev, data) {
    const {ClientID, LocalUserID, GatewayID, Name, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_MOTOR_OVERCURRENTS, data, {
            loading: {
                title: i18next.t("IOT.motorOverloads"),
                message: i18next.t("IOT.setMotorOverload", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setMotorOverloadsDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setMotorOverloadsFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function setForceLateFeedings(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_FORCE_LATE_FEEDING, data, {
            loading: {
                title: i18next.t("IOT.overdueFeeding"),
                message: i18next.t("IOT.setOverdueFeeding", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setOverdueFeedingDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setOverdueFeedingFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function getSkipDoses(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_SKIP_DOSES);
    }
}

/**
 *
 * @param dev
 * @param data {array} dosesToSkip array 6 el, number
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @returns {string}
 */
export function setSkipDoses(dev, data, onSuccess, onFailure, hideNotification = false) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_SKIP_DOSES, data, !hideNotification ? {
            loading: {
                title: i18next.t("IOT.skipDose"),
                message: i18next.t("IOT.setSkipDose", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSkipDoseDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSkipDoseFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

export function getSchedule(dev, number) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            number: number
        };
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_SCHEDULE, data);
    }
}

export function getNutritionCurve(dev, number) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            number: number
        };
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_NUTRITION_CURVE, data);
    }
}

export function startMotors(dev, data, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.START_MOTORS, data, {
            loading: {
                title: i18next.t("IOT.setMotorsTitle"),
                message: i18next.t("IOT.setMotors", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setMotorsDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setMotorsFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }

}

export function stopMotors(dev, data, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.STOP_MOTORS, data, {
            loading: {
                title: i18next.t("IOT.stoppingMotors"),
                message: i18next.t("IOT.setMotors", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.stoppedMotors", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.stopMotorsFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }

}

export function getControllerStatus(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_CONTROLLER_STATUS);
    }
}

export function getNonFeedDetails(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_NON_FEED_DETAILS);
    }
}

export function getPowerOnEvents(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_POWER_ON_EVENTS);
    }
}

export function setClearPowerOnEvents(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_CLEAR_POWER_ON_EVENTS, data, {
            loading: {
                title: i18next.t("IOT.powerOnRegister"),
                message: i18next.t("IOT.powerOnRegisterClean", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.powerOnRegisterCleanDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.powerOnRegisterCleanFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getClearPOEEvents(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_CLEAR_POE_ORDERS);
    }
}

export function getSetRTCOrders(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_SET_RTC_ORDERS);
    }
}

export function setClearFeedProblem(dev, number) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            number: number
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_CLEAR_FEED_PROBLEM, data, {
            loading: {
                title: i18next.t("feedingErrors"),
                message: i18next.t("IOT.feedingErrorsClean", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.feedingErrorsCleanDone", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.feedingErrorsCleanFailed", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setClearMotorProblem(dev, number) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            number: number
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_CLEAR_MOTOR_PROBLEM, data, {
            loading: {
                title: i18next.t("motorErrors"),
                message: i18next.t("IOT.motorErrorsClean", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.motorErrorsCleanDone", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.motorErrorsCleanFailed", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setHoldOut(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_HOLD_OUT, data, {
            loading: {
                title: i18next.t("IOT.lockPlanning"),
                message: i18next.t("IOT.setLockPlanning", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setLockPlanningDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setLockPlanningFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function getHoldOut(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_HOLD_OUT);
    }
}

export function setEmptyOutput(dev, {number=255}={}, {onSuccess, onFailure}={}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_FORCE_FEEDING, {
            number,
            dose: 25500,
            type: 0
        },  {
            loading: {
                title: i18next.t("emptyDispenser"),
                message: i18next.t("IOT.setEmptyDispenser", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setEmptyDispenserDone", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setEmptyDispenserFailed", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

/**
 *
 * @param dev
 * @param data {number: nr wyjscia, type: 0/1, dose: dawka w g}
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @returns {string}
 */
export function setForceFeedings(dev, data, onSuccess, onFailure, hideNotification = false) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_FORCE_FEEDING, data, !hideNotification ? {
            loading: {
                title: i18next.t("extraFeeding"),
                message: i18next.t("IOT.setAdditionalFeeding", {name: Name}) + i18next.t("IOT.dispenser", {number: data.number}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAdditionalFeedingDone", {name: Name}) + i18next.t("IOT.dispenser", {number: data.number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAdditionalFeedingFailed", {name: Name}) + i18next.t("IOT.dispenser", {number: data.number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }
}

export function formatFactoryConfiguration(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let id = NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.FORMAT_FACTORY_CONFIGURATION);
        let notifi = {
            id: id,
            title: i18next.t("reset"),
            message: i18next.t("IOT.resetRequestSent"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000,
            DevID: DevID
        };
        store.dispatch(addNotification(notifi))
    }

}

export function getConsumption(dev, number) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            number: number
        };
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_HOLD_OUT, data);
    }

}

export function resetConsumption(dev, number) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            number: number
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.RESET_CONSUMPTION, data, {
            loading: {
                title: i18next.t("IOT.forageConsumption"),
                message: i18next.t("IOT.resetForageConsumption", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.resetForageConsumptionDone", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.resetForageConsumptionFailed", {name: Name}) + i18next.t("IOT.dispenser", {number: number}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getDebug(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_DEBUG);
    }
}

export function getDebugIndex(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.GET_DEBUG_INDEX);
    }
}

export function eraseSchedule(dev, number) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            number: number,
            doses: [
                {
                    doseNumber: 1,
                    dailyPercentage: 0,
                    startTime: 0,
                    stopTime: 0
                }
            ]
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserDriverCommandTypes.SET_SCHEDULE, data, {
            loading: {
                title: i18next.t("IOT.dailyPlan"),
                message: i18next.t("IOT.dailyPlanReset", {number: data.number}) + i18next.t("IOT.on", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.dailyPlanResetDone", {number: data.number}) + i18next.t("IOT.on", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.dailyPlanResetFailed", {number: data.number}) + i18next.t("IOT.on", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

