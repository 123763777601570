import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import groupsDB from "../database/groupsDB";

/**
 * Metoda wysyła zapytanie o stworzenie grupy zwierząt
 * @param group.animals {Array}
 * @param farmID
 * @param clientID
 * @param localUserID
 * @param onSuccess
 * @param onFailure
 * @return {Function}
 */
export function createGroupDynamoDB(group, farmID, clientID, localUserID, onSuccess = null, onFailure = null) {
    return function (dispatch) {
        dispatch({
            type: "CREATE_GROUP_DYNAMO_DB",
            payload: invokeApig({
                ...Paths.createGroup({farmID, clientID, localUserID}),
                body: group
            })
        }).then(res => {
            onSuccess && onSuccess();
        }).catch(e => {
            onFailure && onFailure(e);
        })
    }
}

export function listGroupsDynamoDB(farmID) {
    return function (dispatch) {
        dispatch({
            type: "LIST_GROUPS_DYNAMO_DB",
            payload: invokeApig({
                ...Paths.listGroups({farmID}),
                queryParams: {DtaModTime: groupsDB.getModificationTime(farmID).DtaModTime}
            })
        }).then(async res => {
            if (res.value.length > 0) {
                await groupsDB.insertIntoGroups(res.value)
            }
        }).finally(()=>{
            dispatch(getAllGroups(farmID));
        })
    }
}

export function getAllGroups(farmID){
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_GROUPS",
            payload: groupsDB.getAllGroups(farmID)
        })
    }
}
export function updateGroupDynamoDB(group, farmID, clientID, localUserID, onSuccess = null, onFailure = null) {
    return function (dispatch) {
        dispatch({
            type: "UPDATE_GROUP_DYNAMO_DB",
            payload: invokeApig({
                ...Paths.updateGroup({farmID, clientID, localUserID}),
                body: group
            })
        }).then(res => {
            onSuccess && onSuccess();
        }).catch(e => {
            onFailure && onFailure(e);
        })
    }
}
