export function setFetching(gatewayId, isFetching = true) {
    return function (dispatch) {
        dispatch({
            type: "IOT_ALARMS_SET_FETCHING",
            payload: {gatewayId, fetching: !!isFetching}
        })
    }
}

export function addAlarm(gatewayId, alarms = []) {
    return function (dispatch) {
        dispatch({
            type: "IOT_ALARMS_ADD_ALARMS",
            payload: {gatewayId, alarms: alarms}
        })
    }
}

export function iotAlarmsReset() {
    return function (dispatch) {
        dispatch({
            type: "IOT_ALARMS_RESET"
        })

    }

}