import {invokeApig} from "../../libs/awsLib"
import Paths from './../paths';

export function debugClientEnv(data = {}) {
    return invokeApig({
        ...Paths.debugClientEnv(),
        body: {
            ...data
        }
    })
}