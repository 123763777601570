import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import _ from "lodash";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n";

export function listSettlements({FarmID, ClientID, LocalUserID} = {}) {
    console.log("listSettlements")
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.listSettlements({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {
            all: "true"
        }
    })
}

/**
 * @param data.PlcmntID {string} id komory
 * @param data
 * @param FarmID
 * @param ClientID
 * @param LocalUserID
 * @return {Promise<*|undefined>}
 */
export function createSettlement(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createSettlement({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {
            PlcmntID: data.PlcmntID,
            DtaStartTime: data.DtaStartTime
        },
        body: {
            action: "O",
            type: "F"
        }
    })
}

export function createSettlementNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("settlement"),
        message: i18n.t("apiNotifications.chamberSettlementSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createSettlementNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("settlement"),
        message: i18n.t("apiNotifications.chamberSettlementFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}

/**
 *
 * @param data.PlcmntID {string} id komory
 * @param data.PiggletCnt {number|null} ile zwierzat do odzsadzenia
 * @param data
 * @param FarmID
 * @param ClientID
 * @param LocalUserID
 * @return {Promise<*|undefined>}
 */
export function createSeparation(data = {}, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    const body = {
        action: "C",
        type: "F"
    };
    if (_.isFinite(data.PiggletCnt)) {
        body.PiggletCnt = data.PiggletCnt
    }
    return invokeApig({
        ...Paths.createSettlement({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {
            PlcmntID: data.PlcmntID
        },
        body: body
    })
}

export function createSeparationNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("separation"),
        message: i18n.t("apiNotifications.chamberSeparationSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createSeparationNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("separation"),
        message: i18n.t("apiNotifications.chamberSeparationFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}

/**
 * Funkcja do wołania na karmienu w komorze na skonczeniu etapu karmienia (?) nie mylic z innym createTransfer do przenoszenia
 * @param data.PlcmntID {string} id komory
 * @param data
 * @param FarmID
 * @param ClientID
 * @param LocalUserID
 * @return {Promise<*|undefined>}
 */
export function createTransfer(data = {}, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createSettlement({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {
            PlcmntID: data.PlcmntID
        },
        body: {
            action: "C",
            type: "F"
        }
    })
}

export function createTransferNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("transfer"),
        message: i18n.t("transferSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createTransferNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("transfer"),
        message: i18n.t("transferFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}

export function createEndInsertionNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.chamberEndInsertionTitle"),
        message: i18n.t("apiNotifications.chamberEndInsertionSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createEndInsertionNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.chamberEndInsertionTitle"),
        message: i18n.t("apiNotifications.chamberEndInsertionFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}

export function createSettlementForDevice(data = {}, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createSettlementForDevice({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: {
            ...data,
            type: "F"
        }
    })
}

export function endSettlementForDevice(data = {}, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.endSettlementForDevice({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: {
            ...data,
            type: "F"
        }
    })
}