import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";

/**
 * @typedef ParturitionData
 * @type {object}
 * @property AnmID {string}         ID zwierzęcia
 * @property EvTime {number}        Czas wykonania zdarzenia
 * @property DeadCnt {number}       Ilość martwych
 * @property HealthyCnt {number}    Ilość zdrowych
 * @property MummyCnt {number}      Ilość mumii
 * @property Weight {number}        Waga miotu
 * @property GrID {string}          ID grupy, do której należało zwierzę
 * @property comment {string}       Komentarz
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego porodu
 * @param data {[ParturitionData]}          Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createParturition(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createParturition({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function startParturition(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.startParturition({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function endParturition(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.endParturition({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createParturitionNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Poród",
        message: "Pomyślnie utworzono poród",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createParturitionNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Poród",
        message: "Nie udało się utworzyć porodu",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}

export function startParturitionNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Poród",
        message: "Pomyślnie zapisano rozpoczęcie porodu",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function startParturitionNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Poród",
        message: "Nie udało się zapisać rozpoczęcia porodu",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}

export function endParturitionNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Poród",
        message: "Pomyślnie zapisano zakończenie porodu",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function endParturitionNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Poród",
        message: "Nie udało się zapisać zakończenia porodu",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}