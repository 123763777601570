import NewIOT from "../NewIOT";
import {BridgeCommandTypes} from "../../constans/mqttMessages";
import {getIOTAttributes, transformDevID} from "../../utils/IOTUtils";
import {BridgeBackendTypes} from "../../constans/devices";
import i18next from "i18next";


export function setBeaconOff(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, transformDevID(DevID, BridgeBackendTypes.CONFIGURATION), BridgeCommandTypes.SET_BEACON_OFF, null, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.beaconOff", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.beaconOffDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.beaconOffFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setBeaconOn(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, transformDevID(DevID, BridgeBackendTypes.CONFIGURATION), BridgeCommandTypes.SET_BEACON_ON, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.beaconOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.beaconOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.beaconOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}


export function setPower(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, transformDevID(DevID, BridgeBackendTypes.CONFIGURATION), BridgeCommandTypes.SET_POWER, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setPower", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setPowerDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setPowerFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setChannel(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, transformDevID(DevID, BridgeBackendTypes.CONFIGURATION), BridgeCommandTypes.SET_CHANNEL, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setChannel", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setChannelDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setChannelFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function getBInfo(dev, onSuccess) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, BridgeCommandTypes.B_INFO, null, null, onSuccess);
    }
}

export function getBBootInfo(dev, onSuccess) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, BridgeCommandTypes.B_BOOT_INFO, null, null, onSuccess);
    }
}

export function getBBootInfoNRF(dev, onSuccess) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, BridgeCommandTypes.B_BOOT_INFO_NRF, null, null, onSuccess);
    }
}

export function getBNRFStat(dev, onSuccess) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, BridgeCommandTypes.B_NRF_STAT, null, null, onSuccess);
    }
}

export function getB485ToutR(dev, number, onSuccess) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, BridgeCommandTypes.B_485_TOUT_R, {nr: number}, null, onSuccess);
    }
}

export function getBScanAddr(dev, number, onSuccess) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, BridgeCommandTypes.B_GET_SCAN_ADDR, {nrf: number}, null, onSuccess);
    }
}

export function setBSendDozoPRG(dev, idx) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, BridgeCommandTypes.SET_B_SEND_DOZO_PRG, {idx}, {
            loading: {
                title: i18next.t("IOT.setBSendDozoPrg"),
                message: i18next.t("IOT.setBSendDozoPrgText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setBSendDozoPrgSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setBSendDozoPrgFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}