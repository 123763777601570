import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n"

export function createDevices(data, {FarmID, ClientID, LocalUserID} = {}, showSynchronizePopup = true) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createDevices({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    });
}

export function createDevicesNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.createDevicesTitle"),
        message: i18n.t("apiNotifications.createDevicesSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createDevicesNotificationFailure(response, {uniqueId = myID()} = {}) {
    console.log(response);
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.createDevicesTitle"),
        message: i18n.t("apiNotifications.createDevicesFailureGeneral"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}