import dbEvents from "../database/eventsDB";
import _ from 'lodash';
import {checkIsInsemination, getTimeToPartuition} from "../utils/RaportsUtils";
import moment from "moment";
import "moment-timezone";
import BirthHistoryWorker from "../workers/raports/birthHistory.worker"
import {checkIfUserIsService} from "../utils/NewRolesUtils";
import store from "../store/store";
import {reportStatus, reportType} from "../constans/reports";
import reportsDB from "../database/reportsDB";
import IdleDaysWorker from "../workers/raports/idleDays.worker"
import {getDaysForRepeatedInsemination} from "../utils/SettingsUtils";
import Inseminations from "../workers/raports/inseminations.worker";
import Separations from "../workers/raports/separations.worker";
import HerdStructure from "../workers/raports/herdStructure.worker";
import Selection from "../workers/raports/selection.worker";
import Dead from "../workers/raports/deaths.worker"
import Sales from "../workers/raports/sales.worker"
import Treatment from "../workers/raports/treatment.worker"
import MedicineConsumption from "../workers/raports/medicineConsumption.worker"
import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import Report from "../beans/Report";
import parse from "csv-parse"
import {addNotification} from "reapop";
import i18next from "i18next";

export function createReport(dispatch, Worker, postMessageData, type, {start = 0, stop = 0, forDay = 0} = {}, farmID) {
    let isService = checkIfUserIsService();
    let sub = store.getState().user.attributes.sub;
    let user = store.getState().user.user;
    let report = Report.createLocalReport(farmID, type, {startDate: start, endDate: stop, day: forDay});
    reportsDB.insertNewReport(report);
    dispatch(getAllReports(farmID));
    let w = new Worker();
    w.onmessage = event => {
        const {data: {status, data}} = event;
        if (status === "error") {
            reportsDB.updateReportStatus(report.FeturaQuery.FeturaQTime, [], reportStatus.FAILED);
            dispatch(getAllReports(farmID));
        } else {
            reportsDB.updateReportStatus(report.FeturaQuery.FeturaQTime, data);
            dispatch(getAllReports(farmID));
        }
    };
    if (type !== reportType.STRUCTURE) {
        w.postMessage({
            ...postMessageData,
            start, stop, FarmID: farmID, isService, sub, user
        });
    } else {
        let startDate = +moment(forDay).utc().startOf("day");
        let endDate = +moment(forDay).utc().endOf("day");
        w.postMessage({
            ...postMessageData,
            start: startDate, stop: endDate, FarmID: farmID, isService, sub, user
        });
    }
}

/**
 * Funkcja wyszykuje ostatnie inseminacje i zwraca planowane porody dla swin dla danej farm
 * @param farmID
 * @param start
 * @param stop
 * @returns {Function}
 */
export function getHistoryBirthData(farmID, start, stop) {
    return (dispatch) => {
        createReport(dispatch, BirthHistoryWorker, {}, reportType.BIRTH, {start: start, stop: stop}, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.birthReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}

/**
 * Funkcja wylicza na podstawie eventów w danym czasie zużycie konretnych leków
 * @param farmID
 * @param startDate
 * @param endDate
 * @returns {Function}
 */

export function getMedicineConsumptionData(farmID, startDate, endDate) {
    return (dispatch) => {
        createReport(dispatch, MedicineConsumption, {}, reportType.MEDICINE_CONSUMPTION, {
            start: startDate,
            stop: endDate
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.medicineConsumptionReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}

/**
 *
 * @param farmID
 * @param start
 * @param stop
 * @returns {Function}
 */
export function getIdleDaysData(farmID, start, stop) {
    return (dispatch) => {
        createReport(dispatch, IdleDaysWorker, {}, reportType.IDLE, {start: start, stop: stop}, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.idleDaysReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}


export function getSalesData(farmID, startDate, endDate) {
    return (dispatch) => {
        createReport(dispatch, Sales, {}, reportType.SALES, {start: startDate, stop: endDate}, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.idleDaysReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}

export function getTreatmentData(farmID, startDate, endDate) {
    return function (dispatch) {
        createReport(dispatch, Treatment, {}, reportType.TREATMENT, {start: startDate, stop: endDate}, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.treatmentReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}

export function getSeparationData(farmID, startDate, endDate) {
    return function (dispatch) {
        createReport(dispatch, Separations, {}, reportType.SEPARATION, {start: startDate, stop: endDate}, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.separationReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}

//todo: pozmieniac aby dzialalo na employee a nie na insID
export function getInseminationsData(farmID, start, stop) {
    return (dispatch) => {
        let daysBetweenInseminations = getDaysForRepeatedInsemination();
        createReport(dispatch, Inseminations, {daysBetweenInseminations}, reportType.INSEMINATION, {
            start: start,
            stop: stop
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.inseminationsReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}

/**
 *
 * @param farmID
 * @param day - stan na podany dzień
 * @returns {Function}
 */
export function getHerdStructureData(farmID, day) {
    return (dispatch) => {
        createReport(dispatch, HerdStructure, {}, reportType.STRUCTURE, {forDay: day}, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.herdStructureReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}

export function getExpectingBirthDataByAnm(animal) {
    try {
        //pobranie wszystkich zwierzat nba fermie
        if (animal) {
            //pobranie wszystkich eventow dla jednego zwierzecia
            let events = _.cloneDeep(dbEvents.getAllEvents4Animal(animal.AnmID));
            events.sort((o1, o2) => o2.EvTime - o1.EvTime);
            let willExpectBirt = checkIsInsemination(events, 0);
            // console.log("GExB events 4", willExpectBirt);
            if (willExpectBirt)
                return moment.tz(+willExpectBirt.EvTime, "Europe/Warsaw").startOf("day").add(getTimeToPartuition(), "days").toDate().getTime();
        }
    } catch (e) {
        console.error("ERROR RAPORTS ACTIONS", e)
    }
    return undefined;
}

export function getSelectionData(farmID, start, stop) {
    return function (dispatch) {
        createReport(dispatch, Selection, {}, reportType.SELECTION, {start: start, stop: stop}, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.selectionReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}

export function getDeadData(farmID, start, stop) {
    return function (dispatch) {
        createReport(dispatch, Dead, {}, reportType.DEAD, {start: start, stop: stop}, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.deadReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(addNotification(notifi));
    }
}

export function getAllReports(farmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_REPORTS",
            payload: reportsDB.getAllReports(farmID)
        })
    }
}

export function listAthenaReports(FarmID, ClientID, LocalUserID) {
    return function (dispatch) {
        dispatch({
            type: "LIST_ATHENA_REPORTS",
            payload: invokeApig({
                ...Paths.getReports({clientID: ClientID, localUserID: LocalUserID}),
                queryParams: {DtaModTime: reportsDB.getModificationTime().DtaModTime}
            })
        }).then(async res => {
            if (res.value.items.length > 0) {
                await reportsDB.insertAthenaReports(res.value.items);
                dispatch(getAllReports(FarmID));
            }
        })
    }
}

/**
 * Metoda wysyła prośbę o wygenerowanie raportu przez athene
 * @param type {string}                 Typ raportu (lista mozliwych: reportTypes)
 * @param startDate {number}            Czas, od którego jest generowany
 * @param endDate {number}              Czas do, którego jest generowany
 * @param farms {Array<string>}         Lista ferm, które dotyczy
 * @param ClientID {string}             ID klienta
 * @param LocalUserID {string}          ID użytkownika
 * @param additionalParams {object}     Dodatkowe parametry zapytania
 * @return {function(...[*]=)}
 */
export function createReportAthena(type, startDate, endDate, farms, ClientID, LocalUserID, additionalParams = {}) {
    return function (dispatch) {
        dispatch({
            type: "CREATE_REPORT_ATHENA",
            payload: invokeApig({
                ...Paths.createReport({clientID: ClientID, localUserID: LocalUserID}),
                body: {
                    QueryCode: type,
                    QueryParams: {
                        startDate,
                        endDate,
                        farmsArray: farms,
                        ...additionalParams
                    },
                    FeturaQTime: new Date().getTime()
                }
            })
        }).then(() => {
            const notifi = {
                title: i18next.t("reportNotifications.title"),
                message: i18next.t("reportNotifications.general"),
                status: 'success',
                dismissible: true,
                dismissAfter: 5000
            };
            dispatch(addNotification(notifi));
        })
    }
}

const cast = (value, context) => {
    if (!value) return null;
    if (context.header) return value;
    if (!isNaN(+value)) return +value;
    try {
        return JSON.parse(value);
    } catch (e) {}
    if (value.startsWith("[")) {
        value = value.replace("[", "").replace("]", "")
        let split = value.split(",");
        console.log(split);
        return split;
    }
    return value;
};

function createCSV(text) {
    return new Promise((resolve, reject) => {
        let output = [];
        try {
            const parser = parse({
                columns: true,
                cast
            });
            parser.on('readable', () => {
                let record = parser.read();
                while (record) {
                    output.push(record);
                    record = parser.read();
                }
            });
            parser.on('end', () => {
                resolve(output);
            });
            parser.write(text);
            console.log(output);
            parser.end();
        } catch (e) {
            reject(e);
        }
    })
}

export async function getReportData(LocalQTime, CreatorID, ClientID, LocalUserID) {
    let resultGetReport = await invokeApig({
        ...Paths.getReportData({clientID: ClientID, localUserID: LocalUserID}),
        body: {
            LocQTime: LocalQTime,
            CreatorID
        }
    });
    let path = resultGetReport.ResourceUrl;
    let fileRes = await fetch(path);
    let text = await fileRes.text();
    return await createCSV(text);
}