import dictionariesDB from "../database/dictionariesDB";
import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import {cutUselessPrefixes} from "../utils/DevicesUtils";
import {addNotification} from "reapop";
import _ from "lodash";
import i18next from "i18next";

let promises = [];

export function getAllDictionaries() {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_DICTIONARIES",
            payload: dictionariesDB.getAllDictionaries()
        });
    }
}


export function updateDictionaryDynamoDB(values, clientID, localUserID) {
    let _values = _.cloneDeep(values);
    let dictionaries = cutUselessPrefixes(_values);
    return function (dispatch) {
        dispatch({
            type: "UPDATE_DICTIONARY", payload: invokeApig({
                ...Paths.updateDictionaries({clientID: clientID, localUserID: localUserID}),
                body: dictionaries,
            })
        }).then(() => {
            const notify = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.successfullyUpdated"),
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(notify));
        }).catch(err => {
            const notify = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.updateErr"),
                status: 'error',
                dismissible: true,
                dismissAfter: 3000
            };
            console.error(err);
            dispatch(addNotification(notify));
        });
        dispatch({type: "UPDATE_DICTIONARY", payload: values});
    }
}

export function listDictionariesDynamoDB(clientID) {
    return function (dispatch) {
        dispatch({
            type: "LIST_DICTIONARY", payload: invokeApig({
                ...Paths.listDictionaries({clientID: clientID}),
                queryParams: {DtaModTime: dictionariesDB.getModificationTime().DtaModTime}
            })
        }).then(async res => {
            let l = {};
            Object.assign(l, res);
            if (res.value.length > 0) {
                let tmp = [];
                Object.assign(tmp, res.value);
                await tmp.forEach(item => promises.push(item));
                await dictionariesDB.insertIntoDictionaries(promises);
                promises.length = 0;
            }
            dispatch(getAllDictionaries());
        }).catch(err => {
            dispatch(getAllDictionaries());
            console.error(err);
        });
    }
}

export function listDefaultDictionariesDynamoDB(clientID) {
    return function (dispatch) {
        dispatch({
            type: "LIST_DEFAULT_DICTIONARY", payload: invokeApig({
                ...Paths.listDictionaries({clientID: "Default"}),
                method: 'GET',
                queryParams: {DtaModTime: dictionariesDB.getModificationTime().DtaModTime}
            }).then(async res => {
                let l = {};
                Object.assign(l, res);
                if (res.length > 0) {
                    let tmp = [];
                    Object.assign(tmp, res);
                    await tmp.forEach(item => promises.push(item));
                }
                dispatch(listDictionariesDynamoDB(clientID));
            }).catch(err => {
                dispatch(listDictionariesDynamoDB(clientID));
                console.error(err);
            })
        })
    }
}

export function createDictionaryDynamoDB(values, clientID, localUserID) {
    let _values = _.cloneDeep(values);
    let dictionaries = cutUselessPrefixes(_values);
    return function (dispatch) {
        dispatch({
            type: "CREATE_DICTIONARY", payload: invokeApig({
                ...Paths.createDictionaries({clientID: clientID, localUserID: localUserID}),
                body: dictionaries
            }).then(() => {
                const notify = {
                    title: i18next.t("success"),
                    message: i18next.t("popNotifications.added"),
                    status: 'success',
                    dismissible: true,
                    dismissAfter: 3000
                };
                dispatch(addNotification(notify));
            }).catch(err => {
                const notify = {
                    title: i18next.t("error"),
                    message: i18next.t("popNotifications.additionErr"),
                    status: 'error',
                    dismissible: true,
                    dismissAfter: 3000
                };
                console.error(err);
                dispatch(addNotification(notify));
            })
        });
        dispatch({type: "CREATE_DICTIONARY", payload: dictionaries});
    }
}