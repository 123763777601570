import {getEventsForAnimal, getEventsForAnimals} from "./eventsActions";
import Animal from "../beans/Animal";
import Group from "../beans/Group";
import TechnologyGroup from "../beans/TechnologyGroup";

export function changeSelectedItem(item) {
    return function (dispatch) {
        if (item instanceof Animal || item instanceof Group || item instanceof TechnologyGroup) {
            dispatch({
                type: "ANIMAL_DOCUMENTS_CHANGE_SELECTED_ITEM",
                payload: {
                    item: item
                }
            });
            if (item instanceof Animal) {
                dispatch(getEventsForAnimal(item));
            } else if (item instanceof Group) {
                dispatch(getEventsForAnimals([...item.AnmIDs, ...item.Rmvd], {meta: {AnmGrp: item.AnmGrp}}));
            } else if (item instanceof TechnologyGroup) {
                dispatch(getEventsForAnimals([...item.AnmList, ...item.Rmvd], {meta: {TGID: item.TGID}}));
            }
        } else {
            dispatch({
                type: "ANIMAL_DOCUMENTS_RESET"
            })
        }
    }
}

export function resetSelectedItem() {
    return function (dispatch) {
        dispatch({
            type: "ANIMAL_DOCUMENTS_RESET"
        })
    }
}
