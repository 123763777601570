import Api from "../localization/ApiLanguage";
import {getTranslations} from "../api/translations/GetTranslations";

export function fetchLanguage(lang) {

    return async function (dispatch) {
        dispatch({type: 'SET_LANGUAGE', payload: lang});
    }
}

export function getLanguages() {
    return {
        type: "GET_LANGUAGES", payload: Api.getLanguage()
    }
}

export function getTranslation(language, addLoading = true) {
    return function (dispatch) {
        dispatch({
            type: "GET_TRANSLATION",
            payload: getTranslations(language),
            meta: addLoading
        })
    }
}

export function getAllTranslationLanguages() {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_TRANSLATION_LANGUAGES",
            payload: getTranslations([])
        })
    }
}

export function addLanguage(language) {
    return function (dispatch) {
        dispatch({
            type: "ADD_LANGUAGE",
            payload: language
        })
    }
}

export function changeTranslation(locale, translation) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_TRANSLATION",
            payload: translation,
            meta: locale
        })
    }
}