import Moment from "moment/moment";
import {extendMoment} from 'moment-range';
import _ from "lodash";
import store from "../store/store"
import {DevType, DispenserStatus} from "../constans/devices";
import {addWorker} from "./workersActions";
import animalsDB from "../database/animalsDB"
import devicesDB from "../database/devicesDB"
import {addDispenserError} from "../utils/DevicesUtils";
import {DispenserNRFEventTypes} from "../constans/mqttMessages";
import {DispenserErrorType, DispenserViewErrors} from "../constans/dispenserViewLocalErrors";
import {SettingTypes} from "../constans/settingTypes";
import settingsDB from "../database/settingsDB";
import {checkIfPlmntIDIncludesLocationID} from "../utils/BuildingUtils";
import {checkIfUserIsService} from "../utils/NewRolesUtils";
import Worker from "../workers/prepareResultsGrid.worker"

const moment = extendMoment(Moment);

/**
 * Funkcja dispatchujaca funkcje do przetwarzania przejsc RFID na dozownikach NRF
 * @param locationID {string} id komory
 * @param shadow {object} obiekt ze stora.shadows.animals w ktorym znajduje sie mapa komor wrac z przejsciami RFID
 * @param animals {array} obiekt zwierzat
 * @param filterBy {string} path do pola po którym będziemy sortować
 * @param ascending {boolean} czy bedziemy sortowac rosnaca czy malejaco
 * @param farm {string} id fermy
 * @returns {Function}
 */
export function createFeedingGrid(locationID, shadow, animals, filterBy, ascending, farm) {
    return function (dispatch) {
        dispatch({
            type: "CREATE_FEEDING_GRID",
            payload: createFeeding(locationID, shadow, animals, filterBy, ascending, farm)
        })
    }
}

/**
 * Funkcja scrapująca dane z shadowa zwierząt dozownika NRF-RFID i zwraca ujednolicony obiekt podobny do tego co z namy z funkcji createStandingGrid
 * @param shadowValue {object | null} wartość brana ze stora z shadows.animals.get(locationID)[n] - GET_DAILY_USAGE dla pojedynczej swini - (przykładowy obiekt za duży żeby tutaj wstawić, można zobaczyć w 'dokumentacji')
 * @param chamberAnimal {null | object} obiekt zwierzęcia (taki sam jest w storze/lokim) nie przekazujemy jesli istnieje shadowValue
 * @param locationID {string} id komory w ktorej znajduje sie zwierze/przejscia rfid
 * @param hasShadow {boolean} flaga czy swinia ma shadowa zawsze true jesli shadowValue istnieje
 * @param farmID {string} id farmy
 * @returns {{feed: {percentage: number, got: number, forageAmount: number}, schedule: {scheduleName: string | null, settings: {dosesToSkip: [], scheduleNumber: number}, scheduleObj: object | null}, lastSeen: {dispenser: object | null, time: number | null}, feedingHistory: [], curve: {workingDay: number, settings: {curveNumber: number, punishment: number, doseCorrection: number, curveStart: number, curveShift: number}, eventStage: number, curveAndStageName: string | null, curveObj: object | null, curveDay: number, dayFormatted: number}, RFID: (string | null), animal: (object | null), device: object | null, feedingEfficiency: number, status: {active: (*), errors: *}, detailedHistory: []}|*}
 */
function createFeedingAnimal({shadowValue, chamberAnimal, locationID, hasShadow, farmID}) {
    let initialValue = {
        RFID: shadowValue ? shadowValue.RFID : chamberAnimal.RFID,
        animal: chamberAnimal ? chamberAnimal : undefined,
        curve: {
            settings: {
                curveNumber: 0,
                curveShift: 0,
                curveStart: +new Date(),
                doseCorrection: 0,
                punishment: 0
            },
            curveDay: 0,
            workingDay: 0,
            dayFormatted: 0,
            curveAndStageName: undefined,
            curveObj: undefined,
            eventStage: 0
        },
        feed: {
            got: 0,
            forageAmount: 0,
            percentage: 0
        },
        feedingHistory: [],
        feedingEfficiency: 0,
        schedule: {
            settings: {
                dosesToSkip: [],
                scheduleNumber: 0
            },
            scheduleObj: undefined,
            scheduleName: undefined
        },
        status: {
            active: hasShadow ? DispenserStatus.NOT_FEED : DispenserStatus.NOT_CONNECTED,
            errors: addDispenserError([], DispenserViewErrors.NO_DATA, DispenserErrorType.VIEW)
        },
        lastSeen: {
            time: undefined,
            dispenser: undefined,
        },
        device: undefined,
        detailedHistory: []
    };
    try {
        //kopiujemy domyslne wartosci i bedziemy je zmieniac do tego co przyszlo
        let animal = _.cloneDeep(initialValue);
        let active = hasShadow ? (!!shadowValue ? DispenserStatus.ACTIVE : DispenserStatus.NOT_FEED) : DispenserStatus.NOT_CONNECTED;
        let errors = [];
        //RFID
        //jesli animal nie jest z dozownika (shadowa) tylko z komory to przekazujemy chamberAnimal zamiast shadowValue
        animal.RFID = shadowValue ? shadowValue.RFID : chamberAnimal.RFID;
        //animal
        animal.animal = chamberAnimal ? chamberAnimal : animalsDB.getAnimalByRfid(animal.RFID, farmID);
        //curve
        let curveNumber = _.get(shadowValue, "curveCfg.curveNr", 0);
        let curveShift = _.get(shadowValue, "curveCfg.offset", 0);
        let doseCorrection = _.get(shadowValue, "curveCfg.percentCorrection", 0);
        let curveStart = _.get(shadowValue, "curveCfg.startTime") || +moment().startOf("day");
        let punishment = _.get(shadowValue, "curveCfg.punishment", 0);
        let eventStage = _.get(shadowValue, "curveCfg.stage", 0);
        //we filter to findGridByName associated curve by curveNumber if 0 theres no curve
        let curveObj = settingsDB.getSettingByIndexAndType(curveNumber - 1, SettingTypes.FEEDING_CURVE);
        let curveAndStageName = '';
        //days from startTime to present time, if curve starts today then its day equals 1
        let workingDay = moment().startOf("day").diff(moment(curveStart).startOf("day"), "days") + 1;
        //we set curveDay same as workingDay
        let curveDay = workingDay;
        //startTime offset
        if (curveObj) {
            //we get cruve days
            let days = _.get(curveObj, "SetData.Days", []);
            //curve day must be above 0 and below max days
            //we get isemination and partuition day from curve
            let inseminationDay = _.get(curveObj, "SetData.InseminationJumpTo", 0);
            let parturitionDay = _.get(curveObj, "SetData.ParturitionJumpTo", 0);
            switch (eventStage) {
                default:
                case DispenserNRFEventTypes.NO_EVENT:
                    if (inseminationDay && workingDay >= inseminationDay) {
                        curveDay = inseminationDay;
                    } else if (parturitionDay && workingDay > parturitionDay) {
                        curveDay = parturitionDay;
                    }
                    break;
                case DispenserNRFEventTypes.INSEMINATION:
                    curveStart = +moment(curveStart).startOf("day").subtract(inseminationDay, "days");
                    workingDay += inseminationDay;
                    curveDay += inseminationDay;

                    if (parturitionDay && workingDay >= parturitionDay) {
                        curveDay = parturitionDay;
                    }
                    break;
                case DispenserNRFEventTypes.PARTURITION:
                    curveStart = +moment(curveStart).startOf("day").subtract(parturitionDay, "days");
                    workingDay += parturitionDay;
                    curveDay += parturitionDay;
                    break;

            }
            let curveName = _.get(curveObj, "SetData.Name", `${curveNumber}`);
            let stages = _.get(curveObj, "SetData.Stages", []);
            let stageName = '';

            curveDay = curveDay > days.length ? days.length : (curveDay <= 0 ? 1 : curveDay);
            curveDay += curveShift;
            for (let stage of stages) {
                if (curveDay >= +stage.StartDay) {
                    stageName = stage.Name;
                } else {
                    break;
                }
            }
            curveAndStageName = stageName ? `${stageName}-${curveName}` : `${curveName}`;
            //getWorkingDay
            //getCurveDay
        }
        animal.curve = {
            settings: {
                curveNumber: curveNumber,
                curveShift: curveShift,
                curveStart: curveStart,
                doseCorrection: doseCorrection,
                punishment: punishment
            },
            curveDay: curveDay,
            dayFormatted: curveDay,
            workingDay: workingDay,
            curveAndStageName: curveAndStageName,
            curveObj: curveObj,
            eventStage: eventStage
        };
        //if curve is present we can get schedule
        if (animal.curve.curveObj) {
            let curveDays = _.get(animal, "curve.curveObj.SetData.Days", []);
            let scheduleNumber = _.get(curveDays, `[${curveDay - 1}.DailyPlan`);
            let scheduleObj = settingsDB.getSettingByIndexAndType(scheduleNumber, SettingTypes.FEEDING_SCHEDULE, farmID);
            let dosesToSkip = []//doses to skip now allowed in NRF/RFID (?)
            let scheduleName = _.get(scheduleObj, "SetData.Name", `${scheduleNumber + 1}`);
            if (scheduleNumber !== undefined) {
                animal.schedule = {
                    settings: {
                        dosesToSkip: dosesToSkip,
                        scheduleNumber: scheduleNumber
                    },
                    scheduleObj: scheduleObj,
                    scheduleName: scheduleName
                }
            }
        }
        //feed
        let got = _.get(shadowValue, "consumption", 0); // / 1000;
        //ten forage amount jest JUZ w gramach 2 maja 2019
        let forageAmount = (+_.get(curveObj, `SetData.Days[${curveDay - 1}].ForageAmount`, 3000) * (100 + doseCorrection)) / 100;
        let percentage = forageAmount ? ((got * 100) / forageAmount) : 0;
        animal.feed = {
            got: got,
            forageAmount: forageAmount,
            percentage: percentage
        };
        //feedingHistory
        let history = _.get(shadowValue, "history", []);
        let historyPlanned = _.get(shadowValue, "historyPlanned", []);
        let feedingHistory = [];
        //we want to have constant history of 7 days in our RFID/ANIMAL object
        //history size sent by dispenser that we use to determinate feedingEfficiency
        let historyOffset = history.length > 5 ? 5 : history.length;
        for (let i = 0; i < history.length; i++) {
            //if history is found on dispenser we use
            let g = history[i] ? history[i] : 0;
            let fa = historyPlanned[i] ? historyPlanned[i] : 3000;
            let p = fa ? (g * 100) / fa : 0;
            feedingHistory[i] = {
                got: g,
                forageAmount: fa,
                percentage: p
            }

        }
        animal.feedingHistory = feedingHistory;
        //feedingEfficiency wydajnosc z ostatnich 5 dni (dzisiaj + 5 ostatnich dni)
        animal.feedingEfficiency = (animal.feed.percentage + animal.feedingHistory.slice(0, historyOffset).reduce((a, b) => {
            return a + b.percentage
        }, 0)) / (historyOffset + 1);
        //status
        if (!animal.animal) {
            errors = addDispenserError(errors, DispenserViewErrors.RFID_NOT_FOUND_IN_SYSTEM, DispenserErrorType.VIEW);
        }
        if (animal.animal && !checkIfPlmntIDIncludesLocationID(animal.animal.PlcmntID, locationID)) {
            errors = addDispenserError(errors, DispenserViewErrors.WRONG_ANIMAL_LOCATION, DispenserErrorType.VIEW);
        }
        if (!curveNumber) {
            errors = addDispenserError(errors, DispenserViewErrors.DISPENSER_RUNS_ON_DEFAULT_CURVE, DispenserErrorType.VIEW);
        }

        _.get(shadowValue, "errors", []).forEach(alert => {
            alert.code.forEach(code => {
                errors = addDispenserError(errors, code, DispenserErrorType.VIEW)
            })
        });

        animal.status = {
            active: active,
            errors: errors
        };
        animal.lastSeen = {
            time: _.get(shadowValue, "lastFeed"),
            dispenser: _.get(shadowValue, "lastSeen") ? devicesDB.getDeviceByID(_.get(shadowValue, "lastSeen")) : undefined,
        };
        let hist = _.get(shadowValue, "dHist", []);
        animal.detailedHistory = [];
        hist.forEach(day => {
            animal.detailedHistory.push({
                got: day.d,
                time: day.t,
                percentage: 0
            })
        });
        animal.detailedHistory.sort((o1, o2) => o2.time - o1.time);

        //device
        let device = devicesDB.getDevicesInPlcmntID(locationID, {showDevicesInChildren: false}).filter(dev => dev.DevType === DevType.DISPENSER_NRF)[0];
        if (device) {
            animal.device = {
                device: device,
                type: device.DevType
            }
        }
        return animal;
    } catch (e) {
        console.error(e);
        return initialValue;
    }
}

/**
 * Funkcja do przetworzenia danych o karmianiach RFID
 * @param locationID {string} id komory
 * @param animalShadowOrig {object} obiekt ze stora.shadows.animals w ktorym znajduje sie mapa komor wrac z przejsciami RFID
 * @param animalsInPlace {array} obiekt zwierzat
 * @param filterBy {string} path do pola po którym będziemy sortować
 * @param ascending {boolean} czy bedziemy sortowac rosnaca czy malejaco
 * @param farm {string} id fermy
 * @returns {Promise} wrzuca do store.grid.feedingsMap mape gdzie kluczem jest id komory a wartosciami lista przejsc RFID zmixowana z lista zwierzat przypisana do komory
 */
function createFeeding(locationID, animalShadowOrig, animalsInPlace = [], filterBy = "RFID", ascending = true, farm = "") {
    let animalShadow = _.cloneDeep(animalShadowOrig);
    let condition = (o1, o2) => {
        let value = +_.get(o1, filterBy, -1);
        let valueNext = +_.get(o2, filterBy, -1);
        return ascending ? value - valueNext : valueNext - value;
    };
    return new Promise((resolve, reject) => {
        try {
            let array = [];
            if (animalShadow) {
                let shadow = animalShadow.get(locationID);
                if (shadow) {
                    //pobieramy tylko wartości których RFID ma 16 znaków i zaczyna się od 0
                    let animals = shadow.filter(item => item.RFID.length === 16 && item.RFID.startsWith("0"));
                    for (let shadowValue of animals) {
                        array.push(createFeedingAnimal({
                            shadowValue: shadowValue,
                            locationID: locationID,
                            hasShadow: true,
                            farmID: farm
                        }));
                    }
                }
            }
            for (let chamberAnimal of animalsInPlace) {
                if (!array.filter(animal => animal && (animal.RFID === chamberAnimal.RFID))[0]) {
                    array.push(createFeedingAnimal({
                        chamberAnimal: chamberAnimal,
                        locationID: locationID,
                        hasShadow: animalShadow && animalShadow.has(locationID),
                        farmID: farm
                    }))
                }
            }
            resolve({location: locationID, array: array.filter(item => item).sort((o1, o2) => condition(o1, o2))});
        } catch (e) {
            reject(e);
        }
    })
}

function createWorkerForResultGrid(FarmID, array, plan, before, fromTime, days, year, animalsMap) {
    return new Promise(async (resolve, reject) => {
        let state = store.getState();
        let worker = new Worker();
        worker.postMessage({
            FarmID,
            array,
            plan,
            before,
            fromTime: fromTime.toDate().getTime(),
            days,
            user: state.user.user,
            isService: checkIfUserIsService(),
            sub: state.user.attributes.sub,
            language: state.language,
            year,
            animalsMap
        });
        worker.onmessage = (event) => {
            if (event.data.type === "ERROR") reject(event.data.error);
            resolve(event.data.data);
        };
        store.dispatch(addWorker("resultGrid", worker));
    })
}

export function prepareResultsGrid(farmID, array, plan, before, fromTime, days, year, animalsMap) {
    return function (dispatch) {
        dispatch({
            type: "SET_RESULTS_GRID_DATA",
            payload: createWorkerForResultGrid(farmID, array, plan, before, fromTime, days, year, animalsMap)
        })
    }
}
