import {authUser, getCurrentUser, invokeApig, signOutUser} from "../libs/awsLib";
import db from "../database/lokiDB";
import {getCreateDay} from "./technologyGroupActions";
import NewIOT from "../IOT/NewIOT"
import {addNotification} from "reapop"
import Auth from '@aws-amplify/auth';
import store from "../store/store";
import {redirect} from "./historyActions";
import Paths from "../api/paths";
import {checkIfUserHasToSubscribe, checkIfUserIsService} from "../utils/NewRolesUtils";
import {pushMessageToServiceWorker} from "../utils/ServiceWorkerUtils";
import sha from "crypto-js/sha256";
import i18next from "i18next";
import * as UserTypes from "validators-schema/Api/constants/userTypes";
import {isFunction} from "lodash";
import {getAllUsers} from "../api/user/allUsers";

export async function getUserSuccess(res, dispatch, sub, onSuccess) {
    let key = checkIfUserIsService() ? `${sub}_${res.value.ClientID}` : sub;
    // persistent storage
    if (navigator.storage && navigator.storage.persist) {
        await navigator.storage.persist();
    }
    await db.loadDatabase(key);
    dispatch({
        type: "GET_FARMS",
        payload: res.value.FarmData
    });
    let l = {};
    if (!checkIfUserHasToSubscribe(res.value)) {
        console.log(res.value);
        if (res.value.UserType !== UserTypes.USER && res.value.UserType !== UserTypes.TRANSLATOR) {
            dispatch(getEmployees(res.value.ClientID));
        }
        dispatch(getCreateDay());
        pushMessageToServiceWorker(null, null, null, checkIfUserIsService(res.value.Roles), res.value);
        // dispatch(listDictionariesDynamoDB(res.value.ClientID))
        Object.assign(l, res);
        // console.log("res", l);
        console.log("res.value.UserType", res.value.UserType, UserTypes.TRANSLATOR, res.value.UserType === UserTypes.TRANSLATOR)
        if (res.value.UserType === UserTypes.TRANSLATOR) {
            dispatch({
                type: "REDIRECT",
                payload: "/H6FSJi7YZquPKNPaKWgb/settings/translate/edit"
            });
        }
        setTimeout(() => {
            NewIOT.startConnection();
        });
    }
    dispatch({
        type: "USER_IS_AUTHENTICATED"
    });
}

export function getUser(sub, runSuccess = true, onSuccess) {
    return function (dispatch) {
        dispatch({
            type: "GET_USER",
            payload: invokeApig({
                ...Paths.getUser(sub),
                queryParams: {}
            })
        }).then(async res => {
            if (runSuccess) {
                await getUserSuccess(res, dispatch, sub);
            }
            if (isFunction(onSuccess)) {
                dispatch(onSuccess());
            }
        }).catch(error => {
            console.error(error);
            dispatch(logoutUser());
        })
    }
}

export function fetchAllUsers(){
    return function (dispatch){
        dispatch({
            type: "FETCH_ALL_USERS",
            payload: getAllUsers()
        })
    }
}

export function authenticatUser() {
    return function (dispatch) {
        dispatch({type: "USER_AUTH", payload: authUser()}
        ).then(async res => {
            if (res.value) {
                await dispatch(getUserAttributes());
            }
        }).catch((e) => {
            console.error(e);
        })
    }
}

export function logoutUser() {
    return function (dispatch) {
        dispatch({type: "USER_LOGOUT", payload: signOutUser()});
    }
}

export function verifyUserAttribute(user, attr, code, type) {
    return async function (dispatch) {
        await dispatch({
            type: "VERIFY_EMAIL_OR_PHONE",
            payload: new Promise(async (resolve, reject) => {
                await Auth.verifyCurrentUserAttributeSubmit(attr, code).then(
                    async res => {
                        if (type === "BOTH") {
                            dispatch({
                                type: "REDIRECT",
                                payload: "/verify/phone"
                            });
                        } else {
                            sessionStorage.clear();
                            localStorage.clear();
                            dispatch({
                                type: "REDIRECT",
                                payload: "/login"
                            });
                        }
                        resolve(res);
                    }
                ).catch(e => {
                    dispatch({
                        type: "REDIRECT",
                        payload: "/login"
                    });
                    reject(e);
                })
            })
        })
    }
}

export function getUserAttributes(runGetUser = true) {
    return async function (dispatch) {
        dispatch({
            type: "GET_CURRENT_USER",
            payload: getCurrentUser()
        }).then(async res => {
            let session = await Auth.currentSession();
            console.log(session);
            console.log(res);
            let user = res.value;
            let atr = session.accessToken.payload.sub;
            const attributes = {};
            if (user) {
                if (runGetUser) {
                    await dispatch(getUser(atr, true));
                }
                attributes.login = user.username;
                attributes.name = user.attributes.name;
                attributes.email = user.attributes.email;
                attributes.phone = user.attributes.phone_number;
                attributes.address = user.attributes.address;
                attributes.locale = user.attributes.locale;
                attributes.sub = atr;
                dispatch({
                    type: "USER_GET_ATTRIBUTES_FULFILLED", payload: attributes
                });
            }
        }).catch(e => {
            console.error(e);
            signOutUser();
        })
    }
}

export function changeTemporaryPasswordRequest(userAttributes, requiredAttributes, user) {
    return function (dispatch) {
        dispatch({
            type: "USER_TEMPORARY_PASSWORD_CHANGE_REQUEST",
            payload: {
                userAttributes: userAttributes,
                requiredAttributes: requiredAttributes,
                user: user
            }
        });
    }
}

function localAuthSuccess(msg, resolve) {
    console.log("success", msg);

    resolve();
    store.dispatch({
        type: "USER_GET_ATTRIBUTES_FULFILLED",
        payload: {
            sub: msg.CAnsw.sub
        }
    });
    store.dispatch({
        type: "GET_USER_FULFILLED",
        payload: msg.CAnsw
    });
    store.dispatch({type: "MQTT_CONNECT_FULFILLED"})
    getUserSuccess({value: msg.CAnsw}, store.dispatch, msg.CAnsw.sub);
}

function localAuthError(error, msg, reject) {
    console.log("error", error, msg);

    if (error === "task_timeout") {
        reject(15);
    }

    // niepoprawne login / haslo
    if (error.code === 1) {
        reject(1)
    }

    NewIOT.endConnection();
}

export function loginUser(email, password) {
    return async (dispatch) => {
        await dispatch({
            type: "USER_LOGIN",
            payload: new Promise((resolve, reject) => {
                Auth.signIn(email, password)
                    .then(async user => {
                        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                            dispatch({
                                type: "REDIRECT",
                                payload: "/changeTemporaryPassword"
                            });
                            dispatch(changeTemporaryPasswordRequest(user.challengeParam.userAttributes, user.challengeParam.requiredAttributes, user))
                        } else if (user.challengeName === undefined) {
                            await dispatch(authenticatUser());
                        }
                        resolve();
                    })
                    .catch(async err => {
                        console.error(err);
                        switch (err.code) {
                            case "PasswordResetRequiredException":
                                dispatch({
                                    type: "USER_FORGOTTEN_PASSWORD_REQUEST_FULFILLED",
                                    payload: email
                                });
                                dispatch({
                                    type: "REDIRECT",
                                    payload: "/forgottenPassword"
                                });
                                reject(1);
                                break;
                            case "UserNotConfirmedException":
                                reject(10);
                                break;
                            case "NotAuthorizedException":
                                if (err.message.includes("disabled")) {
                                    reject(11);
                                } else if (err.message === "Incorrect username or password.") {
                                    reject(1);
                                } else {
                                    reject(0);
                                }
                                break;
                            case "UserLambdaValidationException":
                                if (err.message.includes("MaxDevice")) {
                                    reject(12)
                                }
                                reject(0);
                                break;
                            case "ResourceNotFoundException":
                                reject(13);
                                break;
                            case "NetworkError":
                                try {
                                    // proba lokalnej autoryzacji
                                    let pass = sha(password).toString();
                                    await NewIOT.localAuthUser(email, pass, (msg) => localAuthSuccess(msg, resolve), (error, msg) => localAuthError(error, msg, reject));
                                } catch (e) {
                                    console.error("ERROR in local auth", e);
                                    reject(14);
                                }
                                break;
                            default:
                                reject(0);
                        }
                    })
            })
        });
    }
}

/**
 * akcja potwierdzająca mail użytkownika na podstawie jego username'a
 * @param username - nazwa użytkownika
 * @param onSuccess - funkcja wołana, gdy weryfikacja przebiegnie poprawnie
 * @returns {Function}
 */
export function confirmUserMail(username, onSuccess) {
    return async function (dispatch) {
        await dispatch({
            type: "USER_MAIL_VERIFY",
            payload: invokeApig({
                ...Paths.verifyUserEmail(),
                body: {username: username}
            })
        }).then(() => {
            if (onSuccess) onSuccess();
        }).catch((e) => {
            console.error(e);
        })
    }
}

export function changeTemporaryPassword(values, user) {
    return function (dispatch) {
        const attr = {
            address: values.address ? values.address : " ",
            locale: values.locale ? values.locale : "pl",
            name: values.name ? values.name : " ",
            phone_number: values.phone_number,
        };

        Auth.completeNewPassword(user, values.password, attr)
            .then(() => {
                dispatch({
                    dispatch: "USER_MAIL_VERIFY",
                    payload: invokeApig({
                        ...Paths.verifyUserEmail(),
                        body: {username: user.username}
                    })
                }).then(() => {
                    dispatch({
                        type: "USER_TEMPORARY_PASSWORD_CHANGE_COMPLETE",
                        payload: null
                    });
                    window.location.reload();
                }).catch((err) => {
                    console.error(err);
                })
            }).catch((err) => {
            console.error(err);
        });
    }
}

export function forgottenPasswordRequest(username) {
    return async function (dispatch) {
        await dispatch({
            type: "USER_FORGOTTEN_PASSWORD_REQUEST",
            payload: new Promise((resolve, reject) => {
                Auth.forgotPassword(username)
                    .then(() => {
                        dispatch({
                            type: "REDIRECT",
                            payload: "/forgottenPassword"
                        });
                        resolve(username)
                    })
                    .catch(err => reject(err));
            })
        }).catch((e) => {
            console.error(e);
        })
    }
}

export function forgottenPasswordChange(username, verificationCode, password) {
    // console.log(username, verificationCode, password);
    // console.log('%c ' `${username} ${verificationCode} ${password}`, 'background: #222; color: #bada55');
    return async function (dispatch) {
        await dispatch({
            type: "USER_FORGOTTEN_PASSWORD_REQUEST",
            payload: new Promise((resolve, reject) => {
                Auth.forgotPasswordSubmit(username, verificationCode, password)
                    .then(data => {
                        dispatch({
                            type: "REDIRECT",
                            payload: "/login"
                        });
                        let mail;
                        try {
                            mail = data.CodeDeliveryDetails.Destination;
                        } catch (e) {
                            mail = "";
                        }
                        resolve(mail);
                    })
                    .catch(err => reject(err));
            })
        }).catch(e => {
            console.error(e);
        })
    }
}

export function resendConfirmationCode(username) {
    return async function (dispatch) {
        await dispatch({
            type: "USER_CONFIRMATION_CODE_REQUEST",
            payload: new Promise((resolve, reject) => {
                Auth.resendSignUp(username)
                    .then(() => {
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    });
            })
        }).catch((e) => {
            console.error("2catch", e);
        })
    }
}

export function confirmUserAccount(username, code) {
    return async function (dispatch) {
        await dispatch({
            type: "USER_CONFIRMATION_ACCOUNT",
            payload: new Promise((resolve, reject) => {
                Auth.confirmSignUp(username, code)
                    .then(() => {
                        resolve();
                    })
                    .catch(e => {
                        reject(e);
                    });
            })
        }).catch((e) => {
            console.error(e);
        })
    }
}

/**
 * Funkcja do pobierania użytkowników do których mamy dostęp
 * @store user.fetchingEmployees {boolean} flaga która mówi o pobieraniu userów
 * @store user.employees {object[]} tablica userów które się wypełni po pomyślnym wykonaniu funkcji
 * @param ClientID
 * @returns {Function}
 */
export function getEmployees(ClientID) {
    return function (dispatch) {
        dispatch({
                type: "GET_USER_EMPLOYEES", payload: invokeApig({
                    ...Paths.listUser({clientID: ClientID})
                })
            }
        ).then(async () => {
            //console.log("User employees GET success: ", res);
        }).catch((e) => {
            console.error("User employees GET error: ", e);
        });
    }
}

/**
 * Funkcja do updatowania przywileji (słowa kluczowe: role, autoryzacja, przywileje)
 * @store user.updatingAuthorizations {boolean} flaga czy przywileje są w trakcie zapisu na dynamoDB
 * @param values {object} role przykład:
 *     {
 *        "LocalUserID": "TestRoleLocalUser",
 *        "roles": [
 *          {
 *            "Role": "GENERAL_READ",
 *            "LocalRights": [
 *              {
 *                "FarmID": "TestNewRoleFarm"
 *              },
 *              {
 *                "FarmID": "TestNewRoleFarm1"
 *              },
 *              {
 *                "FarmID": "TestNewRoleFarm2"
 *              }
 *            ]
 *          },
 *          .
 *          .
 *          .
 *          {
 *            "Role": "DEVICE_DI_WRITE",
 *            "LocalRights": [
 *              {
 *                "FarmID": "TestNewRoleFarm1"
 *              }
 *            ]
 *          },
 *          {
 *            "Role": "DEVICE_DI_READ",
 *            "LocalRights": [
 *              {
 *                "FarmID": "TestNewRoleFarm1"
 *              }
 *            ]
 *          }
 *        ]
 *      }
 * }
 * @param ClientID {string} id klienta
 * @param LocalUserID {string} id usera któremu się edytuje role
 * @param [onSuccess] {Function} funkcja która się wykona po pomyślnej edcyji użytkownika
 * @returns {Function}
 */
export function updateAuthorizations(values, ClientID, LocalUserID, onSuccess) {
    return function (dispatch) {
        dispatch({
                type: "UPDATE_AUTHORIZATIONS", payload: invokeApig({
                    ...Paths.updateUser({clientID: ClientID, localUserID: LocalUserID}),
                    method: "PUT",
                    body: values
                })
            }
        ).then(() => {
                let notifi = {
                    title: i18next.t("popNotifications.editUser"),
                    message: i18next.t("popNotifications.successful"),
                    status: 'success',
                    dismissible: true,
                    dismissAfter: 3000,
                };
                store.dispatch(addNotification(notifi));
                if (onSuccess) onSuccess();
            }
        ).catch(() => {
            let notifi = {
                title: i18next.t("popNotifications.editUser"),
                message: i18next.t("popNotifications.failure"),
                status: 'error',
                dismissible: true,
                dismissAfter: 3000,
            };
            store.dispatch(addNotification(notifi));
        })
    }
}

/**
 * Metoda zmieniajaca lokalne hasło usera
 * @param password      nowe haslo
 * @param ClientID
 * @param LocalUserID
 * @param FarmID
 */
export function changeMQTTPassword(password, ClientID, LocalUserID, FarmID) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_MQTT_PASSWORD",
            payload: invokeApig({
                ...Paths.changeMQTTPassword({clientID: ClientID, localUserID: LocalUserID}),
                queryParams: {LocalMqttSecret: password}
            })
        }).then(() => {
            dispatch({
                type: "USER_CHANGE_MQTT_PASSWORD",
                payload: password
            });
            let notifi = {
                title: i18next.t("popNotifications.localMqttPassword.localPasswordAuthorization"),
                message: i18next.t("popNotifications.localMqttPassword.successMessage"),
                status: 'success',
                dismissible: true,
                dismissAfter: 3000,
            };
            store.dispatch(addNotification(notifi));
        }).catch(() => {
            let notifi = {
                title: i18next.t("popNotifications.localMqttPassword.localPasswordAuthorization"),
                message: i18next.t("popNotifications.localMqttPassword.failureMessage"),
                status: 'error',
                dismissible: true,
                dismissAfter: 3000,
            };
            store.dispatch(addNotification(notifi));
        }).finally(() => {
            dispatch(redirect(`${FarmID}/settings`));
        });
    }
}


/**
 *
 * @param user
 * @param onSuccess
 * @param onFailure
 * @returns {Function}
 */
export function createUser(user, {
    onSuccess = () => {
    }, onFailure = () => {
    }
} = {}) {
    const locale = store.getState().user.attributes.locale;
    const tmp = {
        CogAccData: {
            email: user.email,
            accName: user.userLogin,
            phoneNumber: user.phone,
            uName: user.userName,
            address: user.address,
            //powinno być, ale w razie co sie zabezpieczam
            locale: locale ? locale : "pl"
        },
        UserAccData: {
            roles: user.roles || [],
            maxDevices: 1,
            // maxDevicesPC: 1,
            // maxDevicesMobile: 1,
            maxConcurrentSessions: 1
        }

    };
    return function (dispatch) {
        dispatch({
            type: "CREATE_USER",
            payload: invokeApig({
                ...Paths.createUser(user.userType),
                body: tmp
            })
        }).then(res => {
            onSuccess(res);
            let msg = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.added"),
                status: 'success',
                dismissible: true
            };
            dispatch(addNotification(msg));
        }).catch(err => {
            onFailure(err);
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.additionErr"),
                status: 'error',
                dismissible: true
            };
            dispatch(addNotification(msg));
        }).finally(() => {
            dispatch(redirect("/farmSettings/users"));
        })

    }
}

export function enableDisableUser(ClientID, enable, sub, login, onSuccess) {
    return function (dispatch) {
        dispatch({
            type: "ENABLE_DISABLE_USER",
            payload: invokeApig({
                ...Paths.enableDisableUser({clientID: ClientID}),
                body: {enable: enable, sub: sub, login: login}
            })
        }).then(() => {
            let msg = {
                title: i18next.t("success"),
                message: enable ? i18next.t("popNotifications.enabledUser") : i18next.t("popNotifications.disabledUser"),
                status: 'success',
                dismissible: true
            };
            dispatch(addNotification(msg));
            if (onSuccess) onSuccess();
        }).catch(err => {
            let msg;
            if (err.statusText === "Validation error") {
                let message = "";
                for (let key in err) {
                    if (key !== "statusText") {
                        switch (err[key].code) {
                            case 13:
                                message += `${i18next.t("braintreeValidation.operatorsMax", {count: err[key].amount})}<br/>`;
                                break;
                            case 14:
                                message += `${i18next.t("braintreeValidation.managersMax", {count: err[key].amount})}<br/>`;
                                break;
                            default:
                                message += i18next.t("notFound.dataNotFound");
                                break;
                        }
                    }
                }
                msg = {
                    title: i18next.t("error"),
                    message,
                    status: 'error',
                    dismissible: true,
                    allowHTML: true
                };
            } else {
                msg = {
                    title: i18next.t("error"),
                    message: enable ? i18next.t("popNotifications.enabledUserErr") : i18next.t("popNotifications.disabledUserErr"),
                    status: 'error',
                    dismissible: true
                };
            }
            dispatch(addNotification(msg));
        }).finally(() => {
            dispatch(redirect("/farmSettings/users"));
        })
    }
}

/**
 * funkcja usuwająca konto usera
 * @param LocalUserID - localUserID usera którego chcemy usunąć
 * @param onSuccess
 * @param onFailure
 * @returns {Function}
 */
export function deleteUserAccount(LocalUserID, onSuccess = () => {
}, onFailure = () => {
}) {
    return function (dispatch) {
        dispatch({
            type: "DELETE_USER_ACCOUNT",
            payload: invokeApig({
                ...Paths.deleteUser(),
                body: {LocalUserID}
            })
        }).then((res) => {
            onSuccess(res);
            let msg = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.deleteUserSuccess"),
                status: "success",
                dismissible: true,
            };
            dispatch(addNotification(msg));
        }).catch((err) => {
            onFailure(err);
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.deleteUserFailure"),
                status: "error",
                dismissible: true,
            };
            dispatch(addNotification(msg));
        });
    }
}

