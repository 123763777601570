/**
 * Funkcja iniciujaca dane dla widokow krzywych
 * @param chamber - obiekt komory
 * @param options
 * @param options.dispensers - lista urzadzen
 * @return {function(...[*]=)}
 */
export function feedingInit(chamber, options = {}) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_INITIALIZE",
            payload: {
                id: chamber.CID,
                chamber: chamber,
                options: options
            }
        })
    }
}

export function feedingDestroy(chamber) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_DESTROY",
            payload: {
                id: chamber.CID
            }
        })
    }
}

export function feedingSelect(chamber, dataId) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_SELECT",
            payload: {
                chamberId: chamber.CID,
                id: dataId
            }
        })
    }
}

export function feedingSelectAll(chamber) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_SELECT_ALL",
            payload: {
                chamberId: chamber.CID
            }
        })
    }
}

export function feedingUnselectAll(chamber) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_SELECT_NONE",
            payload: {
                chamberId: chamber.CID
            }
        })
    }
}

export function feedingFilter(chamber, feedingFilter) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_FILTER",
            payload: {
                chamberId: chamber.CID,
                feedingFilter: feedingFilter
            }
        })
    }
}

export function feedingSort(chamber, feedingSort) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_SORT",
            payload: {
                chamberId: chamber.CID,
                feedingSort: feedingSort
            }
        })
    }
}

export function feedingUpdateAnimals(chamber, options = {}) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_UPDATE_ANIMALS",
            payload: {
                chamber: chamber,
                chamberId: chamber.CID,
                options: options
            }
        })

    }
}

export function getFeedState(data) {
    return function (dispatch) {
        dispatch({
            type: "GET_FEED_STATE",
            payload: data
        })

    }
}

export function getFeedStateDelta(data) {
    return function (dispatch) {
        dispatch({
            type: "GET_FEED_STATE_DELTA",
            payload: data
        })

    }
}

export function getFeedStateRFID(data) {
    return function (dispatch) {
        dispatch({
            type: "GET_FEED_RFID_STATE",
            payload: data
        })

    }
}

export function getFeedStateRFIDDelta(data) {
    return function (dispatch) {
        dispatch({
            type: "GET_FEED_RFID_STATE_DELTA",
            payload: data
        })

    }
}

export function feedingCheckConnection(chamber) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_CHECK_LAST_CONNECTION",
            payload: {
                chamber: chamber,
                chamberId: chamber.CID
            }
        })

    }
}

export function clearFeeding() {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_CLEAR"
        })

    }
}

