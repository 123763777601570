import Queue from "better-queue";
import QueueMemory from "better-queue-memory";
import store from "../store/store";
import {authUser, invokeApig} from "../libs/awsLib";
import Auth from "@aws-amplify/auth";
import v4 from "aws-signature-v4"
import config from "../conf/config";
import {myID} from "../libs/generateID";
import {
    BridgeCommandTypes,
    ClimateDriverCommandTypes,
    CommandStatus,
    DispenserDriverCommandTypes,
    DispenserNRFCommandTypes,
    GatawayCommandTypes,
    LambdaTables,
    MessageCommands,
    MessageTypes,
    SeparationCageCommandTypes,
    TopicTypes
} from "../constans/mqttMessages";
import AlarmsIOT from "./shadows/AlarmsIOT";
import {addNotification, updateNotification} from "reapop";
import pako from "pako"
import {changeAnimalShadow, changeShadowState, setShadowLoading} from "../actions/shadowsActions";
// import {AWSIoTProvider} from "@aws-amplify/pubsub/lib/Providers"
import mqtt from "mqtt";
import _ from "lodash"
import {onGetFullDevStateFailure, onGetFullDevStateSuccess} from "../utils/DevicesUtils";
import {getIOTAttributes} from "../utils/IOTUtils";
import {getAggregatedData} from "../actions/aggregatedActions";
import devicesDB from "../database/devicesDB"
import {DevType} from "../constans/devices";
import {
    changeAddressingState,
    createDeviceDynamoDB,
    fetchDevices,
    updateDeviceDynamoDB,
    updateDeviceParam
} from "../actions/devicesActions";
import buildingsDB from "../database/buildingsDB";
import {getLocationID} from "../utils/BuildingUtils";
import {fetchBuildingsByFarmID, updateLocation} from "../actions/farmsActions";
import Device from "../beans/Device";
import {listAnimalDynamoDB} from "../actions/animalsActions";
import {listDictionariesDynamoDB} from "../actions/dictionaryActions";
import {getEvents} from "../actions/eventsActions";
import {getNotificationsDynamoDB} from "../actions/notificationsActions";
import {getSettings} from "../actions/settingsAction";
import {listTechnologyGroupDynamoDB} from "../actions/technologyGroupActions";
import {getEmployees} from "../actions/userActions";
import {redirect} from "../actions/historyActions";
import {getOffline} from "../utils/SettingsUtils";
import ConfigurationParser from "../parser/ConfigurationParser";
import {listGroupsDynamoDB} from "../actions/groupActions";
import {getFeedState, getFeedStateDelta, getFeedStateRFID, getFeedStateRFIDDelta,} from "../actions/feedingActions";
import {listSettlementsDynamoDB} from "../actions/settlementActions";
import {IntervalTimes} from "../constans/intervals";
import Paths from "../api/paths";
import {listAthenaReports} from "../actions/raportsActions";
import {getTranslation} from "../actions/localizationActions";
import {logout} from "../utils/UserUtils";
import {onPingSuccess} from "../actions/mqttActions";
import i18next from "i18next";
import * as RoleTypes from "validators-schema/Api/constants/roleTypes";
import * as UserTypes from "validators-schema/Api/constants/userTypes";
import moment from "moment";
import {getTasksAPI} from "../actions/taskActions";
// import AWS, {Iot} from "aws-sdk";

const IntervalTime = IntervalTimes.DEVICE_MQTT_RESEND_STATE;

let test;

function getBugsnagClient() {
    let bs = "";
    if (process.env.NODE_ENV !== "test") {
        test = true;
        bs = require("../index").bugsnagClient;
    } else {
        test = false;
    }
    return bs;
}

class NewIOT {
    constructor() {
        this.url = "";
        this.window = null;
        this.msgChunks = new Map();
        this.client = undefined; //klient mqtt
        this.offlineChecker = null; // dodatkowy klient, ktory uruchamia sie na offlinie, aby przywrocic polaczenie z AWS
        this.subscriptions = []; //lista subskrypcji
        this.intervalMap = new Map(); /*

            CYKL
            WCHODZISZ NA WIDOK WYSYLASZ START SENDING DEVICE STATE ZAPAMIETUJESZ ID WIADOMOSCI
            NA WYJSCIU ROBISZ STOP SENDING DEVICE STATE Z ID WIADOMOSCI ESSA PROSTE

        */

        this.afterProcessDelay = 25; //czas w ms miedzy wysyłanymi komendami z kolejki
        this.preconditionRetryTimeout = 2; //czas co jaki ma sprawdzac czy odzyskal polaczenie z mqtt w sekundach
        this.timeout = undefined;
        this.timeToClearQueue = 1; //czas w minutach po ktorych nastapi wyczyszczenie kolejki w przyapdku ciaglego braku polaczenia
        this.waitedSetMessage = [];
        this.specialMessages = [DispenserDriverCommandTypes.RESET_CONSUMPTION, SeparationCageCommandTypes.DO_TARE, SeparationCageCommandTypes.RESET_DAILY_VISITS,
            SeparationCageCommandTypes.CLEAR_INSERTION_DATA, SeparationCageCommandTypes.RESET, DispenserDriverCommandTypes.START_MOTORS, DispenserDriverCommandTypes.STOP_MOTORS,
            MessageCommands.GET_FULL_DEV_STATE, MessageCommands.GET_DEVICES, DispenserNRFCommandTypes.GET_LOGS, DispenserNRFCommandTypes.DELETE_LOGS, DispenserNRFCommandTypes.GET_PIG,
            DispenserNRFCommandTypes.ADD_PIG, DispenserNRFCommandTypes.GET_WORK_TYPE, DispenserNRFCommandTypes.GET_HISTORY_STANDARD, MessageCommands.PING, DispenserNRFCommandTypes.GET_DAILY_USAGE,
            MessageCommands.GET_AGGREGATED_DATA, GatawayCommandTypes.STOP_ADDRESSING, GatawayCommandTypes.START_ADDRESSING, GatawayCommandTypes.GET_GATEWAY_TIME, MessageCommands.UPDATE_SINGLE_DEVICE,
            MessageCommands.REMOVE_DEVICES, MessageCommands.GET_NRF_FEEDERS, SeparationCageCommandTypes.GET_SENSORS_AND_VALVES, ClimateDriverCommandTypes.GET_CONFIGURATION, GatawayCommandTypes.OPEN_SSH,
            GatawayCommandTypes.GET_NRF_STATS, GatawayCommandTypes.START_ADR_WST, GatawayCommandTypes.START_ADDRESSING_NRF, GatawayCommandTypes.STOP_ADR_WST, GatawayCommandTypes.STOP_ADDRESSING_NRF, GatawayCommandTypes.GET_SCHEMA,
            MessageCommands.AUTH_USER, GatawayCommandTypes.ADDR_START_SUBADDRESSING_NRF, BridgeCommandTypes.B_INFO, BridgeCommandTypes.B_BOOT_INFO, BridgeCommandTypes.B_BOOT_INFO_NRF, BridgeCommandTypes.B_NRF_STAT,
            BridgeCommandTypes.B_485_TOUT_R, BridgeCommandTypes.B_GET_SCAN_ADDR, GatawayCommandTypes.GET_QUEUES_LAST_SUCCESS, GatawayCommandTypes.GET_PIGS_DATA, GatawayCommandTypes.GET_ASCII_CLIMATE_TESTING_DATA,
            MessageCommands.GET_FEED_RFID_STATE, GatawayCommandTypes.GET_ALL_ALARMS, GatawayCommandTypes.GET_ALL_ONGOING_ALARMS,
            GatawayCommandTypes.GET_USAGE_FOR_PIG, MessageCommands.REFRESH_TOKEN, GatawayCommandTypes.GET_FEEDING_DATA_FOR_PIG,
            GatawayCommandTypes.PING,  DispenserNRFCommandTypes.SET_ADDITIONAL_WATER, DispenserNRFCommandTypes.SET_ANIMAL_MODIFICATION
        ]; //lista specjalnych komend, ktore nie zaczynaja sie na set a powinny byc uwzglednione jako ten typ
        this.sendingRefreshToken = false;
        this.reconnectsAmount = 0;
        //tworzenie kolejki mqtt
        this.queue = new Queue((input, cb) => {
            this.runQueueTask(input, cb)
        }, {
            store: new QueueMemory(),
            afterProcessDelay: this.afterProcessDelay,
            maxTimeout: 10000,
            // concurrent: 5,
            precondition: cb => {
                if (this.client && this.client.connected) {
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                        this.timeout = undefined;
                    }
                    if (this.client.options.hostname !== "FeturaOfflineGW" && this.subscriptions.length < 2) {
                        cb(null, false);
                    } else {
                        if (this.sendingRefreshToken) {
                            console.log("sending refresh token")
                            cb(null, false);
                        } else {
                            cb(null, true);
                        }
                    }
                } else {
                    if (!this.timeout) {
                        this.timeout = setTimeout(() => {
                            this.clearQueue();
                            this.timeout = undefined;
                        }, 1000 * 60 * this.timeToClearQueue)
                    }
                    cb(null, false);
                }
            },
            preconditionRetryTimeout: this.preconditionRetryTimeout * 1000,
            id: (task, cb) => {
                let command = task.message;
                cb(null, command.MsgId);
            },
            //wykomentowalem bo nie idzie ustawic rzeczy za 1. razem, trzeba wyslac druga komende aby pierwsza przesla
            // merge: (oldTask, newTask, cb) => {
            //     cb(null, oldTask);
            // },
            concurrent: 3
        });

        this.queue.on('task_finish', (taskId, result) => {
            if (typeof result === "object") {
                this.checkResult(result);
            }
        });
        this.queue.on('task_failed', (taskId, errorMessage) => {
            //tylko set moze nie przejsc gdyz gety maja callback od razu
            // console.log("FAILED", this.waitedSetMessage, errorMessage);
            console.log(taskId, errorMessage, this.waitedSetMessage);
            try {
                let waitedMessage = this.waitedSetMessage.find(item => item.message.MsgId === taskId);
                if (waitedMessage.notification) {
                    let notif = {
                        id: waitedMessage.notification.id,
                        ...waitedMessage.notification.loading,
                    }
                    // TODO sprawdzic czy wejdzie w ten warunek jak wysypie sie API
                    if ((errorMessage === "Failed to fetch" || errorMessage.statusCode) && waitedMessage.notification.apiFailed) {
                        notif = {
                            ...notif,
                            ...waitedMessage.notification.apiFailed
                        };
                    } else {
                        notif = {
                            ...notif,
                            ...waitedMessage.notification.error
                        };
                    }
                    store.dispatch(updateNotification(notif));
                }
                if (waitedMessage.onError) waitedMessage.onError(errorMessage, waitedMessage.message);
                this.waitedSetMessage = this.waitedSetMessage.filter(item => item.message.MsgId !== taskId);
            } catch (e) {
                console.error(e);
                this.waitedSetMessage = [];
            }
        });

        this.onStateMessageArrived = this.onStateMessageArrived.bind(this);
        this.onAggregatedDataMessageArrived = this.onAggregatedDataMessageArrived.bind(this);
        this.onMessageArrived = this.onMessageArrived.bind(this);
        this.onAlarmsMessageArrived = this.onAlarmsMessageArrived.bind(this);
        this.onResponsesMessageArrived = this.onResponsesMessageArrived.bind(this);
    }

    clearQueue() {
        this.queue.use(new QueueMemory());
    }

    runQueueTask(input, cb) {
        //funkcja ktora wysyla na mqtt, wykonuje sie przy dodaniu do kolejki
        // console.log("INPUT", input);
        console.warn("QUEUE_TASK_EXECUTED", input);
        if ((this.client.options.host.includes("fetura") || this.client.options.host.includes("Fetura")) &&
            Array.isArray(input.message.Command) &&
            !input.message.Command.includes(MessageCommands.AUTH_USER) &&
            !input.message.Command.includes(MessageCommands.REFRESH_TOKEN)) {
            let {user: {user: {Login}}} = store.getState();
            let tokenExpireTime = +localStorage.getItem(`${Login}.createTokenTime`);
            if (tokenExpireTime > new Date().getTime()) {
                input.message.CData.token = localStorage.getItem(`${Login}.token`);
                this.sendMqttMessage({
                    topic: input.topic,
                    message: input.message
                });
            } else {
                let refreshTokenExpire = +localStorage.getItem(`${Login}.createRefreshTokenTime`);
                if (refreshTokenExpire > new Date().getTime()) {
                    let refreshToken = localStorage.getItem(`${Login}.localRefreshToken`);
                    if (!this.sendingRefreshToken) {
                        this.refreshToken(refreshToken, input);
                    } else {
                        this.addMessageToQueue(input);
                    }
                } else {
                    logout();
                }
            }
        } else {
            this.sendMqttMessage({
                topic: input.topic,
                message: input.message
            });
        }
        if (input.onSend) input.onSend(input.message);
        if (this.checkCommands(input.message.Command)) {
            // console.log("ADDING WAITEDMESSAGE", input);
            this.waitedSetMessage.push({
                ...input,
                callback: cb
            });
            if (input.notification) {
                input.notification.id = input.message.MsgId;
                store.dispatch(addNotification({
                    ...input.notification.loading,
                    id: input.message.MsgId
                }));
            }
        } else {
            // console.log("GET \"ADDING WAITEDMESSAGE\"");
            cb(null, true);
        }
    }

    onRefreshTokenSuccess = msg => {
        let {user: {user: {Login}}} = store.getState();
        localStorage.setItem(`${Login}.token`, msg.CAnsw.token);
        localStorage.setItem(`${Login}.createTokenTime`, msg.CAnsw.createTokenTime);
        localStorage.setItem(`${Login}.localRefreshToken`, msg.CAnsw.refreshToken);
        localStorage.setItem(`${Login}.createRefreshTokenTime`, msg.CAnsw.createRefreshTokenTime);
        this.sendingRefreshToken = false;
    }

    onRefreshTokenError = (error, msg) => {
        console.log(error, msg);
        console.error("cant refresh token");
        this.sendingRefreshToken = false;
        logout();
    }

    onRefreshTokenSend = (msg, trigger) => {
        this.sendingRefreshToken = true;
        this.addMessageToQueue(trigger);
    }

    refreshToken(refreshToken, trigger) {
        let msg = {
            MsgId: myID(),
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.REFRESH_TOKEN],
            CData: {refreshToken}
        }
        let topic = `devices/auth`;
        this.createAndAddMessageToQueue(topic, msg, null, this.onRefreshTokenSuccess, this.onRefreshTokenError, (msg) => this.onRefreshTokenSend(msg, trigger));
    }

    checkIfAlreadySubscribed(topic) {
        return this.subscriptions.includes(topic);
    }

    addToSubscriptions(topic) {
        // console.log("adding to sub list", sub, topic);
        this.subscriptions.push(topic);
    }

    removeFromSubscriptions(topic) {
        this.subscriptions = this.subscriptions.filter(item => item !== topic);
    }

    clearSubscriptions() {
        console.log("clear");
        for (let topic of this.subscriptions) {
            this.client.unsubscribe(topic);
        }
        this.subscriptions = [];
        console.log(this.subscriptions);
        store.dispatch({
            type: "SUBSCRIBE_MQTT_TOPICS",
            payload: []
        })
    }

    storeConfiguration(msg, success = true) {
        console.log("STORING", msg);
        let configurations = [];
        let configurationParser = new ConfigurationParser(msg);
        for (let DevID of Array.isArray(msg.DeviceId) ? msg.DeviceId : [msg.DeviceId]) {
            let configuration = configurationParser.parseCommand(DevID, success);
            if (configuration) {
                configurations = [...configurations, ...configuration];
            }
        }
        if (configurations.length > 0) {
            const {user: {user}, location: {farm}} = store.getState();
            store.dispatch(updateDeviceParam(configurations, farm, user.ClientID, user.LocalUserID, null, null, false));
        }
    }

    createMessage(topic, message, notification = undefined, onSuccess = undefined, onError = undefined, onSend = undefined) {
        return {
            topic: topic,
            message: message,
            notification: notification,
            onSuccess: msg => {
                this.storeConfiguration(msg);
                onSuccess && onSuccess(msg);
            },
            onError: (err, msg) => {
                console.log(err, msg);
                this.storeConfiguration(msg, false);
                onError && onError(err, msg);
            },
            onSend: () => {
                onSend && onSend(message);
            }
        }
    }

    addMessageToQueue(message) {
        console.log(message);
        this.queue.push(message);
        //notyfikacja wyświetlana w przypadku braku neta. na razie takie tlumaczenie
        //bo jest zmieniane na i18 next, wiec nowego nie dodaje
        //todo - pliki i18next powinny byc trzymane offlline'owo bo w tym case'ie nam ich nie pobierze

        // wykomentowuje bo jest nie potrzebne, wywala bardzo czesto zanim jeszcze sie mqtt polaczy
        // if (this.client && !this.client.connected) {
        //     let n = {
        //         title: store.getState().language.lang.page.IOT.noConnection,
        //         message: store.getState().language.lang.page.IOT.noResponseFromDevice,
        //         dismissible: true,
        //         dismissAfter: 3000,
        //         status: "error"
        //     };
        //     store.dispatch(addNotification(n));
        // }
    }

    createAndAddMessageToQueue(topic, message, notification = undefined, onSuccess = undefined, onError = undefined, onSend = undefined) {
        // console.log("CREATE MESSAGE", onSuccess, topic);
        this.addMessageToQueue(this.createMessage(topic, message, notification, onSuccess, onError, onSend));
    }

    compressData(data) {
        // console.log("COMPRESSING", data);
        return pako.deflate(JSON.stringify(data), {
            level: 9,
            to: 'string'
        })
    }

    //tymczasowe roziwazanie na czas wymiany w agg data cansw
    decompressData(data, topic) {
        let shouldDecompress = true;
        if (topic === TopicTypes.AGGREGATED_DATA) {
            shouldDecompress = false;
        }

        if (!shouldDecompress) {
            return data;
        } else {
            return JSON.parse(pako.ungzip(pako.inflate(Array.isArray(data) ? data.reduce((prev, next) => prev + next, "") : data), {
                to: 'string'
            }))
        }
    }

    // decompressData(data){
    //     try{
    //         if( data.ChunksID ){
    //             let msg = this.msgChunks.get(data.ChunksID);
    //             if(msg){
    //                 console.warn("decompressData DATA",data)
    //                 msg[data.ChunksOffset] = data.CAnsw;
    //                 this.msgChunks.set(data.ChunksID, msg);
    //                 if(data.ChunksOffset+1 === data.ChunksSize){
    //                     console.warn("decompressData parse",data)
    //                     return JSON.parse(pako.ungzip(pako.inflate(data), {
    //                         to: 'string'
    //                     }))
    //                 }
    //             }else{
    //                 console.warn("decompressData collect",data)
    //                 let chunks = [].fill({},0,data.ChunksSize);
    //                 chunks[data.ChunksOffset] = data.CAnsw;
    //                 this.msgChunks.set(data.ChunksID, chunks);
    //             }
    //
    //         }else{
    //             return JSON.parse(pako.ungzip(pako.inflate(data), {
    //                 to: 'string'
    //             }))
    //         }
    //     } catch(e){
    //         console.error("Error while decompressing data: " + e);
    //     }
    // }

    async createURL(endpoint) {
        if (await authUser()) {
            let creden = await Auth.currentCredentials();
            return v4.createPresignedURL('GET', endpoint, '/mqtt', 'iotdevicegateway', '', {
                key: creden.data.Credentials.AccessKeyId,
                secret: creden.data.Credentials.SecretKey,
                region: config.cognito.REGION,
                protocol: "wss",
                sessionToken: creden.sessionToken
            });
        }
    }

    // async assignPolicy() { //przypisanie polityki bezpieczenstwa do aktualnie zalogowanego cognito identity
    //     let currentCredentials = await Auth.currentCredentials();
    //     console.log(currentCredentials);
    //     AWS.config.update({
    //         region:'eu-central-1',
    //         credentials: currentCredentials
    //     });
    //     let iot = new Iot();
    //     let cogIdentity = currentCredentials._identityId; //pobranie id zalogowanego identity
    //     let params = {
    //         policyName: 'fullAccessPolicy',
    //         principal: cogIdentity
    //     };
    //
    //     iot.attachPrincipalPolicy(params, function (err, data) { //przypisanie policy do aktualnego identity, zeby moc sie polaczyc
    //         if (err) console.error(err, err.stack);
    //         else console.error(data);
    //     });
    // }

    subscribeAllTopics(ClientID = "") {
        // if(window.disableMQTT) return false;
        let state = store.getState();
        const {user: {user}, farmDevices: {devices}} = state;
        let topics = [];
        if (ClientID || user.ClientID) {
            topics.push(`devices/state/${ClientID || user.ClientID}/lambda`);
        }
        if (user.LocalUserID && user.UserType === UserTypes.SERVICE) {
            topics.push(`devices/state/${user.LocalUserID}/lambda`);
        }
        let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
        for (let gw of gateways) {
            topics.push(`devices/responses/${ClientID || user.ClientID}/${gw.DevID}`);
            topics.push(`devices/state/${ClientID || user.ClientID}/${gw.DevID}`);
            topics.push(`devices/aggregatedData/${ClientID || user.ClientID}/${gw.DevID}`)
        }
        for (let topic of topics) {
            this.subscribeTopic(topic);
        }
        store.dispatch({
            type: "SUBSCRIBE_MQTT_TOPICS",
            payload: topics
        })
    }

    /**
     * Metoda sprawdzajaca czy istnieje polaczenie z internetem, jezeli istnieje to przywraca mqtt przez aws
     * @param time      czas do sprawdzenia, zwieksza sie x2 przy kazdym uruchomieniu do max 5 min
     */
    createCheckTimeout(time) {
        setTimeout(async () => {
            console.log("timeout xD");
            try {
                // zaawansowany mechanizm sprawdzajacy czy istnieje polaczenie z internetem
                let response = await fetch("https://icanhazip.com/");
                if (response && response.status === 200) {
                    this.url = await this.createURL("a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com");
                    if (this.client) {
                        this.client.end();
                        this.clearSubscriptions();
                        this.client = null;
                    }
                    this.createClient(this.url, {
                        protocol: "wss",
                        transformWsUrl: () => {
                            return this.url;
                        }
                    })
                } else {
                    if (time < 5 * 60 * 1000) {
                        this.createCheckTimeout(time);
                    }
                }
            } catch (e) {
                console.error(e);
                if (time < 5 * 60 * 1000) {
                    this.createCheckTimeout(time);
                }
            }
        }, time);
    }

    async createLocalClient() {
        // let gws = store.getState().farmDevices.devices.filter(item => item.DevType === DevType.GATEWAY && item.BrokerAdr);
        // let address = gws[0] ? gws[0].BrokerAdr : `FeturaOfflineGW`;
        let address = "FeturaOfflineGW";
        // let address = gws[0] ? gws[0].BrokerAdr.split(":")[0] : `192.168.2.148`; // poprawka na Bednary
        this.createClient(`wss://${address}`, {
            //clientId:"daniel"+ Math.random().toString(16).substr(2, 8),
            keepalive: 60,
            port: 9001,
            hostname: address,
            protocol: "wss",
            ca: ["-----BEGIN CERTIFICATE-----\n" +
            "MIIDtTCCAp2gAwIBAgIURiyLl4b7ktNcTdmO8RrGWlZjDSswDQYJKoZIhvcNAQEN\n" +
            "BQAwajEXMBUGA1UEAwwOQW4gTVFUVCBicm9rZXIxFjAUBgNVBAoMDU93blRyYWNr\n" +
            "cy5vcmcxFDASBgNVBAsMC2dlbmVyYXRlLUNBMSEwHwYJKoZIhvcNAQkBFhJub2Jv\n" +
            "ZHlAZXhhbXBsZS5uZXQwHhcNMTkwODIzMTgwNjE0WhcNMzIwODE5MTgwNjE0WjBq\n" +
            "MRcwFQYDVQQDDA5BbiBNUVRUIGJyb2tlcjEWMBQGA1UECgwNT3duVHJhY2tzLm9y\n" +
            "ZzEUMBIGA1UECwwLZ2VuZXJhdGUtQ0ExITAfBgkqhkiG9w0BCQEWEm5vYm9keUBl\n" +
            "eGFtcGxlLm5ldDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBANqtusP0\n" +
            "+APMlOFVC198QMMuFJM4+myWH9I1GyhZFGTif/DbOeVEwakXMyt0G9TCktoYud5C\n" +
            "QhvCoz9XZqHm163//r8rV2EU/9e6yy8g9zxz50YK9tDD9XHtK32vXCPPjEpuGKO4\n" +
            "vQJMGFCaFfjG/CjtPlyioMo72auTQknZip9XvOWHJvYCjdYQVDxvfSeYFJp7Gxee\n" +
            "eC5sDDCz3wbSjg+eOSXCIk/3Vzg9PvQ6ITxnbbNxB0rdE0yHLkZTlmQ/dRe/JVP7\n" +
            "2t4co1f8eJ/xysmzTf5nVQlKtuKZG/JpKqmvOu25ocehdIJBa8MsOoTtH35aJTRs\n" +
            "b7ecflbwaxJqBh8CAwEAAaNTMFEwHQYDVR0OBBYEFDd9uCVRS+D/XeLRQ+oZOBZr\n" +
            "LEAcMB8GA1UdIwQYMBaAFDd9uCVRS+D/XeLRQ+oZOBZrLEAcMA8GA1UdEwEB/wQF\n" +
            "MAMBAf8wDQYJKoZIhvcNAQENBQADggEBAH5EVma7jjT1+0U7FIymQDmn/tYQ8CRn\n" +
            "LWliGG6dScPR/NDM86xuB8fG96YGnE7D2PRJNIcwKx8xAJdbFTekCRBF3Aym20ZH\n" +
            "6wH4jwBJgDLyfzUoCwSUYTByK7LTCQNiBqVWfGuT6dkTIjXlgl92dR//W2CP8k6y\n" +
            "NyHkl8M8FY8MtL7bvwGxW1p+97QA7/haWvJB4q5UgdVdg3+DietTKL5Q4vgNzdH4\n" +
            "4lOjvfJwlf6SY9jSFNTarGCCMENz+owexVyvXWX1QEi+0Muyvc0HJH2vAp9R+1Ys\n" +
            "R7/OpqIKOdSTMg663uoX1A5ZB0c2X/jlE5GAbzb5A65HF0ZSX+bcPjc=\n" +
            "-----END CERTIFICATE-----"],
            rejectUnauthorized: false
        });
        this.createCheckTimeout(10 * 1000);
    }

    createClient(url, options = {}) {
        let retries = 1;
        this.client = mqtt.connect(url, {
            clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
            keepalive: 10,
            ...options
        });

        this.client.on('connect', () => {
            this.reconnectsAmount = 0;
            (!store.getState().mqtt.connected) && store.dispatch({type: "MQTT_CONNECT_FULFILLED"});
            console.log("MQTT: Client connected", this.client);
            this.subscribeAllTopics();
        });

        this.client.on('reconnect', async () => {
            this.reconnectsAmount++;
            console.error("reconnect");
            if (this.reconnectsAmount < 10) {
                store.dispatch({type: "MQTT_CONNECT_PENDING"});
            } else {
                store.dispatch({type: "MQTT_KEEPS_RECONNECTING"});
            }
            retries--;
            if (retries < 0) {
                this.clearSubscriptions();
                if (this.client) {
                    this.client.end(true);
                }
                this.client = null;
                await this.createLocalClient();
            }
            if (this.client.options.host.includes("a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com")) {
                this.url = await this.createURL("a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com");
                console.log("MQTT: Client reconnect");
            }
        });

        this.client.on('message', (topic, message) => {
            this.onMessageArrived(topic, message)
        });

        this.client.stream.on("error", async (e) => {
            console.log("error stream", e);
            try {
                if (!e.srcElement.url.includes("a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com")) {
                    this.clearQueue();
                    this.endConnection(e.srcElement.url);
                    if (window.location.href.includes("login")) {
                        store.dispatch({
                            type: "USER_LOGIN_REJECTED",
                            error: null
                        })
                    }
                    if (getOffline()) {
                        store.dispatch(redirect("/acceptCert"));
                    }
                }
            } catch (e) {
                console.error(e);
            }
        });

        this.client.on('error', (err) => {
            console.error('MQTT: Client error: ', err);
            this.clearSubscriptions();
            let client = getBugsnagClient();
            client.notify("MQTT: Client error", {
                metaData: {
                    'feedback': {
                        causedBy: (err && err.toString) ? err.toString() : 'No info available',
                        stack: (err && err.stack) ? err.stack : 'No info available'
                    }
                }
            });
        });
    }

    async connectToAWS() {
        // await this.assignPolicy();
        if (!this.client || !this.client.connected) {
            // let ip = await this.getLocalIPAddress();

            this.url = await this.createURL("a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com");
            this.createClient(this.url, {
                protocol: "wss",
                transformWsUrl: () => {
                    return this.url;
                }
            });
        } else {
            this.subscribeAllTopics();
        }
    }

    async startConnection() {
        try {
            store.dispatch({type: "MQTT_CONNECT_PENDING"});
            await this.connectToAWS();
        } catch (e) {
            console.error("Blad przy polaczeniu ", e)
        }
    }

    endConnection(url) {
        if (url) {
            if (this.client.options.href === url) {
                this.clearSubscriptions();
                this.client.end(false, () => store.dispatch({type: "MQTT_CONNECT_FAILURE"}));
                this.client = undefined;
            }
        } else {
            this.clearSubscriptions();
            this.client.end(false, () => store.dispatch({type: "MQTT_CONNECT_FAILURE"}));
            this.client = undefined;
        }
    }

    sendToChannel(message) {
        try {
            this.client.publish(message.topic, JSON.stringify(message.message), {}, err => {
                if (err && !test) {
                    let client = getBugsnagClient();
                    client.notify("MQTT: Send MQTT message failure (Client.publish())", {
                        metaData: {
                            'feedback': {
                                causedBy: (err.toString) ? err.toString() : 'No info available',
                                stack: (err.stack) ? err.stack : 'No info available'
                            }
                        }
                    });
                    console.error("MQTT: Send MQTT message failure (Client.publish())", err);
                }
            })
        } catch (e) {
            let client = getBugsnagClient();
            client.notify("MQTT: Send MQTT message failure", {
                metaData: {
                    'feedback': {
                        causedBy: (e && e.toString) ? e.toString() : 'No info available',
                        stack: (e && e.stack) ? e.stack : 'No info available'
                    }
                }
            });
            console.error("MQTT: Send MQTT message failure: ", e);
        }
    }

    /**
     * Funkcja dzielaca CData na mniejsze paczki
     *
     * @param data obiekt CDatat z wiadomosci do wyslania
     * @param chunks w głównbym wywolaniu nie podajemy tego parametru jest on potrzebyn do wywołań rekurencyjnych
     * @return {*[]}
     */
    isMoreThan120kB(data, chunks = []) {
        let sizePkg = 120 * 1024;
        console.log(data.length);

        if (data.length > sizePkg) {
            chunks.push(data.slice(0, sizePkg));
            data = data.slice(sizePkg, data.length);
            // console.error("isMoreThan120kB if\r\nchunks:",chunks );
            // console.error("isMoreThan120kB if\r\ndata:",data );
            return this.isMoreThan120kB(data, chunks);
        } else {
            console.log(chunks);
            //jesli caly obiekt nie jest wiekszy niz sizePkg to zwroc obiekt w przeciwnym razie string
            // if (chunks.length === 0) {
            //     return JSON.parse(data);
            // }
            //todo - usunięto JSON.parse po przetestowaniu z obu stron
            chunks.push(JSON.parse(data));
            //chunks.push(data);
            // console.error("isMoreThan120kB else\r\nchunks:",chunks );
            // console.error("isMoreThan120kB else\r\ndata:",data );
            return chunks;
        }
    }

    /**
     * funkcja przygotowujaca wiadomosci do wyslania
     * dodaje ChunkID, ChunkOffset i ChunkSize do wiadomosci wiekszy niz sizePkg
     * @param data wiadomosc do wyslania obiekt mqtt
     * @param chunks podzielona CData na mniejsze paczki
     * @return {Uint8Array|BigInt64Array|any[]|Float64Array|Int8Array|Float32Array|Int32Array|Uint32Array|Uint8ClampedArray|BigUint64Array|Int16Array|Uint16Array}
     */
    prepareToPublish(data, chunks) {
        if (chunks.length > 1) {
            //console.log("prepareToPublish", chunks.length)
            //przygotowywanie jesli jest obiekt jest wiekszy i musial byc podzielony
            let chunksID = myID();
            chunks = chunks.map((chunk, i) => {
                let _data = Object.assign({}, data);
                _data.ChunksID = chunksID;
                _data.ChunksOffset = i;
                _data.ChunksSize = chunks.length;
                _data.CData = chunk;
                //console.log("prepareToPublish data", _data)
                return _data;
            })
            //console.log("prepareToPublish chunks", chunks)
            return chunks;
        } else {
            //przypadek jak wiadomosc jest w calosci
            data.CData = chunks[0];
            return [data];
        }
    }

    async sendMqttMessage(message) {
        //dzielenie wiadomosci na mniejsze jesli przekracza rozmiar (max128kb)
        // console.log("sendMqttMessage", message)
        let CData = JSON.stringify(message.message.CData);
        let chunks = this.isMoreThan120kB(CData, []);
        console.log(chunks);
        //utworzenie kilku wiadomosci z jednej jesli przekroczyla rozmiar
        let messages = this.prepareToPublish(message.message, chunks);
        //console.log("sendMqttMessage 2", messages)
        messages.forEach(value => {
            //podstawienie do wiadomosci zaktualizowanej o nowe pola jesli takie wystapily
            message.message = value;
            if (this.client.options.host.includes("amazonaws.com")) {
                if (message.topic.includes("commands")) {
                    const state = store.getState();
                    const {location: {farm}} = state;
                    if (farm) {
                        invokeApig({
                            ...Paths.commandProxy({farmID: farm}),
                            body: {
                                ...message.message,
                                Topic: message.topic
                            }
                        }).catch(e => {
                            let waitedMessage = this.waitedSetMessage.find(item => item.message.MsgId === message.message.MsgId);
                            if (waitedMessage) {
                                waitedMessage.callback(e, null);
                            }
                        })
                    }
                } else {
                    this.sendToChannel(message);
                }
            } else {
                this.sendToChannel(message);
            }
        })
    }

    /**
     * Metoda subskrybujaca sie do danego topica i dodajaca do listy subskrypcji jezeli jeszcze nie zostal zasubskrybowany
     * @param topic             topic mqtt
     */
    subscribeTopic(topic) {
        try {
            if (!this.checkIfAlreadySubscribed(topic)) {
                console.warn("SUBSCRIBING TO: " + topic);
                this.client.subscribe(topic);
                this.addToSubscriptions(topic);
            }
        } catch (err) {
            console.error("sub failure:" + err)
        }
    }

    /**
     * Metoda subskrybuje do kanalu o alarmach
     * @param ClientID      ID klienta
     */
    // subscribeAlarmTopic(ClientID) {
    //     // let topic = `devices/alarms/${ClientID}`;
    //     let topic = `devices/alarms`;
    //     this.subscribeTopic(topic);
    // }

    /**
     * Metoda usuwajaca subskrypcje z listy i klienta
     * @param topic     topic ktory ma zostac odsubskrybowany
     */
    unsubscribeTopic(topic) {
        try {
            this.client.unsubscribe(topic);
            this.removeFromSubscriptions(topic);
        } catch (err) {
            console.error("unsub failure: " + err);
        }
    }

    /**
     * Metoda odsubskrybowujaca response topci dla gatewaya
     * @param ClientID      ID klienta
     * @param GatewayID     ID gateway (raspberry)
     */
    // unsubscribeResponseTopic(ClientID, GatewayID) {
    //     let topic = `devices/responses/${ClientID}/${GatewayID}`;
    //     this.unsubscribeTopic(topic);
    // }

    // unsubscribeAlarmTopic(ClientID) {
    //     // let topic = `devices/alarms/${ClientID}`;
    //     let topic = `devices/alarms`;
    //     this.unsubscribeTopic(topic);
    // }

    /**
     * Metoda subskrybuje do kanału i pyta dane urządzenie o swój stan
     * @param ClientID      ID klienta
     * @param LocalUserID   ID uzytkownika
     * @param GatewayID     ID gatewaya (raspberry)
     * @param DeviceID      ID urzadzenia o ktore ma zostac wyslane zapytanie
     * @param onSuccess
     * @param onFailure
     */
    pingDevice(ClientID, LocalUserID, GatewayID, DeviceID, onSuccess, onFailure) {
        let topic = `devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
        let command = {
            MsgId: myID(),
            PktType: MessageTypes.REQUEST,
            DeviceId: DeviceID,
            RTime: new Date().getTime(),
            Command: [MessageCommands.PING],
            CData: {ping: "pong"}
        };
        // let sendTopic = `devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;
        // let sendTopic = `devices/state/UNBINDED/GW_SB_18_09_04_110247371`;
        // let sendTopic = `devices/state/UNBINDED/GW_SB_18_09_04_110247371`;
        this.createAndAddMessageToQueue(topic, command, null, onSuccess, onFailure);
    }

    /**
     * Metoda subskrubuje do kanalu, na ktorym sa wysylane stany urzadzen oraz wysyla zapytanie do gatewaya
     * o aktualne dane. Nastepnie ustawia interval na 10 aby wysylac prosbe o kolejne dane.
     * @param dev     Lista/pojedyncze urzedzenie (lub jego ID) do pobrania np. "DevID" / ["DevID","DevID", device] / device
     * @param onSuccess     Funkcja, ktora wykona się po odpowiedzi
     * @param onFailure     Funkcja, która wykona się po braku odpowiedzi lub błędzie
     * @param keepAlive
     */
    startSendingDeviceState(dev, onSuccess = onGetFullDevStateSuccess, onFailure = onGetFullDevStateFailure, {keepAlive = false} = {}) {
        const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
        if (isValid) {
            let sendTopic = `devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            const id = myID();
            let command = {
                MsgId: id,
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [MessageCommands.GET_FULL_DEV_STATE],
                CData: {DeviceIDs: _.isArray(DevID) ? DevID : [DevID]}
            };
            store.dispatch(setShadowLoading({DevID: command.CData.DeviceIDs, status: true}));
            const stopLoading = (message) => store.dispatch(setShadowLoading({
                DevID: message.CData.DeviceIDs,
                status: false
            }));
            const startLoading = (message) => store.dispatch(setShadowLoading({
                DevID: message.CData.DeviceIDs,
                status: true
            }));
            this.createAndAddMessageToQueue(sendTopic, command, null, onSuccess, onFailure, startLoading);
            if (keepAlive) {
                let interval = setInterval(() => {
                    command.MsgId = myID();
                    command.RTime = new Date().getTime();
                    this.createAndAddMessageToQueue(sendTopic, command, null, stopLoading, stopLoading, startLoading);
                }, IntervalTime);
                this.intervalMap.set(id, interval);
            }
            console.error("WYSYLAM");
            return id;
        } else {
            onFailure();
        }
    }

    removeFromInterval(messageId) {
        console.log("removing intervals for -> ", messageId);
        const msgIds = _.isString(messageId) ? [messageId] : _.isArray(messageId) ? messageId : [];
        msgIds.forEach(MsgId => {
            const interval = this.intervalMap.get(MsgId);
            if (interval) {
                clearInterval(interval);
                this.intervalMap.delete(MsgId);
            }
        })

    }

    /**
     * Metoda subskrybuje do kanalu od response a nastepnie wysyla polecenie zakonczenie wysylania aktualnego stanu
     * oraz czysci interval a nastepnie odsubskrbowuje kanal jezeli nie ma zadnego urzadzenia proszacego o aktualny stan
     * @param ClientID      ID klienta
     * @param LocalUserID   ID uzytkownika
     * @param GatewayID     ID gateway (raspberry)
     * @param DeviceIDs
     */
    stopSendingDeviceState(ClientID, LocalUserID, GatewayID, DeviceIDs = []) {
        try {
            let command = {
                MsgId: myID(),
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [MessageCommands.STOP_SENDING_DEV_STATE],
                CData: {DeviceIDs: DeviceIDs}
            };
            // let sendTopic = `devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            let sendTopic = `devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;
            this.createAndAddMessageToQueue(sendTopic, command);
            // console.log(this.deviceStateMap);
            // let interval = this.deviceStateMap.get(DeviceID);
            // console.log(interval);
            // if (interval) {
            //     clearInterval(interval);
            //     this.deviceStateMap.delete(DeviceID);
            // }
            // if (this.deviceStateMap.size === 0) {
            //     let topic = `devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            //     this.unsubscribeTopic(topic);
            // }
        } catch (e) {
            console.error("ERROR: " + e);
        }
    }

    startSendingFeedingState(dev, {onSuccess, onFailure, keepAlive = false} = {}) {
        const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
        if (isValid) {
            let sendTopic = `devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            const id = myID();
            let command = {
                MsgId: id,
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [MessageCommands.GET_FEED_STATE],
                CData: {DeviceIDs: _.isArray(DevID) ? DevID : [DevID]}
            };
            this.createAndAddMessageToQueue(sendTopic, command, null, onSuccess, onFailure);
            if (keepAlive) {
                let interval = setInterval(() => {
                    command.MsgId = myID();
                    command.RTime = new Date().getTime();
                    this.createAndAddMessageToQueue(sendTopic, command, null, null, null);
                }, IntervalTime);
                this.intervalMap.set(id, interval);
            }
            return id;
        } else {
            if (_.isFunction(onFailure)) {
                onFailure();
            }
        }
    }

    startSendingFeedingStateRFID(dev, chamberId, {onSuccess, onFailure, keepAlive = false} = {}) {
        const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
        if (isValid) {
            let sendTopic = `devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            const id = myID();
            let command = {
                MsgId: id,
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [MessageCommands.GET_FEED_RFID_STATE],
                CData: {PlcmntID: chamberId}
            };
            this.createAndAddMessageToQueue(sendTopic, command, null, onSuccess, onFailure);
            if (keepAlive) {
                let interval = setInterval(() => {
                    command.MsgId = myID();
                    command.RTime = new Date().getTime();
                    this.createAndAddMessageToQueue(sendTopic, command, null, null, null);
                }, IntervalTime);
                this.intervalMap.set(id, interval);
            }
            return id;
        } else {
            if (_.isFunction(onFailure)) {
                onFailure();
            }
        }
    }

    /**
     * Metoda wysylajaca zapytanie o zagregowane dane urzadzenia
     * @param dev
     */
    sendRequestForAggregatedData(dev) {
        // console.log(dev);
        const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
        if (isValid) {
            let sendTopic = `devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            let command = {
                MsgId: myID(),
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [MessageCommands.GET_AGGREGATED_DATA],
                CData: {DeviceIDs: _.isArray(DevID) ? DevID : [DevID]}
            };
            //nie wiem czy ten error jest potrzebny tutaj i tak parametrow nie ma przekazanych
            this.createAndAddMessageToQueue(sendTopic, command, null, null, () => {
                console.log(dev);
                let devs = Array.isArray(dev) ? dev : typeof dev === "string" ? [dev] : [dev.DevID];
                for (let dev of devs) {
                    let devID = "";
                    if (typeof dev === "string") devID = dev;
                    else devID = dev.DevID;
                    let device = devicesDB.getDeviceByID(devID);
                    if (device) {
                        store.dispatch(getAggregatedData(device))
                    }
                }
            });
        }
    }

    /**
     * Metoda tworzaca strukture wiadomosci i dodaje do kolejki
     * @param ClientID      ID klienta
     * @param GatewayID     ID gateway
     * @param LocalUserID   ID zalogowanego uzytkownika
     * @param DeviceID      ID urzadzenia
     * @param data          Informacje do przeslania
     * @param Command   Typ komendy np. GET_CONFIGURATION
     * @param notification  Notyfikacja, ktora bedzie wyswietlona w formacie {loading: loadingNotif, success: successNotif, error: errorNotif, DevID: id urzadzenia}
     * @param onSuccess     Funkcja, ktora bedzie wywolana jezeli zapytanie sie uda (msg) => void
     * @param onError       Funkcja, ktora bedzie wywoalana jezeli zapytanie sie nie uda (error) => void
     * @param onSend
     * @returns {string}    ID wysłanej komendy
     */
    createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DeviceID, Command, data = undefined, notification = undefined, onSuccess = undefined, onError = undefined, onSend = undefined) {
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            DeviceId: DeviceID,
            RTime: new Date().getTime(),
            Command: Array.isArray(Command) ? Command : [Command],
            CData: {},
            Priority: 69
        };
        if (data) {
            // tmp.CData = this.compressData(data);
            tmp.CData = data;
        }
        let topic = `devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;

        this.createAndAddMessageToQueue(topic, tmp, notification, onSuccess, onError, onSend);
        return id;
    }

    /**
     * Metoda tworzaca strukture wiadomosci i dodaje do kolejki
     * @param ClientID      ID klienta
     * @param GatewayID     ID gateway
     * @param LocalUserID   ID zalogowanego uzytkownika
     * @param DeviceID      ID urzadzenia
     * @param data          Informacje do przeslania
     * @param Command   Typ komendy np. GET_CONFIGURATION
     * @param notification  Notyfikacja, ktora bedzie wyswietlona w formacie {loading: loadingNotif, success: successNotif, error: errorNotif, DevID: id urzadzenia}
     * @param onSuccess     Funkcja, ktora bedzie wywolana jezeli zapytanie sie uda (msg) => void
     * @param onError       Funkcja, ktora bedzie wywoalana jezeli zapytanie sie nie uda (error) => void
     * @returns {string}    ID wysłanej komendy
     */
    createAndSendMessageObjectToStateTopic(ClientID, GatewayID, LocalUserID, DeviceID, Command, data = undefined, notification = undefined, onSuccess = undefined, onError = undefined) {
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            DeviceId: DeviceID,
            RTime: new Date().getTime(),
            Command: Array.isArray(Command) ? Command : [Command],
            CData: {}
        };
        if (data) {
            // tmp.CData = this.compressData(data);
            tmp.CData = data;
        }
        let topic = `devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
        this.createAndAddMessageToQueue(topic, tmp, notification, onSuccess, onError);
        return id;
    }

    onAddSingleDeviceSuccess(device) {
        store.dispatch(createDeviceDynamoDB(device, device.FarmID));
    }

    onEditSingleDeviceSuccess(device) {
        let state = store.getState();
        store.dispatch(updateDeviceDynamoDB(device, device.FarmID, state.user.user.ClientID, state.user.user.LocalUserID));
    }

    sendSingleDeviceToGateway(GatewayID, device, protocol, onSuccess = undefined, edit = false) {
        const state = store.getState();
        const {user} = state.user;
        let devToSend = {
            DevID: device.DevID,
            ParentID: device.ParentID,
            FarmID: device.FarmID,
            DevAdr: device.Address,
            DevType: device.DevType,
            Protocol: protocol,
            VerHard: device.VerHard,
            VerSoft: device.VerSoft
        };
        if (device.DevType === DevType.BRIDGE) {
            devToSend.Interfaces = device.Interfaces;
        } else {
            devToSend.Interface = device.Interface;
        }
        let notif = {
            loading: {
                title: "Dodawanie urządzenia",
                message: "Dodawanie urządzenia na gateway",
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: "Pomyślnie dodano urządzenie na gateway",
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: "Nie udało się dodać urządzenia na gateway",
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        };
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.UPDATE_SINGLE_DEVICE],
            CData: devToSend,
            DeviceId: GatewayID
        };
        let topic = `devices/commands/${user.ClientID}/${GatewayID}/${user.LocalUserID}`;
        store.dispatch({
            type: "ADD_DEVICE_TO_GATEWAY_PENDING"
        });
        this.createAndAddMessageToQueue(topic, tmp, notif, () => {
            if (edit) {
                this.onEditSingleDeviceSuccess(device);
            } else {
                this.onAddSingleDeviceSuccess(device);
            }
            store.dispatch({
                type: "ADD_DEVICE_TO_GATEWAY_FULFILLED"
            });
            if (onSuccess) onSuccess();
        }, () => {
            store.dispatch({
                type: "ADD_DEVICE_TO_GATEWAY_REJECTED"
            });
        });
    }

    removeDevicesFromDynamoDB(devices, children) {
        console.log(devices);
        let buildingsToChange = new Map();
        let devicesToUpdate = [];
        for (let device of devices) {
            device.removeDevice();
            let locations = device.getLocation();
            console.log(locations);
            let clone = _.cloneDeep(locations);
            for (let location of clone) {
                let id = getLocationID(location);
                console.log(id);
                let tree = buildingsDB.getTreeByLocationID(id);
                console.log(tree);
                let building = _.cloneDeep(tree.building);
                // jezeli urzadzenie bylo przypisane do budynku to usun z budynku i zapisz do mapy
                if (building.BgID === id) {
                    let data = buildingsToChange.get(building.BgID);
                    if (!data) data = building;
                    data.Devices = data.Devices.filter(item => item.DevID !== device.DevID);
                    if (data.Devices.length === 0) delete data.Devices;
                    buildingsToChange.set(data.BgID, data);
                } else {
                    for (let sector of building.Sectors) {
                        // jezeli urzadzenie bylo przypisane do sektora
                        if (sector.SID === id) {
                            sector.Devices = sector.Devices.filter(item => item.DevID !== device.DevID);
                            if (sector.Devices.length === 0) delete sector.Devices;
                            let data = buildingsToChange.get(building.BgID);
                            if (!data) data = building;
                            building.Sectors[tree.sectorIndex] = sector;
                            buildingsToChange.set(data.BgID, data);
                        } else {
                            for (let chamber of sector.Chambers) {
                                // jezeli urzadzenie bylo przypisane do komory
                                if (chamber.CID === id) {
                                    chamber.Devices = chamber.Devices.filter(item => item.DevID !== device.DevID);
                                    if (chamber.Devices.length === 0) delete chamber.Devices;
                                    tree.sector.Chambers[tree.chamberIndex] = chamber;
                                    let data = buildingsToChange.get(building.BgID);
                                    if (!data) data = building;
                                    building.Sectors[tree.sectorIndex] = sector;
                                    buildingsToChange.set(data.BgID, data);
                                } else {
                                    if (chamber.Boxes) {
                                        for (let box of chamber.Boxes) {
                                            // jezeli urzadzenie bylo przypisane do stanowiska
                                            if (box.BID === id) {
                                                box.Devices = box.Devices.filter(item => item.DevID !== device.DevID);
                                                if (box.Devices.length === 0) delete box.Devices;
                                                tree.chamber.Boxes[tree.boxIndex] = box;
                                                tree.sector.Chambers[tree.chamberIndex] = chamber;
                                                let data = buildingsToChange.get(building.BgID);
                                                if (!data) data = building;
                                                building.Sectors[tree.sectorIndex] = sector;
                                                buildingsToChange.set(data.BgID, data);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            devicesToUpdate.push(device.prepareBeanToSave());
        }
        let state = store.getState();
        store.dispatch(updateDeviceDynamoDB(devicesToUpdate, state.location.farm, state.user.user.ClientID, state.user.user.LocalUserID));
        for (let building of buildingsToChange.values()) {
            console.log(building);
            delete building.$loki;
            delete building.meta;
            store.dispatch(updateLocation(building));
        }
        let childrenDevices = children.map(dev => {
            let clone = dev.clone();
            clone.ParentID = "UNBINDED";
            return clone.prepareBeanToSave();
        });
        if (childrenDevices.length > 0) {
            store.dispatch(updateDeviceDynamoDB(childrenDevices, state.location.farm, state.user.user.ClientID, state.user.user.LocalUserID));
        }
        console.log(buildingsToChange, devicesToUpdate);
    }

    sendRemoveDevicesToGateway(GatewayID, devIDs, devices, children) {
        const state = store.getState();
        const {user} = state.user;
        let notif = {
            loading: {
                title: "Usuwanie urządzeń",
                message: "Usuwanie urządzeń na gateway",
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: "Pomyślnie usunięto urządzeń z gateway",
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: "Nie udało się usunąć urządzeń na gateway",
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        };
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.REMOVE_DEVICES],
            CData: devIDs,
            DeviceId: GatewayID
        };
        let topic = `devices/commands/${user.ClientID}/${GatewayID}/${user.LocalUserID}`;
        console.log(tmp, topic, notif);
        this.createAndAddMessageToQueue(topic, tmp, notif, () => {
            this.removeDevicesFromDynamoDB(devices, children);
        });
    }

    saveFeedersToDynamo(msg, forceUpdate = false) {
        console.log(msg);
        if (Array.isArray(msg.CAnsw)) {
            let state = store.getState();
            let devicesToAdd = [];
            let devicesToUpdate = [];
            for (let nrf of msg.CAnsw) {
                let hasDevice = devicesDB.getDeviceByID(nrf.DevID);
                if (!hasDevice) {
                    devicesToAdd.push(Device.createDevice(DevType.DISPENSER_NRF, nrf.DevAdr, nrf.Interface, nrf.DevID, nrf.ParentID, nrf.VerHard, nrf.VerSoft, nrf.Protocol, null, {
                        DevID: nrf.DevID
                    }))
                } else if (hasDevice.DtaDltTime && forceUpdate) {
                    let clone = hasDevice.clone();
                    delete clone.DtaDltTime;
                    clone.setDtaModTime();
                    devicesToUpdate.push(clone.prepareBeanToSave());
                }
            }
            if (devicesToAdd.length > 0) {
                store.dispatch(createDeviceDynamoDB(devicesToAdd, state.location.farm));
            }
            if (devicesToUpdate.length > 0) {
                store.dispatch(updateDeviceDynamoDB(devicesToUpdate, state.location.farm, state.user.user.ClientID, state.user.user.LocalUserID));
            }
        }
    }

    createAndSendDeviceTreeMessage(ClientID, LocalUserID, GatewayID, data) {
        let id = myID();
        // console.log(data);
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.UPDATE_DEVICES],
            CData: data,
            DeviceId: GatewayID
        };
        // console.log(tmp);
        let topic = `devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;
        // console.log(topic);
        this.createAndAddMessageToQueue(topic, tmp);
        return id;
    }

    createAndSendDeviceTreeGetMessage(ClientID, LocalUserID, GatewayID, onSuccess, onFailure) {
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.GET_DEVICES],
            CData: {},
            DeviceId: GatewayID
        };
        let topic = `devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;
        this.createAndAddMessageToQueue(topic, tmp, null, onSuccess, onFailure);
        return id;
    }

    async localAuthUser(email, password, refreshToken, onSuccess, onError) {
        if (!this.client) {
            await this.createLocalClient();
        }
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.AUTH_USER],
            CData: {
                login: email,
                password: password ? password : undefined,
                refreshToken
            }
        };
        let topic = `devices/auth`;
        this.subscribeTopic(topic);
        this.createAndAddMessageToQueue(topic, tmp, null, onSuccess, onError);
        return id;
    }

    getNotificationStatus(DevID, message) {
        try {
            let status;
            if (!_.isNil(message.CAnsw.status)) {
                status = message.CAnsw.status;
            } else if (DevID && message.CAnsw[DevID]) {
                status = message.CAnsw[DevID].status;
            }
            if (status instanceof Array) {
                console.log("StATUS", message);
                switch (message.Command[0]) {
                    case DispenserDriverCommandTypes.STOP_MOTORS:
                    case DispenserDriverCommandTypes.START_MOTORS: {
                        let tmpStatus = true;
                        for (let value of message.CData) {
                            if (status[value.number - 1] === 0) tmpStatus = false;
                        }
                        status = tmpStatus ? CommandStatus.SUCCESS : CommandStatus.COMMAND_REJECTED;
                    }
                        break;
                    case DispenserDriverCommandTypes.SET_FORCE_FEEDING:
                    case DispenserDriverCommandTypes.SET_SKIP_DOSES: {
                        let tmpStatus = true;
                        if (_.isArray(message.CData)) {
                            for (let value of message.CData) {
                                if (status[value.number - 1] !== 0) tmpStatus = false;
                            }
                        } else {
                            if (typeof status !== "number") {
                                if (status[message.CData.number - 1] !== 0) tmpStatus = false;
                            }
                        }

                        status = tmpStatus ? CommandStatus.SUCCESS : CommandStatus.COMMAND_REJECTED;
                    }
                        break;
                    default:
                        status = CommandStatus.COMMAND_REJECTED;
                }
            }
            return status;
        } catch (e) {
            console.error(e);
            return CommandStatus.GET_STATUS_ERROR;
        }
    }

    sendBugsnagReportFromDevice(status, message) {
        const {user: {user: {ClientID}}} = store.getState();
        if (ClientID !== 'TestNewRole') {
            let client = getBugsnagClient();
            client.notify(new Error(`${message.Command[0]} (error)`), {
                severity: 'error',
                metaData: {
                    'feedback': {
                        status: status,
                        user: ClientID,
                        CAnsw: _.get(message, 'CAnsw', 'No CAnsw'),
                        CData: _.get(message, 'CData', 'No CData'),
                        deviceID: message.DeviceId,
                    }
                }
            })
        }
    }

    changeNotification(message, notification) {
        // console.log("changeNotification", message, notification);
        // let notifications = store.getState().notifications;
        // let notif = notifications.filter(item => item.id === message.MsgID)[0];
        // console.log(notif);
        // if(notif){
        //     notif.dismissible = true;
        //     notif.dismissAfter = 5000;
        //TODO tłumaczenia do odpowiedzi
        try {
            let notif = {
                id: notification.id,
                ...notification.loading,
                dismissible: true,
                dismissAfter: 5000
            };
            let status;
            if (_.isArray(notification.DevID)) {
                let okCounter = 0;
                let rejectedCounter = 0;
                let allDevices = notification.DevID.length;
                for (let DevID of notification.DevID) {
                    let st = this.getNotificationStatus(DevID, message);
                    okCounter += st === CommandStatus.SUCCESS ? 1 : 0;
                    rejectedCounter += st === CommandStatus.COMMAND_REJECTED ? 1 : 0;
                }
                if (okCounter === allDevices) {
                    status = CommandStatus.SUCCESS;
                }
                // TODO napisać mechanizm, który rozpozna, na których urządzeniach nie udało się wykonać zapytania i zmieni odpowidnio notyfikacje
                if (rejectedCounter > 0) {
                    status = CommandStatus.COMMAND_REJECTED;
                }
            } else {
                status = this.getNotificationStatus(notification.DevID, message);
            }
            console.log(status);
            let waitedMessage = this.waitedSetMessage.find(item => item.message.MsgId === message.MsgId);
            switch (status) {
                case CommandStatus.SUCCESS:
                    notif = {
                        ...notif,
                        ...notification.success
                    };
                    if (waitedMessage.onSuccess) waitedMessage.onSuccess(message);
                    break;
                case CommandStatus.MESSAGE_PARSER_ERROR:
                    notif.message = i18next.t("IOT.messageParserError");
                    notif.status = "error";
                    this.sendBugsnagReportFromDevice(CommandStatus.MESSAGE_PARSER_ERROR, message);
                    break;
                case CommandStatus.COMMAND_REJECTED:
                    notif.message = i18next.t("IOT.deviceRejectedQuery");
                    notif.status = "error";
                    this.sendBugsnagReportFromDevice(CommandStatus.COMMAND_REJECTED, message);
                    break;
                case CommandStatus.NO_RESPONSE:
                    notif.message = i18next.t("IOT.noResponseFromDevice");
                    notif.status = "error";
                    this.sendBugsnagReportFromDevice(CommandStatus.NO_RESPONSE, message);
                    break;
                case CommandStatus.NO_DATA:
                    notif.message = i18next.t("IOT.noDownloadedDataOnTheDeviceWaitAMomentAndRepeatTheQuery");
                    notif.status = "error";
                    break;
                case CommandStatus.RETURNING_PARSER_ERROR:
                    notif.message = i18next.t("IOT.returningParserError");
                    notif.status = "error";
                    break;
                case CommandStatus.GET_STATUS_ERROR:
                    notif.message = i18next.t("IOT.responseProcessingError");
                    notif.status = "error";
                    break;
                case CommandStatus.NO_PRIVILEGES:
                    notif.message = i18next.t("IOT.noPrivileges");
                    notif.status = "error";
                    break;
                case CommandStatus.DEVICE_ALREADY_FEEDING:
                    notif.message = i18next.t("IOT.alreadyFeeding");
                    notif.status = "error";
                    break;
                default:
                    notif.message = i18next.t("IOT.noStatusRecognized");
                    notif.status = "warning";
                    break;
            }
            if (status !== CommandStatus.SUCCESS && waitedMessage.onError) {
                waitedMessage.onError(null, message);
            }
            store.dispatch(updateNotification(notif));
        } catch (e) {
            console.error(e);
            let waitedMessage = this.waitedSetMessage.find(item => item.message.MsgId === message.MsgId);
            if (waitedMessage) waitedMessage.callback(e, null);
        }
    }

    checkResult(result) {
        let waitedMessage = this.waitedSetMessage.find(item => item.message.MsgId === result.MsgId);
        if (waitedMessage.notification) {
            this.changeNotification(result, waitedMessage.notification);
        } else {
            if (!Array.isArray(result.Command)) result.Command = [result.Command];
            // result.Command.map(command => {
            for (let command of result.Command) {
                switch (command) {
                    case MessageCommands.GET_DEVICES:
                        if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        break;
                    case DispenserNRFCommandTypes.GET_HISTORY_STANDARD:
                    case DispenserNRFCommandTypes.GET_PIG:
                        if (result.CAnsw && result.CAnsw[result.DeviceId] !== undefined && !result.CAnsw[result.DeviceId].status) {
                            Object.keys(result.CAnsw).map(key => {
                                let data = new Map();
                                result.CAnsw[key].RTime = result.RTime;
                                data.set(key, result.CAnsw[key]);
                                store.dispatch(changeShadowState(data));
                                return key;
                            });
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message)
                        }
                        break;
                    case DispenserNRFCommandTypes.GET_LOGS:
                        if (result.CAnsw && result.CAnsw[result.DeviceId] !== undefined) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message)
                        }
                        break;
                    case GatawayCommandTypes.GET_GATEWAY_TIME:
                        if (result.CAnsw && result.CAnsw.time !== undefined) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message)
                        }
                        break;
                    case MessageCommands.GET_FULL_DEV_STATE:
                        if (result.CAnsw && Object.keys(result.CAnsw).length > 0) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message)
                        }
                        break;
                    case MessageCommands.GET_NRF_FEEDERS:
                        if (result.CAnsw && Array.isArray(result.CAnsw)) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message)
                        }
                        break;
                    case GatawayCommandTypes.PING:
                        if (result.CAnsw && result.CAnsw.status === 2) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case SeparationCageCommandTypes.GET_SENSORS_AND_VALVES:
                        if (result.CAnsw && result.CAnsw.sensors && result.CAnsw.valves) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case ClimateDriverCommandTypes.GET_CONFIGURATION:
                        let devIDs = Array.isArray(result.DeviceId) ? result.DeviceId : [result.DeviceId];
                        let hasAll = true;
                        for (let id of devIDs) {
                            if (!result.CAnsw[id].configuration) hasAll = false;
                        }
                        if (hasAll) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case GatawayCommandTypes.GET_NRF_STATS:
                        if (result.CAnsw && result.CAnsw.stats) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case MessageCommands.AUTH_USER:
                        if (result.CAnsw && !result.CAnsw.Code) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case BridgeCommandTypes.B_INFO:
                        if (result.CAnsw && result.CAnsw[result.DeviceId[0]] && result.CAnsw[result.DeviceId[0]].b_info) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case BridgeCommandTypes.B_BOOT_INFO:
                        if (result.CAnsw && result.CAnsw[result.DeviceId[0]] && result.CAnsw[result.DeviceId[0]].boot_info) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case BridgeCommandTypes.B_BOOT_INFO_NRF:
                        if (result.CAnsw && result.CAnsw[result.DeviceId[0]] && result.CAnsw[result.DeviceId[0]].boot_info_nrf) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case BridgeCommandTypes.B_NRF_STAT:
                        if (result.CAnsw && result.CAnsw[result.DeviceId[0]] && result.CAnsw[result.DeviceId[0]].nrf_stat) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case BridgeCommandTypes.B_485_TOUT_R:
                        if (result.CAnsw && result.CAnsw[result.DeviceId[0]] && result.CAnsw[result.DeviceId[0]].rs485_tout) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case BridgeCommandTypes.B_GET_SCAN_ADDR:
                        if (result.CAnsw && result.CAnsw[result.DeviceId[0]] && result.CAnsw[result.DeviceId[0]].scan_addr) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case GatawayCommandTypes.GET_QUEUES_LAST_SUCCESS:
                        if (result.CAnsw && result.CAnsw.queues) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case GatawayCommandTypes.GET_PIGS_DATA:
                        if (result.CAnsw && (result.CAnsw.curveCfg || result.CAnsw.pigs)) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case GatawayCommandTypes.GET_ASCII_CLIMATE_TESTING_DATA:
                        if (result.CAnsw && result.CAnsw.data) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case MessageCommands.GET_FEED_RFID_STATE: {
                        if (result.CAnsw && !result.CAnsw.status) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    }
                    case GatawayCommandTypes.GET_ALL_ALARMS:
                    case GatawayCommandTypes.GET_ALL_ONGOING_ALARMS: {
                        if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        break;
                    }
                    case GatawayCommandTypes.GET_FEEDING_DATA_FOR_PIG:
                    case GatawayCommandTypes.GET_USAGE_FOR_PIG:
                        console.log(result.CAnsw.result, result.CAnsw.result !== null)
                        if (result.CAnsw && Array.isArray(result.CAnsw)) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case MessageCommands.REFRESH_TOKEN:
                        if (result.CAnsw && result.CAnsw.token) {
                            if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    case DispenserDriverCommandTypes.SET_SKIP_DOSES: {
                        if (result.CAnsw) {
                            if (Array.isArray(result.CAnsw.status)) {
                                let isSuccess = true;
                                for (let item of result.CAnsw.status) {
                                    if (item) isSuccess = false;
                                }
                                if (isSuccess) {
                                    if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                                } else {
                                    if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                                }
                            } else if (result.CAnsw.status === 2) {
                                if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                            } else {
                                if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                            }
                        } else {
                            if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message);
                        }
                        break;
                    }
                    default:
                        if (result.CAnsw) {
                            if (result.CAnsw[result.DeviceId] !== undefined) {
                                if (result.CAnsw[result.DeviceId].status !== 2) {
                                    if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message)
                                } else {
                                    if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                                }
                            }
                            if (result.CAnsw.status) {
                                if (result.CAnsw.status !== 2) {
                                    if (waitedMessage.onError) waitedMessage.onError(null, waitedMessage.message)
                                } else {
                                    if (waitedMessage.onSuccess) waitedMessage.onSuccess(result);
                                }
                            }
                        }
                        console.warn("Brak notyfikacji i nie rozpoznano komendy");
                }
            }
        }
        this.waitedSetMessage = this.waitedSetMessage.filter(item => item.message.MsgId !== result.MsgId);
    }

    checkCommandByType(commands, type) {
        try {
            let cmnds = Array.isArray(commands) ? commands : [commands];
            for (let i = 0; i < cmnds.length; i++) {
                if (cmnds[i] === type) {
                    return true;
                }
            }
            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    /**
     * Zwraca czy oczekiwano na daną komendę
     * @param commands
     * @returns {boolean}
     */
    checkCommands(commands) {
        try {
            let isWaitedMessage = true;
            for (let i = 0; i < commands.length; i++) {
                if (!commands[i].startsWith("SET") && !this.specialMessages.includes(commands[i])) isWaitedMessage = false;
            }
            return isWaitedMessage;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    checkIfWaitedForThisMessage(message) {
        return this.waitedSetMessage.filter(item => item.message.MsgId === message.MsgId).length > 0;
    }

    /**
     *
     * @param topic example devices/state/WesstronNewiot1/GW_MAR_18_08_14_093608856
     * @param message
     */
    onStateMessageArrived(topic, message) {
        console.warn("NEW STATE MESSAGE", topic, message);
        try {
            if (message.PktType === MessageTypes.BIG_RESPONSE) {
                // message.CAnsw = this.decompressData(message.CAnsw);
                // const [, , ClientID, GatewayID, LocalUserID] = topic.split("/");
                // console.log(ClientID, GatewayID, LocalUserID);
                //sprawdzenie czy odpowiedz jest obiektem a nie np stringiem typu 'nie znaleziono urzadzenia: blabla'
                if (_.isObject(message.CAnsw) && !message.CAnsw.status) {
                    let data = new Map();
                    Object.keys(message.CAnsw).map(key => {
                        if (!message.CAnsw[key].status) {
                            data.set(key, message.CAnsw[key]);
                        }
                    });
                    if (!_.isEmpty(data)) {
                        if (this.checkCommandByType(message.Command, MessageCommands.GET_FULL_DEV_STATE)) {
                            store.dispatch(changeShadowState(data));
                        } else if (this.checkCommandByType(message.Command, MessageCommands.GET_FULL_DEV_STATE_DELTA)) {
                            store.dispatch(changeShadowState(data, true));
                            let noShadowIDs = [];
                            let state = store.getState();
                            let shadows = state.shadows.shadows;
                            for (let DevID in message.CAnsw) {
                                if (!shadows.get(DevID)) {
                                    noShadowIDs.push(DevID);
                                }
                            }
                            if (noShadowIDs.length > 0) {
                                this.startSendingDeviceState(noShadowIDs);
                            }
                        } else if (this.checkCommandByType(message.Command, DispenserNRFCommandTypes.GET_DAILY_USAGE)) {
                            store.dispatch(changeAnimalShadow(data));
                        } else if (this.checkCommandByType(message.Command, DispenserNRFCommandTypes.GET_DAILY_USAGE_DELTA)) {
                            store.dispatch(changeAnimalShadow(data, true));
                        } else if (this.checkCommandByType(message.Command, GatawayCommandTypes.ADDRESS_DELTA)) {
                            store.dispatch(changeAddressingState(message.CAnsw));
                        } else if (this.checkCommandByType(message.Command, MessageCommands.GET_FEED_STATE)) {
                            store.dispatch(getFeedState(data));
                        } else if (this.checkCommandByType(message.Command, MessageCommands.GET_FEED_RFID_STATE)) {
                            store.dispatch(getFeedStateRFID(data));
                        } else if (this.checkCommandByType(message.Command, MessageCommands.GET_FEED_STATE_DELTA)) {
                            store.dispatch(getFeedStateDelta(data));
                        } else if (this.checkCommandByType(message.Command, MessageCommands.GET_FEED_RFID_STATE_DELTA)) {
                            store.dispatch(getFeedStateRFIDDelta(data));
                        } else if (this.checkCommandByType(message.Command, GatawayCommandTypes.PING)) {
                            store.dispatch(onPingSuccess(message, message.DeviceId[0]));
                        }
                    }
                }
                if (this.checkIfWaitedForThisMessage(message)) {
                    let waitedMessage = this.waitedSetMessage.find(item => item.message.MsgId === message.MsgId);
                    waitedMessage.callback(null, message);
                }
            }
            if (message.PktType === MessageTypes.LAMBDA) {
                function backgroundTask() {
                    const state = store.getState();
                    const {location: {farm}, user: {user}, task: {date}} = state;
                    switch (message.Table) {
                        case LambdaTables.ANIMALS:
                            if (farm) {
                                store.dispatch(listAnimalDynamoDB(farm));
                            }
                            break;
                        case LambdaTables.BUILDINGS:
                            if (farm) {
                                store.dispatch(fetchBuildingsByFarmID(farm));
                            }
                            break;
                        case LambdaTables.DEVICES:
                            if (farm) {
                                store.dispatch(fetchDevices(farm));
                            }
                            break;
                        case LambdaTables.DICTIONARY:
                            if (user) {
                                store.dispatch(listDictionariesDynamoDB(user.ClientID));
                            }
                            break;
                        case LambdaTables.EVENTS:
                            if (farm) {
                                store.dispatch(getEvents(farm));
                            }
                            break;
                        case LambdaTables.NOTIFICATIONS:
                            //todo: fix lags on every notification insertion
                            if (user && farm) {
                                store.dispatch(getNotificationsDynamoDB(farm, user.LocalUserID));
                            }
                            break;
                        case LambdaTables.SETTINGS:
                            if (user && farm) {
                                store.dispatch(getSettings(user.ClientID, farm))
                            }
                            break;
                        case LambdaTables.TECHNO_GROUPS:
                            if (farm) {
                                store.dispatch(listTechnologyGroupDynamoDB(farm));
                            }
                            break;
                        case LambdaTables.USERS:
                            if (user) {
                                store.dispatch(getEmployees(user.ClientID));
                            }
                            break;
                        case LambdaTables.GROUPS:
                            if (farm) {
                                store.dispatch(listGroupsDynamoDB(farm));
                            }
                            break;
                        case LambdaTables.ATHENA:
                            if (user && farm) {
                                store.dispatch(listAthenaReports(farm, user.ClientID, user.LocalUserID));
                            }
                            break;
                        case LambdaTables.SETTLEMENTS: {
                            if (farm) {
                                store.dispatch(listSettlementsDynamoDB(farm));
                            }
                            break;
                        }
                        case LambdaTables.TRANSLATIONS: {
                            if (user && message.LocalUserID !== user.LocalUserID && (user.UserType === UserTypes.SERVICE || (user.UserType === UserTypes.TRANSLATOR || user.Roles.find((r) => r.Role === RoleTypes.TRANSLATION)))) {
                                store.dispatch(getTranslation([message.Language], false))
                            }
                            break;
                        }
                        case LambdaTables.CHANGE_CLIENT: {
                            if (message.FarmID !== farm) {
                                store.dispatch({type: "REFRESH_TOKEN_FULFILLED", meta: {ClientID: message.ClientID}});
                                store.dispatch(redirect("/farmChooser"));

                            }
                            break;
                        }
                        case LambdaTables.ITS: {
                            if (date) {
                                let timestamp = +moment.utc(date);
                                if (message.CData.affectedDates.includes(timestamp)) {
                                    store.dispatch(getTasksAPI(timestamp));
                                }
                            }
                            break;
                        }
                        default:
                            console.warn("Nie rozpoznano tabeli");
                    }
                }

                if ('requestIdleCallback' in window) {
                    console.log("JEST requestIdleCallback");
                    requestIdleCallback(backgroundTask);
                } else {
                    setTimeout(backgroundTask, 0);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    onAggregatedDataMessageArrived(topic, message) {
        console.warn("NEW AGGREGATED DATA MESSAGE", message);
        store.dispatch({
            type: "AGGREGATED_DATA_MQTT",
            payload: message
        })
    }

    onAlarmsMessageArrived(topic, message) {
        // message.CAnsw = this.decompressData(message.CAnsw);
        console.warn("NEW ALARMS MESSAGE", message);
        let alarmsIOT = new AlarmsIOT();
        alarmsIOT.onMessage(message);
    }

    onResponsesMessageArrived(topic, message) {
        console.warn(`NEW RESPONSES MESSAGE`, message);
        if (message.PktType === MessageTypes.BIG_RESPONSE) {
            // message.CAnsw = this.decompressData(message.CAnsw);
            message.Command && !_.isArray(message.Command) && (message.Command = [message.Command]);
            store.dispatch({
                type: "TERMINAL_ADD_MESSAGE",
                payload: {[topic]: message}
            });
            if (message.Command && this.checkCommands(message.Command)) {
                console.log("")
                if (this.checkIfWaitedForThisMessage(message)) {
                    let waitedMessage = this.waitedSetMessage.find(item => item.message.MsgId === message.MsgId);
                    console.log("waited", waitedMessage);
                    waitedMessage.callback(null, message);
                }
            }
            //tylko DEV_STATE & GET_DAILY_USAGE MOGA ZMIENIAC SHADOWY
            // else {
            //     let shadows = store.getState().shadows.shadows;
            //     Object.keys(message.CAnsw).map(key => {
            //         if (!message.CAnsw[key].status) {
            //             let data = new Map();
            //             let shadow = shadows.get(key);
            //             data.set(key, {...shadow, ...message.CAnsw[key]});
            //             store.dispatch(changeShadowState(data));
            //         }
            //     });
            // }
        }
    }

    onAuthMessageArrive(topic, message) {
        console.warn(`NEW AUTH MESSAGE`, message);
        if (message.PktType === MessageTypes.BIG_RESPONSE) {
            // message.CAnsw = this.decompressData(message.CAnsw);
            if (message.Command && this.checkCommands(message.Command)) {
                if (this.checkIfWaitedForThisMessage(message)) {
                    let waitedMessage = this.waitedSetMessage.find(item => item.message.MsgId === message.MsgId);
                    if (message.CAnsw.code) {
                        waitedMessage.callback(message.CAnsw, null);
                    } else {
                        waitedMessage.callback(null, message);
                    }
                }
            }
        }
    }


    /**
     * Funkcja kolekcjujaca wiadomosci wieksze niz 128kb
     * dla wiadomosci mniejszych pozostaje bez zmian.
     * Rozkodowanie wiadomosci jest na wczesniejszym etapie
     *
     * @param data
     * @param topicType
     * @return {null|any}
     */
    collectMessage(data, topicType = "") {

        //console.warn("decompressData DATA1",data)
        if (data.ChunksID) {
            let msg = this.msgChunks.get(data.ChunksID);
            if (msg) {
                //console.warn("decompressData DATA",data)
                msg[data.ChunksOffset] = data.CAnsw;
                this.msgChunks.set(data.ChunksID, msg);
                let howManyRec = 0;
                msg.forEach(value => {
                    if (value !== {}) {
                        howManyRec++;
                    }
                })
                if (howManyRec === data.ChunksSize) {

                    let _data = "";
                    msg.map(m => {
                        _data += m;
                    })
                    //console.warn("decompressData parse",_data,msg)

                    return this.decompressData(_data, topicType);
                }
            } else {
                //console.warn("decompressData collect",data)
                let chunks = [].fill({}, 0, data.ChunksSize);
                chunks[data.ChunksOffset] = data.CAnsw;
                this.msgChunks.set(data.ChunksID, chunks);
                return null;
            }

        } else {
            if (topicType === TopicTypes.AGGREGATED_DATA) {
                return this.decompressData(data.AggDt, topicType)
            } else {
                return this.decompressData(data.CAnsw, topicType)
            }
        }
    }


    onMessageArrived(topic, message) {
        try {
            console.warn("NEW MESSAGE", topic, message);
            let topicType = topic.split("/")[1];
            // console.log(topicType);
            let msg = JSON.parse(message.toString('utf-8'));
            // console.warn("NEW MESSAGE MSG", msg);
            let msgCollected;
            if (msg.CAnsw) {
                msgCollected = this.collectMessage(msg, topicType);
                msg.CAnsw = msgCollected;
            } else {
                msgCollected = true;
            }

            if (msgCollected) {
                console.log("MSG COLLECTED", msg);
                switch (topicType) {
                    case TopicTypes.AGGREGATED_DATA:
                        this.onAggregatedDataMessageArrived(topic, msg);
                        break;
                    case TopicTypes.ALARMS:
                        this.onAlarmsMessageArrived(topic, msg);
                        break;
                    case TopicTypes.RESPONSES:
                        this.onResponsesMessageArrived(topic, msg);
                        break;
                    case TopicTypes.STATE:
                        this.onStateMessageArrived(topic, msg);
                        break;
                    case TopicTypes.AUTH:
                        this.onAuthMessageArrive(topic, msg);
                        break;
                    default:
                        console.error("Nie rozpoznano kanału");
                        break;
                }
            }
        } catch (err) {
            console.error("tutaj", err);
        }
    }
}

const _newIOT = new NewIOT();
export default _newIOT;
