import {invokeApig} from '../libs/awsLib';
import {addNotification} from "reapop";
import store from "../store/store"
import {show} from "redux-modal";
import {ModalName as LogoutModal} from "../components/modals-new/delayed-action/DelayedActionModal"
import Paths from "../api/paths";
import i18next from "i18next";
import {get} from "lodash";
import * as RoleTypes from "validators-schema/Api/constants/roleTypes";
import {checkIfUserIsService} from "../utils/NewRolesUtils";

export function gatewayGenerateToken(customerId, withCustomerID) {
    return (dispatch) => {
        dispatch({
            type: "BT_GENERATE_CLIENT_TOKEN", payload: invokeApig({
                ...Paths.generateToken({customerId}),
                queryParams: {withCustomerID: withCustomerID}
            })
        }).catch(err => {
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.tokenErr"),
                status: 'error',
                dismissible: true
            };
            dispatch(addNotification(msg));
            console.error("gatewayGenerateToken: ", err)
        })
    }
}

export function gatewaySubscriptionSearch(farmIDs, forceFetch = false) {
    return (dispatch) => {
        let farms = store.getState().farms.farms;
        dispatch({
            type: "BT_SEARCH_SUBSCRIPTION", payload: invokeApig({
                ...Paths.searchSubscription(),
                queryParams: {forceFetch, farmIDs}
            }),
            meta: {
                farms
            }
        }).catch(err => {
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.getFarmSubsErr"),
                status: 'error',
                dismissible: true
            };
            console.error("gatewaySubscriptionSearch: ", err);
            dispatch(addNotification(msg))
        })
    }
}

export function gatewayCreatePaymentMethod(nonceFromTheClient, customerId, forceFetch = false) {
    return (dispatch) => {
        dispatch({
            type: "BT_CREATE_PAYMENT_METHOD", payload: invokeApig({
                ...Paths.createPaymentMethod({customerId}),
                queryParams: {nonceFromTheClient: nonceFromTheClient}
            })
        }).then(obj => {
            let msg = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.paymentOk") + obj.action.payload.paymentMethod.cardType + ")",
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(msg))
        }).catch(err => {
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.paymentErr") + err,
                status: 'error',
                dismissible: true
            };
            console.error("gatewayCreatePaymentMethod: ", err);
            dispatch(addNotification(msg));
            dispatch(gatewayCustomerFind("", "", "", customerId, forceFetch));
        })
    }
}

export function gatewayUpdatePaymentMethod(token, data, customerId) {
    return (dispatch) => {
        dispatch({
            type: "BT_UPDATE_PAYMENT_METHOD", payload: invokeApig({
                ...Paths.updatePaymentMethod({customerId}),
                queryParams: {paymentMethodToken: token},
                body: data
            })
        }).then(() => {
            let msg = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.updatePaymentOK"),
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(msg));
            dispatch(gatewayCustomerFind("", "", "", customerId));
        }).catch(err => {
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.updatePaymentError") + err,
                status: 'error',
                dismissible: true
            };
            console.error("gatewayCreatePaymentMethod: ", err);
            dispatch(addNotification(msg));
            dispatch(gatewayCustomerFind("", "", "", customerId));
        })
    }
}

export function gatewayCustomerCreate(email, name, phone, sub) {
    return (dispatch) => {
        dispatch({
            type: "BT_CREATE_CUSTOMER", payload: invokeApig({
                ...Paths.customerCreate({customerId: sub}),
                body: {
                    firstName: name.substr(0, name.indexOf(' ')),
                    lastName: name.substr(name.indexOf(' ') + 1),
                    phone: phone,
                    email: email,
                    id: sub
                }
            })
        }).then(() => {
            dispatch(gatewayCustomerFind(email, name, phone, sub));
            dispatch(gatewayGenerateToken(sub, true));
        }).catch(err => {
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.createUserErr"),
                status: 'error',
                dismissible: true
            };
            dispatch(addNotification(msg));
            console.error("gatewayCustomerCreate: ", err)
        })
    }
}

export function gatewayCustomerFind(email, name, phone, customerId, forceFetch = false) {
    return (dispatch) => {
        const {farms: {farms}, user: {user: {ClientID, clientSub, FarmData}}} = store.getState();
        const isService = checkIfUserIsService();
        const sub = isService ? clientSub : customerId;
        dispatch({
            type: "BT_FIND_CUSTOMER", payload: invokeApig({
                ...Paths.customerFind({customerId: sub}),
                queryParams: {forceFetch}
            }),
            meta: {
                farms
            }
        }).then((res) => {
            if (res && res.value && !res.value.hasOwnProperty("name") && !isService) {
                dispatch(gatewayGenerateToken(sub, true));
            }
            if (res && res.value && res.value.name === "notFoundError" && !isService) {
                dispatch(gatewayCustomerCreate(email, name, phone, sub))
            } else {
                const rights = !isService ? get(store.getState(), "user.user.Roles", []).find((role) => role.Role === RoleTypes.BILLING).LocalRights : FarmData.filter((fd) => fd.ClientID === ClientID);
                rights.push({FarmID: ClientID});
                const farmIDs = rights.map((lr) => lr.FarmID);
                dispatch(gatewaySubscriptionSearch(farmIDs, forceFetch));
            }
        }).catch(() => {
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.findUserErr"),
                status: 'error',
                dismissible: true
            };
            dispatch(addNotification(msg));
            if (!isService) {
                dispatch(gatewayCustomerCreate(email, name, phone, sub))
            }
        });
    }
}

/**
 * Ponowienie płatności dla fermy lub subskrypcji admina, wysyłać jedno z 2 ID
 * @param farmID        id fermy
 * @param clientID      id klienta
 * @return {Function}
 */
export function retryPayment({farmID, clientID}) {
    return (dispatch) => {
        dispatch({
            type: "BT_RETRY_PAYMENT", payload: invokeApig({
                ...Paths.retryPayment(),
                body: {
                    FarmID: farmID,
                    ClientID: clientID
                }
            })
        }).then(() => {
            let msg = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.retryPaymentSuccess"),
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(msg));
        }).catch(() => {
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.retryPaymentError"),
                status: 'error',
                dismissible: true
            };
            dispatch(addNotification(msg));
        });
    }
}

export function terminateAccount({farmID, clientID, localUserID, subs, pays}) {
    return (dispatch) => {
        dispatch({
            type: "BT_TERMINATE_ACCOUNT", payload: invokeApig({
                ...Paths.terminateAccount({clientID: clientID, localUserID: localUserID}),
                body: {
                    FarmID: farmID,
                    ClientID: clientID,
                    LocalUserID: localUserID,
                    subs: subs,
                    pays: pays
                }
            })
        }).then(() => {
            let msg = {
                title: i18next.t("success"),
                status: 'success',
                message: i18next.t("popNotifications.terminateAccountSuccess"),
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(msg));
            dispatch(show(LogoutModal, {counter: 5}));
        }).catch(() => {
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.terminateAccountError"),
                status: 'error',
                dismissible: true
            };
            dispatch(addNotification(msg));
        });
    }
}

export function gatewayTransactionHistory(customerId, forceFetch = false) {
    const {user: {user: {clientSub}}} = store.getState();
    const sub = checkIfUserIsService() ? clientSub : customerId;
    return (dispatch) => {
        dispatch({
            type: "BT_TRANSACTION_HISTORY", payload: invokeApig({
                ...Paths.transactionHistory({customerId: sub}),
                queryParams: {forceFetch}
            })
        })
    }
}

export function gatewayCustomerDelete(customerId) {
    return (dispatch) => {
        dispatch({
            type: "BT_DELETE_CUSTOMER", payload: invokeApig({
                ...Paths.customerDelete({customerId})
            })
        });
    }
}

export function storeDropinInstance(instance) {
    return function (dispatch) {
        dispatch({
            type: "BT_STORE_DROPIN_INSTANCE",
            payload: instance
        })
    }
}

export function initializeDropin() {
    return function (dispatch) {
        dispatch({
            type: "BT_INITIALIZE_DROPIN"
        })
    }
}

export function setPaymentMethodRequestable(bool) {
    return function (dispatch) {
        dispatch({
            type: "BT_SET_PAYMENT_METHOD_REQUESTABLE",
            payload: bool
        })
    }
}

export function changeSelectedSubscription(subscription) {
    return (dispatch) => {
        dispatch({
            type: "BT_CHANGE_SELECTED_SUBSCRIPTION",
            payload: {
                subscription: subscription
            }
        })
    }
}

export function updateSelectedPlans(planName) {
    return function (dispatch) {
        dispatch({
            type: "BT_UPDATE_SELECTED_PLANS",
            payload: planName
        });
    }
}
