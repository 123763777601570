import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";

/**
 * @typedef InsertionData
 * @type {object}
 * @property AnmNo1 {string}            numer zwierzecia
 * @property AnimalKind {number}        typ zwierzecia
 * @property AnmCnt {number}            ilosc zwierzat w obiekcie
 * @property PlcmntID {string}          miejsce zwierzecia
 * @property additionalData {object}    dodatkowee informacje o zwierzeciu (waga, itp.)
 * @property devices {object}           urzedzenia przypisane do miejsca {DevID, GwID}
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowej mamki
 * @param data {[InsertionData]}    Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createInsertion(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createInsertion({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createInsertionNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Wprowadzenie",
        message: "Pomyślnie dodano zwierzęta",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createInsertionNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Wprowadzenie",
        message: "Nie udało się zapisać zwierząt",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}