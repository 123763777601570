import NewIOT from "../NewIOT";
import {DispenserNRFCommandTypes} from "../../constans/mqttMessages";
import {getIOTAttributes} from "../../utils/IOTUtils";
import i18next from "i18next";

/*
    maxOneDose,
    initDose,
    timeOrImpulse,
    workType
 */
export function setWorkType(dev, {
    maxOneDose,
    initDose,
    timeOrImpulse,
    optButt,
    workType
} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_WORK_TYPE, {
            maxOneDose,
            initDose,
            timeOrImpulse,
            optButt,
            workType
        }, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setOperationMode", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setOperationModeDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setOperationModeFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setMotorSetup(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_MOTOR_SETUP, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setMotorsConfiguration", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setMotorsConfigurationDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setMotorsConfigurationFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}


export function setFeedEfcTime(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FEED_EFC_TIME, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setEfficiencyTime", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setEfficiencyTimeDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setEfficiencyTimeFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setFeedEfcImpulse(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FEED_EFC_IMPULSE, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setEfficiencyImpulse", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setEfficiencyImpulseDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setEfficiencyImpulseFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function getLogs(dev, onSuccess, onFailure, onSend) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_LOGS, null, null, onSuccess, onFailure, onSend);
    }
}

export function deleteLogs(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.DELETE_LOGS, null, {
            loading: {
                title: i18next.t("logs"),
                message: i18next.t("IOT.removeLogs", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.removeLogsDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.removeLogsFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setLock(dev, status, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    let notification = {
        loading: {
            title: i18next.t("IOT.outputLocks"),
            message: i18next.t("IOT.setOutputLocks", {name: Name}),
            status: 'loading',
            dismissible: false,
            dismissAfter: 0
        },
        success: {
            message: i18next.t("IOT.setOutputLocksDone", {name: Name}),
            dismissible: true,
            dismissAfter: 3000,
            status: "success"
        },
        error: {
            message: i18next.t("IOT.setOutputLocksFailed", {name: Name}),
            dismissible: true,
            dismissAfter: 3000,
            status: "error"
        },
        DevID: DevID
    };
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_LOCK, {isLocked: !!status}, notification, onSuccess, onFailure);
    }

}


export function startMotor(dev, data, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.START_MOTORS, data, {
            loading: {
                title: i18next.t("motor"),
                message: i18next.t("IOT.startMotor", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.startMotorDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.startMotorFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }

}

export function stopMotor(dev, onSuccess) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.STOP_MOTORS, null, {
            loading: {
                title: i18next.t("motor"),
                message: i18next.t("IOT.stopMotor", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.stoppedTheMotorOn", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.coultNotStopTheEngineAt", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, undefined, undefined);
    }

}

/**
 * Function to configure pig on RFID stand
 * @param dev - Gateway/GatewayID
 * @param data - configuration data structure {
 *     PlcmntID: chamber locationID as string.
 *     animals: array of pigs [{
 *         RFID: rfid number,
 *         AnmNo1: animal number
 *         AnmID: animal ID,
 *         curveCfg:{
 *             curveNr - curve number 0 - 15
 *             offset
 *             percentageCorrection
 *             startTime
 *             punishment
 *         }
 *     }]
 * }
 * @param animals
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @deprecated use setAnimal
 * @returns {string}
 */
export function addPig(dev, data, animals, onSuccess, onFailure = null, hideNotification = false) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.ADD_PIG, data, !hideNotification ? {
            loading: {
                title: i18next.t("IOT.setFeedingTitle"),
                message: i18next.t("IOT.setFeeding", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setFeedingDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setFeedingFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

/**
 * @deprecated use setAnimal
 * @param dev
 * @param data
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @return {string}
 */
export function setConfigStandard(dev, data, onSuccess, onFailure, hideNotification = false) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    console.log(ClientID, LocalUserID, GatewayID, DevID, Name, isValid);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CONFIG_STANDARD, data, !hideNotification ? {
            loading: {
                title: i18next.t("IOT.setFeedingTitle"),
                message: i18next.t("IOT.setFeeding", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setFeedingDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setFeedingFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

/**
 *
 * @param dev
 * @param data
 * @returns {string}
 */
export function setTypeEvent(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    //console.log(ClientID, LocalUserID, GatewayID, DevID, Name, isValid);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_TYPE_EVENT, data, {
            loading: {
                title: i18next.t("IOT.setDispenserNRFTypeEvent"),
                message: i18next.t("IOT.setDispenserNRFTypeEventText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setDispenserNRFTypeEventSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setDispenserNRFTypeEventError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getHistoryStandard(ClientID, LocalUserID, device, onSuccess, onFailure) {
    return NewIOT.createAndSendMessageObject(ClientID, device.GatewayID, LocalUserID, device.DevID, DispenserNRFCommandTypes.GET_HISTORY_STANDARD, null, null, onSuccess, onFailure);
}

/**
 *
 * @param dev
 * @param alert
 * @returns {string}
 */
export function setAlert(dev, alert = false) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    //console.log(ClientID, LocalUserID, GatewayID, DevID, Name, isValid);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ALERT, {alert: !!alert}, {
            loading: {
                title: i18next.t(`IOT.${alert ? "setDispenserNRFAlarm" : "clearDispenserNRFAlarm"}`),
                message: i18next.t(`IOT.${alert ? "setDispenserNRFAlarmText" : "clearDispenserNRFAlarmText"}`, {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t(`IOT.${alert ? "setDispenserNRFAlarmSuccess" : "clearDispenserNRFAlarmSuccess"}`, {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t(`IOT.${alert ? "setDispenserNRFAlarmError" : "clearDispenserNRFAlarmError"}`, {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getUploadSoftwareStatus(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_UPLOAD_SOFTWARE_STATUS);
    }
}

export function getConfiguration(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_CONFIGURATION);
    }
}

export function getCurveCRC(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_CURVE_CRC);
    }
}

export function getScheduleCRC(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_SCHEDULE_CRC);
    }
}

export function getFeedEfcTime(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_FEED_EFC_TIME);
    }
}

export function getFeedEfcImpulse(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_FEED_EFC_IMPULSE);
    }
}

export function getCANStatus(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_CAN_STATUS);
    }
}

export function getSkipDoses(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_SKIP_DOSES);
    }
}

/**
 *
 * @param dev
 * @param data {object}
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @param data.dosesToSkip {array}
 * @returns {string}
 */
export function setSkipDoses(dev, data, onSuccess, onFailure, hideNotification) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SKIP_DOSES, data, !hideNotification ? {
            loading: {
                title: i18next.t("IOT.skipDose"),
                message: i18next.t("IOT.setSkipDose", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSkipDoseDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSkipDoseFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}


/**
 *
 * @param dev
 * @param data {array}
 * @returns {string}
 */
export function setPunishmentSetup(dev, data = []) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_MINUS_CONFIG, data, {
            loading: {
                title: i18next.t("punishmentSetup"),
                message: i18next.t("IOT.setPunishmentSetup", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setPunishmentSetupDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setPunishmentSetupFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

/**
 *
 * @param dev
 * @param data
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @param data.dose dawka g
 * @param data.forage index
 * @return {string}
 */
export function setForceFeeding(dev, data, onSuccess, onFailure, hideNotification = false) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FORCE_FEEDING, data, !hideNotification ? {
            loading: {
                title: i18next.t("extraFeeding"),
                message: i18next.t("IOT.setAdditionalFeeding", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAdditionalFeedingDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAdditionalFeedingFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }
}

export function setEmptyDispenser(dev, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FORCE_FEEDING, {
            dose: 12750, type: 0
        }, {
            loading: {
                title: i18next.t("emptyDispenser"),
                message: i18next.t("IOT.setEmptyDispenser", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setEmptyDispenserDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setEmptyDispenserFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

/**
 *
 * @param dev
 * @param state
 * @returns {string}
 */
export function setSwitchMotorOnInfo(dev, state) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SWITCH_MOTOR_ON_INFO, {state}, {
            loading: {
                title: i18next.t("IOT.setSwitchMotorOnInfo"),
                message: i18next.t("IOT.setSwitchMotorOnInfoText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSwitchMotorOnInfoSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSwitchMotorOnInfoError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

/**
 *
 * @param dev
 * @param section
 * @returns {string}
 */
export function setClearFRAM(dev, section) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CLEAR_FRAM, {section}, {
            loading: {
                title: i18next.t("IOT.setClearFRAM"),
                message: i18next.t("IOT.setClearFramText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setClearFramSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setClearFramError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

/**
 *
 * @param dev
 * @param t
 * @returns {string}
 */
export function setNRFPower(dev, t) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_NRF_POWER, {t}, {
            loading: {
                title: i18next.t("IOT.setNRFPower"),
                message: i18next.t("IOT.setNRFPowerText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setNRFPowerSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setNRFPowerFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

/**
 *
 * @param dev
 * @param duration
 * @returns {string}
 */
export function setClearHistory(dev, duration) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CLEAR_HISTORY, {duration}, {
            loading: {
                title: i18next.t("IOT.setClearHistory"),
                message: i18next.t("IOT.setClearHistoryText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setClearHistorySuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setClearHistoryFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

/**
 *
 * @param dev
 * @param command
 * @returns {string}
 */
export function setControlBootloader(dev, command) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CONTROL_BOOTLOADER, {command}, command === 3 ? {
            loading: {
                title: i18next.t("IOT.setControlBootloader"),
                message: i18next.t("IOT.setControlBootloaderText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setControlBootloaderSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setControlBootloaderFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null);
    }

}

export function getFramRamStatusCRC(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_FRAM_RAM_STATUS_CRC);
    }
}

export function unsetInseminationParturition(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_INSEMINATION_PARTURITION, {isInseminated: false}, {
            loading: {
                title: i18next.t("IOT.unsetEvent"),
                message: i18next.t("IOT.unsetEventText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.unsetEventSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.unsetEventError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setParturition(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_INSEMINATION_PARTURITION, {isInseminated: true}, {
            loading: {
                title: i18next.t("setEvent"),
                message: i18next.t("IOT.setParturitionText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setParturitionSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setParturitionError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setInsemination(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_INSEMINATION_PARTURITION, {isInseminated: true}, {
            loading: {
                title: i18next.t("setEvent"),
                message: i18next.t("IOT.setInseminationText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setInseminationSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setInseminationError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setFeedingEfficiency(dev, {feedingDoseType = 0, feedingTimeEfficiency = 1000, feedingImpulseEfficiency = 25}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FEEDING_EFFICIENCY, arguments[1], {
            loading: {
                title: i18next.t("IOT.setFeedingEfficiencyTitle"),
                message: i18next.t("IOT.setFeedingEfficiencyText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setFeedingEfficiencySuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setFeedingEfficiencyError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

/**
 *
 * @param dev
 * @param waterDoseType {number} (typ wydajności 0 - czas, 1 - impulsy)
 * @param waterEfficiency {number} (0 - 4 294 967 294, [imp/l] lub [ml/30s] zależnie od waterDoseType)
 * @param waterInitDose {number} (0 - 4 294 967 294, dawka inicjująca [ml])
 * @param waterDose {number} (0 - 4 294 967 294, dawka jednorazowa [ml])
 * @param onSuccess {function}
 * @param onFailure {function}
 * @return {string} (id wyslanej komendy)
 */
export function setWater(dev, {waterDoseType = 0, waterEfficiency = 0, waterInitDose = 0, waterDose = 0}, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_WATER_EFFICIENCY, arguments[1], {
            loading: {
                title: i18next.t('IOT.setWaterTitle'),
                message: i18next.t('IOT.setWaterMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setWaterMsgDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setWaterMsgFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}


export function setAdditionalWater(dev, {amount = 0}, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ADDITIONAL_WATER, {
            waterDoseType: 1,
            waterDose: amount
        }, {
            loading: {
                title: i18next.t('extraWater'),
                message: i18next.t('IOT.setAdditionalWaterMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setAdditionalWaterMsgDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setAdditionalWaterMsgFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}


export function stopWater(dev, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ADDITIONAL_WATER, {
            waterDoseType: 3,
            waterDose: 0
        }, {
            loading: {
                title: i18next.t('IOT.stopWaterTitle'),
                message: i18next.t('IOT.stopWaterMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.stopWaterMsgDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.stopWaterMsgFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

export function startWater(dev, {time = 0}, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ADDITIONAL_WATER, {
            waterDoseType: 0,
            waterDose: time
        }, {
            loading: {
                title: i18next.t('IOT.startWaterTitle', {seconds: Math.floor(time / 1000)}),
                message: i18next.t('IOT.startWaterMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.startWaterMsgDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.startWaterMsgFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

export function startWaterImpulses(dev, {impulses = 0}, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ADDITIONAL_WATER, {
            waterDoseType: 4,
            waterDose: impulses
        }, {
            loading: {
                title: i18next.t('IOT.startWaterImpulsesTitle', {impulses: impulses}),
                message: i18next.t('IOT.startWaterImpulsesMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.startWaterImpulsesMsgDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.startWaterImpulsesMsgFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

/**
 *
 * @param dev
 * @param data - configuration data structure {
 *     PlcmntID: chamber locationID as string.
 *     animals: array of pigs [{
 *         RFID: rfid number,
 *         AnmNo1: animal number
 *         AnmID: animal ID,
 *         feeding:{
 *             curveNr - curve number 0 - 15
 *             offset
 *             percentageCorrection
 *             startTime
 *             punishment
 *         }
 *     }]
 * }
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @return {string}
 */
export function setAnimal(dev, data = [], {onSuccess, onFailure, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        const showSetFeeding = data.length !== data.filter(o => !!o.removed).length;
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ANIMAL_MODIFICATION, data, showNotification ? {
            loading: {
                title: i18next.t(showSetFeeding ? "IOT.setFeedingTitle" : "IOT.setDeletePig"),
                message: i18next.t(showSetFeeding ? "IOT.setFeeding" : "IOT.setDeletePigText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t(showSetFeeding ? "IOT.setFeedingDone" : "IOT.setDeletePigSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t(showSetFeeding ? "IOT.setFeedingFailed" : "IOT.setDeletePigFailure", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

/**
 *
 * @param dev
 * @param data - configuration data structure {
 *     PlcmntID: chamber locationID as string.
 *     animals: array of pigs [{
 *         RFID: rfid number,
 *         AnmNo1: animal number
 *         AnmID: animal ID,
 *         feeding:{
 *             curveNr - curve number 0 - 15
 *             offset
 *             percentageCorrection
 *             startTime
 *             punishment
 *         }
 *     }]
 * }
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @return {string}
 */
export function setAnimalInsemination(dev, data = [], {onSuccess, onFailure, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ANIMAL_MODIFICATION, data, showNotification ? {
            loading: {
                title: i18next.t("tagReaderDrawer.insemination"),
                message: i18next.t("IOT.setInseminationText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setInseminationSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t( "IOT.setInseminationError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

/**
 *
 * @param dev
 * @param data - configuration data structure {
 *     PlcmntID: chamber locationID as string.
 *     animals: array of pigs [{
 *         RFID: rfid number,
 *         AnmNo1: animal number
 *         AnmID: animal ID,
 *         feeding:{
 *             curveNr - curve number 0 - 15
 *             offset
 *             percentageCorrection
 *             startTime
 *             punishment
 *         }
 *     }]
 * }
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @return {string}
 */
export function setAnimalParturition(dev, data = [], {onSuccess, onFailure, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ANIMAL_MODIFICATION, data, showNotification ? {
            loading: {
                title: i18next.t("menu.parturition"),
                message: i18next.t("IOT.setParturitionText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setParturitionSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t( "IOT.setParturitionError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}
