import store from "../../store/store"

export default class AlarmsIOT {

    onMessage(message) {
        console.log(message);
        let data = message.CAnsw;
        store.dispatch({
            type: "SET_ALARM_DATA",
            payload: {
                DevID: message.DeviceID,
                data: data
            }
        })
    }

}