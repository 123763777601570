export function changeBuilding(BgID) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_BUILDING",
            payload: BgID
        })
    }
}

export function changeFarm(farm) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_FARM",
            payload: farm
        })
    }
}

export function changeSector(SID) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_SECTOR",
            payload: SID
        })
    }
}

export function hideShowTreeLeftSideBar(isShow) {
    return function (dispatch) {
        dispatch({
            type: "SHOW_HIDE_NAV_MENU_ELEMENT",
            payload: isShow
        })
    }
}