import {isArray, isString} from "lodash";

export function toggleSelectByID(tabKey, itemId) {
    return function (dispatch) {
        dispatch({
            type: "MAIN_TAB_TOGGLE", payload: {type: "selected", key: tabKey, id: isString(itemId) ? itemId : null}
        });
    }
}

export function setSelectedIDs(tabKey, select = false, ids = []) {
    return function (dispatch) {
        dispatch({
            type: "MAIN_TAB_SET_IDS",
            payload: {type: "selected", key: tabKey, select: !!select, ids: isArray(ids) ? ids : isString(ids) ? [ids] : []}
        });
    }
}

export function toggleExpandByID(tabKey, itemId) {
    return function (dispatch) {
        dispatch({
            type: "MAIN_TAB_TOGGLE", payload: {type: "expanded", key: tabKey, id: isString(itemId) ? itemId : null}
        });
    }
}

export function setExpandedIDs(tabKey, select = false, ids = []) {
    return function (dispatch) {
        dispatch({
            type: "MAIN_TAB_SET_IDS",
            payload: {type: "expanded", key: tabKey, select: !!select, ids: isArray(ids) ? ids : isString(ids) ? [ids] : []}
        });
    }
}

export function clear(tabKey) {
    return function (dispatch) {
        dispatch({
            type: "MAIN_TAB_CLEAR",
            payload: tabKey
        });
    }
}
