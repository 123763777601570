import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n"

export function addNecessaryDevices({FarmID, ClientID, LocalUserID} = {}, showSynchronizePopup = true) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.addNecessaryDevices({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: {}
    });
}

export function addNecessaryDevicesNotificationSuccess(response, {uniqueId = myID()} = {}) {
    const {created} = response;
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.addNecessaryDevicesTitle"),
        message: i18n.t("apiNotifications.addNecessaryDevicesSuccess", {count: created}),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function addNecessaryDevicesNotificationFailure(response, {uniqueId = myID()} = {}) {
    console.log(response);
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.addNecessaryDevicesTitle"),
        message: i18n.t("apiNotifications.addNecessaryDevicesFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}