import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";

/**
 * @typedef InseminationData
 * @type {object}
 * @property AnmID {string}     ID zwierzęcia
 * @property EvTime {number}    Czas wykonania zdarzenia
 * @property boarID {string}    Id knura
 * @property GrID {string}      ID grupy, do której należało zwierzę
 * @property comment {string}   Komentarz
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowej inseminacji
 * @param data {[InseminationData]}         Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createInsemination(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createInsemination({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createToInsemination(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createToInsemination({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createToRepeatInsemination(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createToRepeatInsemination({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createInseminationNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Inseminacja",
        message: "Pomyślnie utworzono inseminacje",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createInseminationNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Inseminacja",
        message: "Nie udało się utworzyć inseminacji",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}

export function createToInseminationNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Do inseminacji",
        message: "Pomyślnie oznaczono lochę do inseminacji",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createToInseminationNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Do inseminacji",
        message: "Nie udało się oznaczyć lochy do inseminacji",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}

export function createToRepeatInseminationNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Do inseminacji",
        message: "Pomyślnie oznaczono lochę do powtórki inseminacji",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createToRepeatInseminationNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Do inseminacji",
        message: "Nie udało się oznaczyć lochy do powtórki inseminacji",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
