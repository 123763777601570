import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";

/**
 * @typedef SeparationData
 * @type {object}
 * @property animals {[{AnmID: string, GrID: string}]}      tablica zwierzat
 * @property amount {number}                                ilość prosiąt
 * @property EvTime {number}                                Czas wykonania zdarzenia
 * @property Weight {number}                                ID grupy, do której należało zwierzę
 * @property Comment {string}                               Komentarz
 * @property createNewGroup {boolean}                       Czy ma stworzyc nowa grupe
 * @property groupNumber {string}                           Numer nowej grupy
 * @property createNewAnimal {boolean}                      Czy ma stworzyc nowe zwierze
 * @property newAnimalNumber {string}                       Numer nowego zwierzecia
 * @property separateTo {string}                            Lokalizacja gdzie odsadzono
 * @property addToAnimal {string}                           Zwierze do ktorego dodac
 * @property addToPlcmnt {string}                           Lokalizacja, do ktorej dodac (jezeli zwierze ma tablice PlcmntID)
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego odsadzenia do mamki
 * @param data {SeparationData}    Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createSeparation(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createSeparation({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createSeparationNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Odsadzenie",
        message: "Pomyślnie utworzono odsadzenie",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createSeparationNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Odsadzenie",
        message: "Nie udało się utworzyć odsadzenia",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}

export function createSeparationByAnimal(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createSeparationByAnimal({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}