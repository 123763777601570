import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n";

export function setWaterEfficiency({AllDevices = false, DevIDs = [], SiloIDs = [], PlcmntIDs = [], Efficiency = 1000, Type = 0}, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.setWaterEfficiency({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: {
            AllDevices,
            DevIDs,
            SiloIDs,
            PlcmntIDs,
            Efficiency: Math.round(Efficiency),
            Type: Type || 0
        }
    })
}

export function setWaterEfficiencyNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.setWaterEfficiencyTitle"),
        message: i18n.t("apiNotifications.setWaterEfficiencySuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function setWaterEfficiencyNotificationFailure(response, {uniqueId = myID()} = {}) {
    console.log(response);
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.setWaterEfficiencyTitle"),
        message: i18n.t("apiNotifications.setWaterEfficiencyFailureGeneral"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}
