import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n"

export function assignDeviceToFarm(DevType, SN, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.assignDeviceToFarm({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {
            DevType,
            SN
        }
    })
}

export function assignDeviceToFarmNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.assignDeviceTitle"),
        message: i18n.t("apiNotifications.assignDeviceSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function assignDeviceToFarmNotificationFailure(response, {uniqueId = myID()} = {}) {
    console.log(response);
    if (response && response.message) {
        if (response.message.includes("Device with SN")) {
            store.dispatch(addNotification({
                id: uniqueId,
                title: i18n.t("apiNotifications.assignDeviceTitle"),
                message: i18n.t("apiNotifications.assignDeviceFailureNotFound"),
                status: 'error',
                dismissible: true,
                dismissAfter: 15000
            }))
            return;
        }
        if (response.message === "No SN or DevType provided") {
            store.dispatch(addNotification({
                id: uniqueId,
                title: i18n.t("apiNotifications.assignDeviceTitle"),
                message: i18n.t("apiNotifications.assignDeviceFailureNotProvided"),
                status: 'error',
                dismissible: true,
                dismissAfter: 15000
            }))
            return;
        }
    }
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.assignDeviceTitle"),
        message: i18n.t("apiNotifications.assignDeviceFailureNotGeneral"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}