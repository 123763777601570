import {invokeApig} from "../libs/awsLib";
import moment from "moment"
import Paths from "../api/paths";
import _ from "lodash";

/**
 *
 * @param device
 * @param params
 * @param params.AggDataTime {number} czas dla pojedyneczego rekodru
 * @param params.AggDataTimeFrom {number} zakres od
 * @param params.AggDataTimeTo {number} zakres do
 * @param params.index {number|null|undefined} index dla urzadzenia
 * @param onSuccess
 * @param onFailure
 * @return {function(...[*]=)}
 */
export function getAggregatedData(device, params = {}, onSuccess = () => {
}, onFailure = () => {
}) {
    return function (dispatch) {
        let id = device.DevID;
        if(!Object.keys(params).filter(o=>o.startsWith("AggDataTime")).length){
            params.AggDataTime = moment().startOf("day").toDate().getTime();
        }
        if (_.isFinite(params.index)) {
            id += `_${params.index}`;
            delete params.index;
        }
        dispatch({
            type: "FETCH_AGGREGATED_DATA",
            payload: invokeApig({
                ...Paths.getAggregatedData(device.DevID, {farmID: device.FarmID}),
                queryParams: {
                    table: "AggData",
                    ...params,
                    DevIDAggData: id
                }
            }),
            meta: {
                DevID: id
            }
        }).then(res => {
            onSuccess(res);
        }).catch(e => {
            console.error(e);
            onFailure(e);
        });
        dispatch({
            type: "FETCH_AGGREGATED_DATA",
            payload: id
        })
    }
}