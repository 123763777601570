import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";

/**
 * @typedef ActiveNipplesData
 * @type {object}
 * @property AnmID {string}         ID zwierzecia
 * @property EvTime {number}        czas wykonania zdarzeniia (możliwe, że przejdzie do tablicy animals)
 * @property Comment {string}       komentarz
 * @property GrID {string}          id grupy
 * @property Nipples {number}       ilość aktywnych sutków
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego eventu aktywne sutki
 * @param data {[ActiveNipplesData]}        Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createActiveNipples(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createActiveNipples({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createActiveNipplesNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Aktywne sutki",
        message: "Pomyślnie zapisano aktywne sutki",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createActiveNipplesNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Aktywne sutki",
        message: "Nie udało się zapisać aktywnych sutków",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}