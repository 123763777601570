import {invokeApig} from '../libs/awsLib';
import {addNotification} from "reapop";
import config from "../conf/config";
import {logoutUser} from "./userActions";
import settingsDB from "../database/settingsDB";
import {settings} from "../database/lokiDB";
import {SettingTypes} from "../constans/settingTypes";
import {redirect} from "./historyActions";
import Paths from "../api/paths";
import i18next from "i18next";

export function registerSNSEndpoint(platformApplicationArn, token, customUserData, clientId, localId, FarmData, roles) {
    return async (dispatch) => {

        async function onSuccess(success) {
            dispatch({
                type: "REGISTER_SNS_ENDPOINT_FULLFILLED",
                payload: success.EndpointArn
            });
            let clientAddition = "-" + clientId;
            let localAddition = clientAddition + "-" + localId;
            // let bigBrotherAlarms = "ALARMS_ALL";
            const serviceRole = roles.find(r => r.Role === "_SERVICE");
            const allowedFarms = serviceRole ? serviceRole.LocalRights.filter(lr => lr.ClientID === clientId).map(obj => obj.FarmID) : [];
            let userFarms = FarmData.map(farm => {
                return farm.FarmID
            });
            if (serviceRole) {
                userFarms = userFarms.filter(f => allowedFarms.includes(f));
            }
            let topics = [
                config.sns.mainTopic,
                config.sns.mainTopic + clientAddition,
                config.sns.mainTopic + localAddition,
            ];
            for (let farm of userFarms) {
                let farmAddition = clientAddition + "-" + farm;
                topics.push(config.sns.mainTopic + farmAddition);
            }
            dispatch(subscribeSNSTopic(success.EndpointArn, topics));
            // dispatch(subscribeSNSTopic(success.EndpointArn, bigBrotherAlarms));//narazie niepotrzebne, na ten moment push n otyfikacje leca po kanale Fetura+"-"+ClientID, wystarczy rejestracja do 3 kanalow jak przy zalozeniu tj. fetura, fetura-[ClientID], fetura-[ClientID]-[localUserID]
            // let endp = {SetType:"ARN", SetID:"EndpointArn", ClientID: clientId, EndpointArn:success.EndpointArn, DtaModTime : new Date().getTime()};

            let endp = {
                SetID: "EndpointArn",
                ClientID: clientId,
                FarmID: "EndpointArn",
                SetData: {
                    EndpointArn: success.EndpointArn,
                    DeviceName: customUserData,
                    Name: "EndpointArn"
                },
                SetType: SettingTypes.ENDPOINT_ARN,
                DtaModTime: new Date().getTime()
            };
            let arr = [];
            arr.push(endp);
            await settingsDB.insertIntoSettings(arr);
            return success;
        }

        function onError(error) {
            dispatch({
                type: "REGISTER_SNS_ENDPOINT_FAILURE",
                payload: error
            });
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.registerEndpointErr"),
                status: 'error',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(msg));
            return error;
        }

        if (token) {
            try {
                const success = await invokeApig({
                    ...Paths.registerSNSEndpoint(),
                    body: {
                        PlatformApplicationArn: platformApplicationArn,
                        Token: token,
                        CustomUserData: customUserData
                    }
                });
                // console.log("EndpointArn: ", success.EndpointArn);
                return onSuccess(success);
            } catch (e) {
                console.error(e);
                return onError(e);
            }
        } else {
            console.error("nie zarejestrowano endpointa bo token pusty")
        }
    }
}

export function subscribeSNSTopic(endpoint, topics) {
    return (dispatch) => {
        dispatch({
            type: "SUBSCRIBE_SNS_TOPIC", payload: invokeApig({
                ...Paths.subscribeSNSTopic(),
                body: {
                    "Protocol": "Application",
                    "TopicArn": topics,
                    "Endpoint": endpoint
                }
            })
        }).catch(err => {
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.subscribeTopicErr"),
                status: 'error',
                dismissible: true
            };
            dispatch(addNotification(msg));
            console.error("subscribeSNSTopic: ", err)
        })
    }
}

export function unsubscribeSNSTopic(endpoint) {
    return async (dispatch) => {

        async function onSuccess(success) {
            dispatch({
                type: "UNSUBSCRIBE_SNS_TOPIC_FULLFILLED",
                payload: success
            });

            let endp = settings.find({SetID: "EndpointArn"});
            if (endp.length > 0) {
                await settingsDB.deleteFromSettings(endp);
            }
            dispatch(redirect("/"));
            dispatch(logoutUser());
            return success;
        }

        function onError(error) {
            dispatch({
                type: "UNSUBSCRIBE_SNS_TOPIC_FAILURE",
                payload: error
            });
            let msg = {
                title: i18next.t("error"),
                message: i18next.t("popNotifications.subscribeEndpointRemoveErr"), //+ error.message,
                status: 'error',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(msg));
            return error;
        }

        try {
            const success = await invokeApig({
                ...Paths.unsubscribeSNSTopic(),
                body: {
                    "EndpointArn": endpoint
                }
            });
            return onSuccess(success);
        } catch (error) {
            return onError(error);
        }
    }
}