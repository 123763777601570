import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";

export function createSell(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createSell({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createSellNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Sprzedaż",
        message: "Pomyślnie zapisano sprzedaż",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createSellNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Sprzedaż",
        message: "Nie udało się zapisać sprzedaży",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}