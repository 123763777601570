import NewIOT from "../NewIOT";
import {convertHexStringToHex} from "../../utils/CRCUtils";
import {TerminalCommandTypes} from "../../constans/mqttMessages";
import {getIOTAttributes} from "../../utils/IOTUtils";

export function sendMessageRaw(user, device, data, addCRC = false, intf = null) {
    let hexData = convertHexStringToHex(data);
    let params = {};
    params.frame = hexData;
    if (intf !== null) {
        params.intf = intf;
    }
    NewIOT.createAndSendMessageObject(user.ClientID, device.GatewayID, user.LocalUserID, device.DevID, addCRC ? TerminalCommandTypes.TEST_RAW_DATA_CRC : TerminalCommandTypes.TEST_RAW_DATA, params);
}


export function sendMessageAscii(user, device, data, intf = null) {
    let params = {};
    params.frame = data.split('').map((c) => {
        return c.charCodeAt(0)
    });
    if (intf !== null) {
        params.intf = intf;
    }
    NewIOT.createAndSendMessageObject(user.ClientID, device.GatewayID, user.LocalUserID, device.DevID, TerminalCommandTypes.TEST_RAW_DATA_ASCII, params);
}

export function sendCommandWithType(user, device, commandType, params = {}) {
    NewIOT.createAndSendMessageObject(user.ClientID, device.GatewayID, user.LocalUserID, device.DevID, commandType, params);
}


export function sendCommand(dev, command, params={}) {
    const {ClientID, LocalUserID, GatewayID, isValid, DevID} = getIOTAttributes(dev);
    if(isValid){
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, command, params)
    }

}