import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";

/**
 * @typedef SelectionData
 * @type {object}
 * @property AnmID {string}         ID zwierzęcia
 * @property EvTime {number}        czas wykonania zdarzenia
 * @property GrID {string}          id grupy
 * @property Comment {string}       komentarz
 * @property Reason {string}        powód upadku
 * @property PlcmntID {string}      miejsce
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowej selekcji
 * @param data {[SelectionData]}            Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createSelection(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createSelection({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createSelectionNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Selekcja",
        message: "Pomyślnie zapisano slekecje",
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createSelectionNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: "Selekcja",
        message: "Nie udało się zapisać selekcji",
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}