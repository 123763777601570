import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export function getLogObjectByID({FarmID, ClientID, LocalUserID, ObjID, TableName} = {}) {
    console.log("getLogObjectByID")
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.getLogObjectByID({
            farmID: _FarmID,
            clientID: _ClientID,
            localUserID: _LocalUserID,
        }),
        queryParams: {
            ObjectID: ObjID,
            TableName: TableName
        }
    })
}
