import {getTasks} from "../api/task/getTasks";
import moment from "moment";

export function setFilters(filters) {
    return function (dispatch) {
        dispatch({
            type: "SET_FILTERS",
            payload: filters
        })
    }
}

export function getTasksAPI(DtaPlanned) {
    return function (dispatch) {
        dispatch({
            type: "GET_TASKS",
            payload: getTasks(DtaPlanned)
        })
    }
}

export function changeDate(date) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_DATE",
            payload: date
        });
        dispatch(getTasksAPI(+moment.utc(date)));
    }
}

export function setSelectedTask(task) {
    return function (dispatch) {
        dispatch({
            type: "SET_SELECTED_TASK",
            payload: task
        })
    }
}