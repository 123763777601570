import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import {isArray, isNumber, isString} from "lodash";

export function listLogsDynamoDB({lastEvaluatedKey = null, ObjID = null, DtaCrtTime = null, UserID = null, Codes = null} = {}) {
    return function (dispatch) {
        const extraParams = {};
        isNumber(DtaCrtTime) && (extraParams.DtaCrtTime = DtaCrtTime);
        isString(ObjID) && (extraParams.ObjID = ObjID);
        isString(UserID) && (extraParams.UserID = UserID);
        isArray(Codes) && Codes.length && (extraParams.Codes = Codes);
        if (lastEvaluatedKey) {
            extraParams.DtaCrtTime = lastEvaluatedKey.DtaCrtTime;
            extraParams.ESKLogID = lastEvaluatedKey.LogID;
        }
        dispatch({
            type: "LIST_LOGS", payload: invokeApig({
                ...Paths.listLogs(),
                queryParams: {limit: 100, ...extraParams}
            }),
            meta: {
                objectId: ObjID,
                lastEvaluatedKey: lastEvaluatedKey,
                userId: UserID,
                startFrom: DtaCrtTime,
                searchCode: Codes || [],
            }
        })
    }
}

export function setSearchText(text = "") {
    return function (dispatch) {
        dispatch({
            type: "LOGS_CHANGE_SEARCH",
            payload: text
        })
    }
}

export function setObjectId(objectId = null) {
    return function (dispatch) {
        dispatch({
            type: "LOGS_CHANGE_OBJECT_ID",
            payload: objectId
        })
    }
}

export function setStartFrom(timestamp = null) {
    return function (dispatch) {
        dispatch({
            type: "LOGS_CHANGE_START_FROM",
            payload: timestamp
        })
    }
}

export function setUserID(userId = null) {
    return function (dispatch) {
        dispatch({
            type: "LOGS_CHANGE_USER_ID",
            payload: userId
        })
    }
}

export function setCode(code = null) {
    return function (dispatch) {
        dispatch({
            type: "LOGS_CHANGE_CODE",
            payload: code
        })
    }
}

export function removeCode(code) {
    return function (dispatch) {
        dispatch({
            type: "LOGS_REMOVE_CODE",
            payload: code
        })
    }
}

export function clearLogs(forceClear=true) {
    return function (dispatch) {
        dispatch({
            type: "CLEAR_LOGS",
            payload: !!forceClear
        })
    }
}
