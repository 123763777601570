import NewIOT from "../NewIOT";
import {getIOTAttributes} from "../../utils/IOTUtils";
import i18next from "i18next";
import {ClimateCommandTypes} from "../../constans/mqttMessages";
import {isFunction} from "lodash";

/**
 * funkcja do ustawienia pasma wentylacji
 * @param dev
 * @param bandwidth
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setBandwidth(dev, {bandwidth} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_BANDWIDTH, {
            bandwidth
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setBandwidthTitle'),
                message: i18next.t('IOT.setBandwidth', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setBandwidthDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setBandwidthFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/**
 * ustawienie trybu nieczynnego
 * @param dev
 * @param data
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setClimateWorkTypeInactive(dev, data = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_WORK_TYPE_INACTIVE, null, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateWorkTypeInactiveTitle'),
                message: i18next.t('IOT.setClimateWorkTypeInactive', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateWorkTypeInactiveDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateWorkTypeInactiveFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

export function setClimateWorkTypeManual(dev, {manual = 90} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_WORK_TYPE_MANUAL, {manual}, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateWorkTypeManualTitle'),
                message: i18next.t('IOT.setClimateWorkTypeManual', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateWorkTypeManualDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateWorkTypeManualFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

export function setClimateWorkTypeAutomatic(dev, {
    maximumVentilation = 100,
    minimumVentilation = 10,
    requestedTemperature = 20
} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_WORK_TYPE_AUTOMATIC,
            {
                maximumVentilation,
                minimumVentilation,
                requestedTemperature
            }, showNotification ? {
                loading: {
                    title: i18next.t('IOT.setClimateWorkTypeAutomaticTitle'),
                    message: i18next.t('IOT.setClimateWorkTypeAutomatic', {name: Name}),
                    status: 'loading',
                    dismissible: false,
                    dismissAfter: 0
                },
                success: {
                    message: i18next.t('IOT.setClimateWorkTypeAutomaticDone', {name: Name}),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "success"
                },
                error: {
                    message: i18next.t('IOT.setClimateWorkTypeAutomaticFailed', {
                        name: Name
                    }),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "error"
                },
                DevID: DevID
            } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

export function setClimateWorkTypeCurve(dev, {
    currentCurveDay = 1,
    minimumVentilationDeviation = 0,
    requestedTemperatureDeviation = 0,
    stages = []
} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_WORK_TYPE_CURVE,
            {
                currentCurveDay,
                minimumVentilationDeviation,
                requestedTemperatureDeviation,
                stages
            }, showNotification ? {
                loading: {
                    title: i18next.t('IOT.setClimateWorkTypeCurveTitle'),
                    message: i18next.t('IOT.setClimateWorkTypeCurve', {name: Name}),
                    status: 'loading',
                    dismissible: false,
                    dismissAfter: 0
                },
                success: {
                    message: i18next.t('IOT.setClimateWorkTypeCurveDone', {name: Name}),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "success"
                },
                error: {
                    message: i18next.t('IOT.setClimateWorkTypeCurveFailed', {
                        name: Name
                    }),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "error"
                },
                DevID: DevID
            } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/**
 * funkcja do ustawiania alarmów
 * @param dev
 * @param minimumTemperatureAlarmDeviation
 * @param maximumTemperatureAlarmDeviation
 * @param absoluteAlarm
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setClimateAlarms(dev, {
    minimumTemperatureAlarmDeviation = 4,
    maximumTemperatureAlarmDeviation = 10,
    absoluteAlarm = 35
} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_ALARMS, {
            minimumTemperatureAlarmDeviation,
            maximumTemperatureAlarmDeviation,
            absoluteAlarm
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateAlarmsTitle'),
                message: i18next.t('IOT.setClimateAlarms', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateAlarmsDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateAlarmsFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/**
 * funkcja do zapisywania ustawien chlodzenia
 * @param dev
 * @param coolingBreakTime
 * @param coolingEnableTemperature
 * @param coolingMaximumHumidity
 * @param coolingMaximumPressure
 * @param coolingMinimumPressure
 * @param coolingWorkTime
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setClimateCooling(dev, {
    coolingBreakTime = 120000,
    coolingEnableTemperature = 1.6,
    coolingMaximumHumidity = 90,
    coolingMaximumPressure = 60,
    coolingMinimumPressure = 20,
    coolingWorkTime = 120000
} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_COOLING, {
            coolingBreakTime,
            coolingEnableTemperature,
            coolingMaximumHumidity,
            coolingMaximumPressure,
            coolingMinimumPressure,
            coolingWorkTime
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateCoolingTitle'),
                message: i18next.t('IOT.setClimateCooling', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateCoolingDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateCoolingFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/**
 * funkcja do ustawiania napieciach na klapach
 * @param dev
 * @param flapsVoltage0
 * @param flapsVoltage2
 * @param flapsVoltage5
 * @param flapsVoltage10
 * @param flapsVoltage15
 * @param flapsVoltage20
 * @param flapsVoltage25
 * @param flapsVoltage35
 * @param flapsVoltage45
 * @param flapsVoltage70
 * @param flapsVoltage100
 * @param flapsVoltage100M1
 * @param flapsVoltage100M2
 * @param flapsVoltage100M3
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setClimateFlaps(dev, {
    flapsVoltage0 = 4000,
    flapsVoltage2 = 4000,
    flapsVoltage5 = 4000,
    flapsVoltage10 = 4000,
    flapsVoltage15 = 4000,
    flapsVoltage20 = 4000,
    flapsVoltage25 = 4000,
    flapsVoltage35 = 4000,
    flapsVoltage45 = 4000,
    flapsVoltage70 = 4000,
    flapsVoltage100 = 4000,
    flapsVoltage100M1 = 4000,
    flapsVoltage100M2 = 4000,
    flapsVoltage100M3 = 4000
} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_FLAPS, {
            flapsVoltage0,
            flapsVoltage5,
            flapsVoltage2,
            flapsVoltage10,
            flapsVoltage15,
            flapsVoltage20,
            flapsVoltage25,
            flapsVoltage35,
            flapsVoltage45,
            flapsVoltage70,
            flapsVoltage100,
            flapsVoltage100M1,
            flapsVoltage100M2,
            flapsVoltage100M3
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateFlapsTitle'),
                message: i18next.t('IOT.setClimateFlaps', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateFlapsDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateFlapsFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/**
 * funkcja do ustawienia nagrzewnic
 * @param dev
 * @param heatingBreakTime
 * @param heatingDeviation
 * @param heatingWorkTime
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setClimateHeating(dev, {
    heatingBreakTime = 120000,
    heatingDeviation = 1.6,
    heatingWorkTime = 120000
} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_HEATING, {
            heatingBreakTime,
            heatingWorkTime,
            heatingDeviation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateHeatingTitle'),
                message: i18next.t('IOT.setClimateHeating', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateHeatingDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateHeatingFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/**
 * funkcja do ustawiania mat
 * @param dev
 * @param mats
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setMatMenu(dev, {
    mats = new Array(8).fill({
        day: 4,
        deviation: 0
    })
} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_MAT_MENU, {
            mats
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setMatMenuTitle'),
                message: i18next.t('IOT.setMatMenu', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setMatMenuDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setMatMenuFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

export function setClimateSingleMat(dev, {number = 1, day = 0, deviation = 0} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_SINGLE_MAT, {
            number,
            day,
            deviation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateSingleMatTitle'),
                message: i18next.t('IOT.setClimateSingleMat', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateSingleMatDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateSingleMatFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/**
 * ustawianie sensorow
 * @param dev
 * @param temperatureSensorsCalibration
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setClimateSensorsCalibration(dev, {
    temperatureSensorsCalibration = new Array(8).fill({
        day: 4,
        deviation: 0
    })
} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_SENSORS_CALIBRATION, {
            temperatureSensorsCalibration
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateSensorsCalibrationTitle'),
                message: i18next.t('IOT.setClimateSensorsCalibration', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateSensorsCalibrationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateSensorsCalibrationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/**
 * funkcja do ustawiania przepustnic
 * @param dev
 * @param throttleVoltageMax
 * @param throttleVoltageMin
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setClimateThrottle(dev, {throttleVoltageMax = 10 * 1000, throttleVoltageMin = 0} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_THROTTLE, {
            throttleVoltageMax,
            throttleVoltageMin
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateThrottleTitle'),
                message: i18next.t('IOT.setClimateThrottle', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateThrottleDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateThrottleFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/**
 * funkcja do ustawienia wentylacji
 * @param dev
 * @param bandwidth
 * @param chimneyVentilatorsCount
 * @param mastersCount
 * @param minimumRotation
 * @param objectDynamics
 * @param ventilationCycleTime
 * @param ventilationDelay
 * @param ventilationIntensity
 * @param onSuccess
 * @param onFailure
 * @param onSend
 * @param showNotification
 * @return {string}
 */
export function setClimateVentilation(dev, {
    bandwidth = 4,
    chimneyVentilatorsCount = 2,
    mastersCount = 3,
    minimumRotation = 15,
    objectDynamics = 30000,
    ventilationCycleTime = 60000,
    ventilationDelay = 60000,
    ventilationIntensity = 10
} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CLIMATE_VENTILATION, {
            bandwidth,
            chimneyVentilatorsCount,
            mastersCount,
            minimumRotation,
            objectDynamics,
            ventilationCycleTime,
            ventilationDelay,
            ventilationIntensity
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setClimateVentilationTitle'),
                message: i18next.t('IOT.setClimateVentilation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setClimateVentilationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setClimateVentilationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

/*
         KOMENDY PONIZEJ SA ZROBIONE DLA TESTOW I SA NIEUZYWANE W NORMALNY SPOSOB NA FRONCIE
 */

export function setRequestedTemperature(dev, {requestedTemperature} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_REQUESTED_TEMPERATURE, {
            requestedTemperature
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setRequestedTemperatureTitle'),
                message: i18next.t('IOT.setRequestedTemperature', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setRequestedTemperatureDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setRequestedTemperatureFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setRequestedTemperatureDeviation(dev, {requestedTemperatureDeviation} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_REQUESTED_TEMPERATURE_DEVIATION, {
            requestedTemperatureDeviation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setRequestedTemperatureDeviationTitle'),
                message: i18next.t('IOT.setRequestedTemperatureDeviation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setRequestedTemperatureDeviationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setRequestedTemperatureDeviationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setHeatingDeviation(dev, {heatingDeviation} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_HEATING_DEVIATION, {
            heatingDeviation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setHeatingDeviationTitle'),
                message: i18next.t('IOT.setHeatingDeviation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setHeatingDeviationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setHeatingDeviationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setMinimumTemperatureAlarmDeviation(dev, {minimumTemperatureAlarmDeviation} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_MINIMUM_TEMPERATURE_ALARM_DEVIATION, {
            minimumTemperatureAlarmDeviation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setMinimumTemperatureAlarmDeviationTitle'),
                message: i18next.t('IOT.setMinimumTemperatureAlarmDeviation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setMinimumTemperatureAlarmDeviationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setMinimumTemperatureAlarmDeviationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setMaximumTemperatureAlarmDeviation(dev, {maximumTemperatureAlarmDeviation} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_MAXIMUM_TEMPERATURE_ALARM_DEVIATION, {
            maximumTemperatureAlarmDeviation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setMaximumTemperatureAlarmDeviationTitle'),
                message: i18next.t('IOT.setMaximumTemperatureAlarmDeviation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setMaximumTemperatureAlarmDeviationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setMaximumTemperatureAlarmDeviationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setAbsoluteAlarm(dev, {absoluteAlarm} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_ABSOLUTE_ALARM, {
            absoluteAlarm
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setAbsoluteAlarmTitle'),
                message: i18next.t('IOT.setAbsoluteAlarm', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setAbsoluteAlarmDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setAbsoluteAlarmFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setMinimumVentilation(dev, {minimumVentilation} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_MINIMUM_VENTILATION, {
            minimumVentilation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setMinimumVentilationTitle'),
                message: i18next.t('IOT.setMinimumVentilation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setMinimumVentilationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setMinimumVentilationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setMaximumVentilation(dev, {maximumVentilation} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_MAXIMUM_VENTILATION, {
            maximumVentilation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setMaximumVentilationTitle'),
                message: i18next.t('IOT.setMaximumVentilation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setMaximumVentilationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setMaximumVentilationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setMinimumVentilationDeviation(dev, {minimumVentilationDeviation} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_MINIMUM_VENTILATION_DEVIATION, {
            minimumVentilationDeviation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setMinimumVentilationDeviationTitle'),
                message: i18next.t('IOT.setMinimumVentilationDeviation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setMinimumVentilationDeviationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setMinimumVentilationDeviationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setCoolingEnableTemperature(dev, {coolingEnableTemperature} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_COOLING_ENABLE_TEMPERATURE, {
            coolingEnableTemperature
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setCoolingEnableTemperatureTitle'),
                message: i18next.t('IOT.setCoolingEnableTemperature', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setCoolingEnableTemperatureDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setCoolingEnableTemperatureFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setCoolingWorkTime(dev, {coolingWorkTime} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_COOLING_WORK_TIME, {
            coolingWorkTime
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setCoolingWorkTimeTitle'),
                message: i18next.t('IOT.setCoolingWorkTime', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setCoolingWorkTimeDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setCoolingWorkTimeFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setCoolingBreakTime(dev, {coolingBreakTime} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_COOLING_BREAK_TIME, {
            coolingBreakTime
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setCoolingBreakTimeTitle'),
                message: i18next.t('IOT.setCoolingBreakTime', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setCoolingBreakTimeDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setCoolingBreakTimeFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setCoolingMaximumHumidity(dev, {coolingMaximumHumidity} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_COOLING_MAXIMUM_HUMIDITY, {
            coolingMaximumHumidity
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setCoolingMaximumHumidityTitle'),
                message: i18next.t('IOT.setCoolingMaximumHumidity', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setCoolingMaximumHumidityDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setCoolingMaximumHumidityFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setCoolingMinimumPressure(dev, {coolingMinimumPressure} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_COOLING_MINIMUM_PRESSURE, {
            coolingMinimumPressure
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setCoolingMinimumPressureTitle'),
                message: i18next.t('IOT.setCoolingMinimumPressure', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setCoolingMinimumPressureDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setCoolingMinimumPressureFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setCoolingMaximumPressure(dev, {coolingMaximumPressure} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_COOLING_MAXIMUM_PRESSURE, {
            coolingMaximumPressure
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setCoolingMaximumPressureTitle'),
                message: i18next.t('IOT.setCoolingMaximumPressure', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setCoolingMaximumPressureDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setCoolingMaximumPressureFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setManualVentilation(dev, {manualVentilation} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_MANUAL_VENTILATION, {
            manualVentilation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setManualVentilationTitle'),
                message: i18next.t('IOT.setManualVentilation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setManualVentilationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setManualVentilationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setDeviceAddress(dev, {deviceAddress} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_DEVICE_ADDRESS, {
            deviceAddress
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setDeviceAddressTitle'),
                message: i18next.t('IOT.setDeviceAddress', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setDeviceAddressDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setDeviceAddressFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setObjectDynamics(dev, {objectDynamics} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_OBJECT_DYNAMICS, {
            objectDynamics
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setObjectDynamicsTitle'),
                message: i18next.t('IOT.setObjectDynamics', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setObjectDynamicsDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setObjectDynamicsFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setChimneyVentilatorsCount(dev, {chimneyVentilatorsCount} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_CHIMNEY_VENTILATORS_COUNT, {
            chimneyVentilatorsCount
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setChimneyVentilatorsCountTitle'),
                message: i18next.t('IOT.setChimneyVentilatorsCount', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setChimneyVentilatorsCountDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setChimneyVentilatorsCountFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setMastersCount(dev, {mastersCount} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_MASTERS_COUNT, {
            mastersCount
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setMastersCountTitle'),
                message: i18next.t('IOT.setMastersCount', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setMastersCountDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setMastersCountFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setThrottleVoltageMin(dev, {throttleVoltageMin} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_THROTTLE_VOLTAGE_MIN, {
            throttleVoltageMin
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setThrottleVoltageMinTitle'),
                message: i18next.t('IOT.setThrottleVoltageMin', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setThrottleVoltageMinDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setThrottleVoltageMinFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setThrottleVoltageMax(dev, {throttleVoltageMax} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_THROTTLE_VOLTAGE_MAX, {
            throttleVoltageMax
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setThrottleVoltageMaxTitle'),
                message: i18next.t('IOT.setThrottleVoltageMax', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setThrottleVoltageMaxDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setThrottleVoltageMaxFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setFlapsVoltageMin(dev, {flapsVoltageMin} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_FLAPS_VOLTAGE_MIN, {
            flapsVoltageMin
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setFlapsVoltageMinTitle'),
                message: i18next.t('IOT.setFlapsVoltageMin', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setFlapsVoltageMinDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setFlapsVoltageMinFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setFlapsVoltageMax(dev, {flapsVoltageMax} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_FLAPS_VOLTAGE_MAX, {
            flapsVoltageMax
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setFlapsVoltageMaxTitle'),
                message: i18next.t('IOT.setFlapsVoltageMax', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setFlapsVoltageMaxDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setFlapsVoltageMaxFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setHeatingBreakTime(dev, {heatingBreakTime} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_HEATING_BREAK_TIME, {
            heatingBreakTime
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setHeatingBreakTimeTitle'),
                message: i18next.t('IOT.setHeatingBreakTime', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setHeatingBreakTimeDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setHeatingBreakTimeFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setHeatingWorkTime(dev, {heatingWorkTime} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_HEATING_WORK_TIME, {
            heatingWorkTime
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setHeatingWorkTimeTitle'),
                message: i18next.t('IOT.setHeatingWorkTime', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setHeatingWorkTimeDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setHeatingWorkTimeFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setVentilationDelay(dev, {ventilationDelay} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_VENTILATION_DELAY, {
            ventilationDelay
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setVentilationDelayTitle'),
                message: i18next.t('IOT.setVentilationDelay', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setVentilationDelayDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setVentilationDelayFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setMinimumRotation(dev, {minimumRotation} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_MINIMUM_ROTATION, {
            minimumRotation
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setMinimumRotationTitle'),
                message: i18next.t('IOT.setMinimumRotation', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setMinimumRotationDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setMinimumRotationFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setTemperatureSensorsCalibration1(dev, {temperatureSensorsCalibration} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_TEMPERATURE_SENSORS_CALIBRATION_1, {
            temperatureSensorsCalibration
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setTemperatureSensorsCalibration1Title'),
                message: i18next.t('IOT.setTemperatureSensorsCalibration1', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration1Done', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration1Failed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setTemperatureSensorsCalibration2(dev, {temperatureSensorsCalibration} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_TEMPERATURE_SENSORS_CALIBRATION_2, {
            temperatureSensorsCalibration
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setTemperatureSensorsCalibration2Title'),
                message: i18next.t('IOT.setTemperatureSensorsCalibration2', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration2Done', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration2Failed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setTemperatureSensorsCalibration3(dev, {temperatureSensorsCalibration} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_TEMPERATURE_SENSORS_CALIBRATION_3, {
            temperatureSensorsCalibration
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setTemperatureSensorsCalibration3Title'),
                message: i18next.t('IOT.setTemperatureSensorsCalibration3', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration3Done', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration3Failed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setTemperatureSensorsCalibration4(dev, {temperatureSensorsCalibration} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_TEMPERATURE_SENSORS_CALIBRATION_4, {
            temperatureSensorsCalibration
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setTemperatureSensorsCalibration4Title'),
                message: i18next.t('IOT.setTemperatureSensorsCalibration4', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration4Done', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration4Failed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setTemperatureSensorsCalibration5(dev, {temperatureSensorsCalibration} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_TEMPERATURE_SENSORS_CALIBRATION_5, {
            temperatureSensorsCalibration
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setTemperatureSensorsCalibration5Title'),
                message: i18next.t('IOT.setTemperatureSensorsCalibration5', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration5Done', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setTemperatureSensorsCalibration5Failed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setVentilationCycleTime(dev, {ventilationCycleTime} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_VENTILATION_CYCLE_TIME, {
            ventilationCycleTime
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setVentilationCycleTimeTitle'),
                message: i18next.t('IOT.setVentilationCycleTime', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setVentilationCycleTimeDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setVentilationCycleTimeFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}


export function setVentilationIntensity(dev, {ventilationIntensity} = {}, {onSuccess, onFailure, onSend, showNotification = true} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateCommandTypes.SET_VENTILATION_INTENSITY, {
            ventilationIntensity
        }, showNotification ? {
            loading: {
                title: i18next.t('IOT.setVentilationIntensityTitle'),
                message: i18next.t('IOT.setVentilationIntensity', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setVentilationIntensityDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setVentilationIntensityFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}
