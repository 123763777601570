import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n"
import devicesDB from "../../database/devicesDB";
import {synchronizeDevicesOnBackend} from "../../IOT/device-functions/GatewayFunctions";

export function updateDevices(devices, {FarmID, ClientID, LocalUserID} = {}, showSynchronizePopup = true) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.updateDevices({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: devices,
        queryParams: {table: "Devices"}
    }).then(res => {
        let devs = Array.isArray(devices) ? devices : [devices];
        let map = new Map();
        for (let device of devs) {
            try {
                let gw = devicesDB.getGateway(device);
                let array = map.get(gw.DevID) || [];
                array.push(device);
                map.set(gw.DevID, array);
            } catch (e) {
                console.error(e);
            }
        }
        for (let [gatewayID, values] of map.entries()) {
            synchronizeDevicesOnBackend(gatewayID, values.map(device => device.DevID), showSynchronizePopup);
        }
    })
}

export function updateDevicesNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.updateDevicesTitle"),
        message: i18n.t("apiNotifications.updateDevicesSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function updateDevicesNotificationFailure(response, {uniqueId = myID()} = {}) {
    console.log(response);
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.updateDevicesTitle"),
        message: i18n.t("apiNotifications.updateDevicesFailureGeneral"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}