import {invokeApig, refreshSession} from "../libs/awsLib";
import Paths from "../api/paths";
import {redirect} from "./historyActions";
import {getEmployees} from "./userActions";
import NewIOT from "../IOT/NewIOT";
import {addNotification} from "reapop";
import i18next from "i18next";

export function changeClient(ClientID, FarmID) {
    //console.log(ClientID);
    return function (dispatch) {
        dispatch({
            type: "CHANGE_CLIENT",
            payload: invokeApig({
                ...Paths.service(),
                queryParams: {
                    ClientID,
                    FarmID
                }
            })
        }).then(res => {
            console.log("changeClient res", res);
            dispatch({
                type: "REFRESH_TOKEN",
                payload: refreshSession(),
                meta: {
                    ClientID
                }
            }).then(() => {
                dispatch(getEmployees(ClientID));
                NewIOT.subscribeAllTopics();
            })
        }).catch(e => {
            console.error(e);
            dispatch(redirect("/farmChooser"));
        })
    }
}

export function checkMaintenanceStatus() {
    const type = "MAINTENANCE_STATUS";
    return (dispatch) => {
        dispatch({
            type: type, payload: invokeApig({
                ...Paths.getMaintenance(),
            })
        }).catch(err => {
            let msg = {
                title: i18next.t("error"),
                message: err.message,
                status: 'error',
                dismissible: true
            };
            dispatch(addNotification(msg));
            console.error("checkMaintenanceStatus: ", err)
        })
    }
}

export function deleteFarmData(farmID, params, onSuccess) {
    return function (dispatch) {
        dispatch({
            type: "DELETE_FARM_DATA",
            payload: invokeApig({
                ...Paths.deleteFarmData({farmID}),
                body: {...params}
            })
        }).then((res) => {
            let msg = {
                title: i18next.t("success"),
                message: res.value.message,
                status: "success",
                dismissible: true,
                dismissAfter: 0
            };
            dispatch(addNotification(msg));
            if (onSuccess) onSuccess();
        }).catch((err) => {
            const whiteString = err.data.whiteList.join(", ");
            let msg = {
                title: i18next.t("error"),
                message: `Fermy znajdujące się na whitelist: ${whiteString}`,
                status: "error",
                dismissible: true,
                dismissAfter: 0
            };
            dispatch(addNotification(msg));
        })
    }
}

export function setMaintenanceDate(params, sendMail = false, onSuccess) {
    const {startTime, endTime} = params;
    return function (dispatch) {
        dispatch({
            type: "SET_MAINTENANCE_DATE",
            payload: invokeApig({
                ...Paths.setMaintenanceDate(),
                body: {
                    startTime,
                    endTime
                },
                queryParams: {sendMail}
            })
        }).then(() => {
            let msg = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.maintenanceSuccess"),
                status: "success",
                dismissible: true
            };
            dispatch(addNotification(msg));
            if (onSuccess) onSuccess();
        }).catch((err) => {
            let msg = {
                title: i18next.t("error"),
                message: err.message,
                status: "error",
                dismissible: true
            };
            dispatch(addNotification(msg));
            console.error("setMaintenanceDate: ", err);
        })
    }
}
