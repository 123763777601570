import NewIOT from "../NewIOT";
import {getIOTAttributes} from "../../utils/IOTUtils";
import {ChainFeedingCommandTypes} from "../../constans/mqttMessages";
import i18next from "i18next";


export function clearAlarms(dev, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ChainFeedingCommandTypes.SET_CLEAR_ALARM, {}, {
            loading: {
                title: i18next.t('alarms'),
                message: i18next.t('IOT.chainFeeding.clearAlarmsMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.chainFeeding.clearAlarmsMsgDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.chainFeeding.clearAlarmsMsgFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

export function stopMotor(dev, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ChainFeedingCommandTypes.SET_START_STOP_MOTOR, {state: 0}, {
            loading: {
                title: i18next.t('motor'),
                message: i18next.t('IOT.chainFeeding.stopMotorMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.chainFeeding.stopMotorDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.chainFeeding.stopMotorFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

export function startMotor(dev, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ChainFeedingCommandTypes.SET_START_STOP_MOTOR, {state: 1}, {
            loading: {
                title: i18next.t('motor'),
                message: i18next.t('chainFeeding.startMotorMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.chainFeeding.startMotorDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.chainFeeding.startMotorFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

/**
 *  data ->
 *    schedule: [
 {
			start: number,	//timestamp kiedy rozpoczęcie okna pracy
			stop: number,	//timestamp kiedy zakończenie okna pracy
			workTime: number	//na jak długo włączyć silnik (1-65535[s])
		},
 //x 1-8
 ]
 */
export function setSchedule(dev, data, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ChainFeedingCommandTypes.SET_SCHEDULE, {...data}, {
            loading: {
                title: i18next.t('IOT.chainFeeding.setScheduleTitle'),
                message: i18next.t('IOT.chainFeeding.setScheduleMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.chainFeeding.setScheduleDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.chainFeeding.setScheduleFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

export function setScheduleAnalog(dev, data, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ChainFeedingCommandTypes.SET_SCHEDULE_ANALOG, {...data}, {
            loading: {
                title: i18next.t('IOT.chainFeeding.setScheduleAnalogTitle'),
                message: i18next.t('IOT.chainFeeding.setScheduleAnalogMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.chainFeeding.setScheduleAnalogDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.chainFeeding.setScheduleAnalogFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

/**
 *
 * @param {*} dev
 * @param {number} data.workType (tryb pracy    0 - off, 1 - manual, 2 - automat, 3 - harmonogram)
 * @param {number} data.excessWorkTime (1-65535[s], jak długo silnik może jeszcze maksymalnie pracować po załączeniu się czujnika zapełnienia)
 * @param {number} data.maxWorkTime (1-65535[s], jak długo silnik może maksymalnie pracować za jednym razem)
 * @param {number} data.sensorID (0,1,2,3, index wykorzystywanego czujnika)
 * @param {*} param2
 */
export function setWorkType(dev, data, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ChainFeedingCommandTypes.SET_WORK_TYPE, {...data}, {
            loading: {
                title: i18next.t('worktype'),
                message: i18next.t('IOT.chainFeeding.setWorkTypeMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.chainFeeding.setWorkTypeDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.chainFeeding.setWorkTypeFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

/**
 *
 * @param {*} dev
 * @param {array} data.inputNegation    (zawiera 4 elementy (0/1) który mówią nam o negacji poszczególnych wejść (0 - proste, 1 - zanegowane))
 * @param {array} data.outputNegation   (zawiera 4 elementy (0/1) który mówią nam o negacji poszczególnych wyjść (0 - proste, 1 - zanegowane))
 * @param {array} data.sensorNegation   (zawiera 4 elementy (0/1) który mówią nam o negacji poszczególnych czujników (0 - proste, 1 - zanegowane))
 * @param {number} data.sensorID    (0,1,2,3, index wykorzystywanego czujnika)
 * @param {boolean} data.outputCurrentRangeReversal (zakres pracy wejścia prosty czy odwrocony np (0-10) czy (10-0))
 * @param {boolean} data.inputCurrentRangeReversal  (zakres pracy wejścia prosty czy odwrocony np (0-10) czy (10-0))
 * @param {number} data.outputMinCurrent    (minimalny prąd wyjścia (0-65535[?]))
 * @param {number} data.outputMaxCurrent    (maksymalny prąd wyjścia (0-65535[?]))
 * @param {number} data.inputMinCurrent (minimalny prąd wejścia (0-65535[?]))
 * @param {number} data.inputMaxCurrent (maksymalny prąd wejścia (0-65535[?]))
 * @param {*} param2
 */
export function setSensorWorkType(dev, data, {onSuccess, onFailure} = {}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE, {...data}, {
            loading: {
                title: i18next.t('sensors'),
                message: i18next.t('IOT.chainFeeding.setSensorWorkTypeMsg', {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.chainFeeding.setSensorWorkTypeDone', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.chainFeeding.setSensorWorkTypeFailed', {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}
