import {invokeApig} from "../libs/awsLib";
import notificationsDB from "../database/notificationsDB";
import store from "../store/store"
import Paths from "../api/paths";
import {ReactLocalStorage} from "../utils/ReactLocalStorage";


export function getNotificationsDynamoDB(farmID) {
    return function (dispatch) {
        dispatch({
            type: "LIST_NOTIFICATIONS",
            payload: invokeApig({
                ...Paths.listNotifications({farmID}),
                queryParams: {DtaModTime: notificationsDB.getModificationTime(farmID).DtaModTime}
            })
        }).then(async res => {
            console.time("getNotificationsDynamoDB then");
            await notificationsDB.insertIntoNotifications(res.value);
            notificationsDB.removeOldRecords(farmID);
            dispatch(getAllNotifications(farmID));
            console.timeEnd("getNotificationsDynamoDB then");
        }).catch(() => {
            dispatch(getAllNotifications(farmID));
        })
    }
}

export function setReadTime(time){
    return (dispatch) => {
        ReactLocalStorage.setPrivate("notificationReadTime", time);
        dispatch({
            type: "SET_NOTIFICATIONS_READ_TIME",
            payload: time
        })
    }
}

export function getNotificationsLoki() {
    return (dispatch) => {
        const {location: {farm}} = store.getState();
        notificationsDB.removeOldRecords(farm);
        dispatch(getAllNotifications(farm));
    }
}

export function getAllNotifications(farmID) {
    const notificationReadTime = ReactLocalStorage.getPrivate("notificationReadTime", 0);
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_NOTIFICATIONS",
            payload: {data: notificationsDB.getAllNotifications(farmID), notificationReadTime}
        })
    }
}
