import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n";

export function setFeedingEfficiency({DevIDs=[], SiloIDs=[], PlcmntIDs=[], Efficiency=1000}, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.setFeedingEfficiency({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: {
            DevIDs,
            SiloIDs,
            PlcmntIDs,
            Efficiency
        }
    })
}

export function setFeedingEfficiencyNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.setFeedingEfficiencyTitle"),
        message: i18n.t("success"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function setFeedingEfficiencyNotificationFailure(response, {uniqueId = myID()} = {}) {
    console.log(response);
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.setFeedingEfficiencyTitle"),
        message: i18n.t("apiNotifications.setFeedingEfficiencyFailureGeneral"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}
