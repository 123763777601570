import {invokeApig} from '../libs/awsLib';
import db from "../database/animalsDB"
import animalsDB from "../database/animalsDB"
import {addNotification} from "reapop"
import {EventTypes} from "../constans/eventTypes";
import {createEvent} from "./eventsActions";
import Paths from "../api/paths";
import {cloneDeep, get} from "lodash";
import Animal from "../beans/Animal";
import i18next from "i18next";

/**
 * Create an item in table-grid animals on dynamoDB
 * ACreat any kind of animal (sow, piglet, boar, hog)
 * @param values
 * @param onSuccess
 * @param onFailure
 * @returns {Function}
 */
export function createAnimalDynamoDB(values, {onSuccess, onFailure} = {}) {
    console.warn("VALUES CREATE ANIMAL DYNA", values);
    const FarmID = values.animals[0].FarmID;
    return function (dispatch) {
        //pierw pobieramy liste zwierzat zeby zobaczyc co cos nie doszlo
        dispatch({
            type: "LIST_ANIMAL", payload: invokeApig({
                ...Paths.listAnimal({farmID: FarmID}),
                queryParams: {DtaModTime: db.getModificationTime(FarmID).DtaModTime}
            })
        }).then(async res => {
            //zwierzeta ktore doszly
            const newAnimalsDynamoDB = cloneDeep(get(res, "value.items", []));
            let newAnimalsLocal = cloneDeep(get(values, "animals", [])).map(animal => animal instanceof Animal ? animal.prepareBeanToSave() : animal);
            console.log(res, newAnimalsDynamoDB, newAnimalsLocal, "local animals to create");
            let shouldGetAllAnimals = false;
            //jesli przy wrzucaniu zassalo nam nowe zwierzaki to wrzucamy je do lokiego
            if (newAnimalsDynamoDB.length) {
                await db.insertIntoAnimals(newAnimalsDynamoDB);
                shouldGetAllAnimals = true;
            }
            const newAnimalsDynamoDBNumbers = newAnimalsDynamoDB.map(animal => animal.AnmNo1);
            newAnimalsLocal = newAnimalsLocal.filter(anm => {
                if (newAnimalsDynamoDBNumbers.includes(anm.AnmID)) {
                    const notifi = {
                        title: i18next.t("error"),
                        message: i18next.t("animalsActions.animalAlreadyAdded", {number: anm.AnmNo1}),
                        status: 'error',
                        dismissible: true,
                        dismissAfter: 5000
                    };
                    dispatch(addNotification(notifi));
                    return false;
                } else {
                    return true;
                }
            });
            if (newAnimalsLocal.length) {
                dispatch({
                    type: "CREATE_ANIMAL", payload: invokeApig({
                        ...Paths.createAnimal({farmID: FarmID}),
                        body: {animals: newAnimalsLocal}
                    })
                }).then(async resp => {
                    const savedAnimals = cloneDeep(get(resp, "value.message", []));
                    await db.insertIntoAnimals(savedAnimals);
                    dispatch(getAnimalModificationTime(FarmID));
                    const notifi = {
                        title: i18next.t("success"),
                        message: i18next.t("animalsActions.successfullyAddedAnm", {count: savedAnimals.length}),
                        status: 'success',
                        dismissible: true,
                        dismissAfter: 5000
                    };
                    dispatch(addNotification(notifi));
                    if (onSuccess) {
                        try {
                            onSuccess({animals: savedAnimals, response: resp});
                        } catch (e) {
                            console.error(e);
                        }
                    }

                }).catch(async err => {
                    if (shouldGetAllAnimals) {
                        dispatch(getAnimalModificationTime(FarmID));
                    }
                });
            } else if (shouldGetAllAnimals) {
                dispatch(getAnimalModificationTime(FarmID))
            }
        }).catch((e) => {
            console.error(e);
        });
    }
}

export function removeUnnecessaryDataFromAnimal(values_2) {
    let values = cloneDeep(values_2);
    if (Array.isArray(values)) {
        for (let animal of values) {
            delete animal.events;
            delete animal.$loki;
        }
        return values;
    } else {
        delete values.events;
        delete values.$loki;
    }
    return [values];
}

export function updateAnimalDynamoDB(values_2, clientID, localUserID, notifications, onSuccess) {
    let values = cloneDeep(values_2);
    values = removeUnnecessaryDataFromAnimal(values);
    return function (dispatch) {
        dispatch({
            type: "UPDATE_ANIMAL", payload: invokeApig({
                ...Paths.updateAnimal({clientID: clientID, localUserID: localUserID}),
                body: values
            })
        }).then(() => {
            if (notifications && notifications.success) notifications.success();
            animalsDB.insertIntoAnimals(values).then(() => {
                if (onSuccess) onSuccess();
            });
        }).catch(() => {
            if (notifications && notifications.error) {
                notifications.error();
            }
        });
        dispatch({type: "UPDATE_ANIMAL", payload: values})
    }
}

export function createTransfer(animal_2, events, clientID, localUserID, hideModal, type = EventTypes.TRANSFER, onSuccess = () => {}) {
    let animal = cloneDeep(animal_2);
    animal = removeUnnecessaryDataFromAnimal(animal);
    return function (dispatch) {
        dispatch({
            type: "UPDATE_ANIMAL", payload: invokeApig({
                ...Paths.updateAnimal({clientID: clientID, localUserID: localUserID}),
                body: animal
            })
        }).then(() => {
            dispatch(createEvent(events, {type: type, hideModal: hideModal}, false, null, onSuccess));
        }).catch(err => {
            addNotification({
                title: "Błąd",
                message: "Błąd podczas zapisywania przeniesienia",
                status: 'error',
                dismissible: true,
                dismissAfter: 5000
            })
        });
        dispatch({type: "UPDATE_ANIMAL", payload: animal})
    }
}

export function listAnimalDynamoDB(FarmID, stopLoadingToProps = false, LastEvaluatedKey = undefined) {
    return function (dispatch) {
        let querryParams = {FarmID: FarmID, DtaModTime: db.getModificationTime(FarmID).DtaModTime, partial: true};
        if (LastEvaluatedKey) {
            querryParams.DtaModTime = 0;
            querryParams.ESKDtaModTime = LastEvaluatedKey.DtaModTime;
            querryParams.ESKAnmID = LastEvaluatedKey.AnmID;
        }
        dispatch({
            type: "LIST_ANIMAL", payload: invokeApig({
                ...Paths.listAnimal({farmID: FarmID}),
                queryParams: querryParams
            })
        }).then(async res => {
            let l = {};
            Object.assign(l, res);
            if (res.value.items.length > 0) {
                let tmp = [];
                Object.assign(tmp, res.value.items);
                await db.insertIntoAnimals(tmp);
            }
            if (res.value.LastEvaluatedKey) {
                dispatch(listAnimalDynamoDB(FarmID, stopLoadingToProps, res.value.LastEvaluatedKey))
            }
            !stopLoadingToProps && dispatch(getAnimalModificationTime(FarmID))
        }).catch(error => {
            !stopLoadingToProps && dispatch(getAnimalModificationTime(FarmID))
        })

    }
}

/**
 * Funkcja zastepuje getAllAnimal ktora wrzucala wszystkie swinie do stora - nikt nie był do nich podpięty a pobieranie samych swin trwało dla 6000 tyś 20 ms dla każdej z nich dając długie czasy ładowania
 * @param farmId
 * @return {function(...[*]=)}
 */
export function getAnimalModificationTime(farmId) {
    return function (dispatch) {
        dispatch({
            type: "GET_ANIMAL_MODIFICATION_TIME", payload: db.getModificationTime(farmId)
        });
    }
}
