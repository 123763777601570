import NewIOT from "../NewIOT";
import {ClimateDriverCommandTypes} from "../../constans/mqttMessages";
import {getIOTAttributes} from "../../utils/IOTUtils";
import i18next from "i18next";

export function setConfiguration(dev, data, onSuccess, onFailure, hideNotification = false) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateDriverCommandTypes.SET_CONFIGURATION, data, !hideNotification ? {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setConfiguration", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setConfigurationDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setConfigurationFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

export function getConfiguration(dev, onSuccess, onFailure, onSend) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateDriverCommandTypes.GET_CONFIGURATION, null, null, onSuccess, onFailure, onSend);
    }
}

export function getAmbientConditions(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateDriverCommandTypes.GET_AMBIENT_CONDITIONS);
    }
}

export function getClockMenu(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateDriverCommandTypes.GET_CLOCK_MENU);
    }
}

export function setClockMenu(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateDriverCommandTypes.SET_CLOCK_MENU, data, {
            loading: {
                title: i18next.t("IOT.clockMenu"),
                message: i18next.t("IOT.setClockMenu", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setClockMenuDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setClockMenuFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function getServiceMenu(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateDriverCommandTypes.GET_SERVICE_MENU);
    }
}

export function setServiceMenu(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ClimateDriverCommandTypes.SET_SERVICE_MENU, data, {
            loading: {
                title: i18next.t("IOT.serviceMenu"),
                message: i18next.t("IOT.setServiceMenu", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setServiceMenuDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setServiceMenuFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}