/**
 *
 * @param shadow
 * @param isDelta
 * @returns {Function}
 */
export function changeShadowState(shadow, isDelta = false) {
    return function (dispatch) {
        dispatch({
            type: `CHANGE_SHADOWS${isDelta ? '_DELTA' : ''}`,
            payload: shadow
        })
    }
}

/**
 * Zmienia shadow animalów
 * @param animalShadow
 * @param isDelta
 * @returns {Function}
 */
export function changeAnimalShadow(animalShadow, isDelta = false) {
    return function (dispatch) {
        dispatch({
            type: `CHANGE_SHADOWS_ANIMAL${isDelta ? '_DELTA' : ''}`,
            payload: animalShadow
        })
    }
}


export function removeRFIDsFromAnimalShadow(rfids, location) {
    return function (dispatch) {
        dispatch({
            type: "REMOVE_RFIDS_FROM_ANIMAL_SHADOW",
            payload: rfids
        })

    }
}

/**
 *
 * @param DevID {array|string}
 * @param status{boolean|null}
 * @return {function(...[*]=)}
 */
export function setShadowLoading({DevID, status} = {}) {
    return function (dispatch) {
        dispatch({
            type: "SET_SHADOW_LOADING",
            payload: {DevID, status}
        })
    }
}