import {addNotification} from "reapop";
import {invokeApig} from "../libs/awsLib";
import db from "../database/technologyGroupsDB"
import Paths from "../api/paths";
import i18next from "i18next";

export function createTechnologyGroup(values) {
    return function (dispatch) {
        dispatch({
            type: "CREATE_TECHNOLOGY_GROUP", payload: invokeApig({
                ...Paths.createTechnologyGroups({farmID: values.FarmID}),
                body: values
            })
        }).then(() => {
            const notifi = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.technoGroupUpdatedOk"),
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(notifi));
        }).catch(e => {
            console.error(e);
        });
    }
}

/**
 * @deprecated prosze używać updateTechnologyGroups (przyjmuje tablice)
 * @param values
 * @param oldDtaModTime - useless bo api tego nie uwzględnia
 * @param clientID
 * @param localUserID
 * @returns {Function}
 */
export function updateTechnologyGroup(values, oldDtaModTime, clientID, localUserID) {
    return function (dispatch) {
        dispatch({
            type: "UPDATE_TECHNOLOGY_GROUP", payload: invokeApig({
                ...Paths.updateTechnologyGroups({farmID: values.FarmID, clientID: clientID, localUserID: localUserID}),
                body: values,
                queryParams: {DtaModTime: oldDtaModTime}
            })
        }).then(() => {
            const notifi = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.technoGroupUpdated"),
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(notifi));
        }).catch(e => {
            console.error(e);
        });
    }
}

export function listTechnologyGroupDynamoDB(FarmID) {
    return function (dispatch) {
        dispatch({
            type: "LIST_TECHNOLOGY_GROUP", payload: invokeApig({
                ...Paths.listTechnologyGroups({farmID: FarmID}),
                queryParams: {DtaModTime: db.getModificationTime(FarmID).DtaModTime}
            })
        }).then(async (res) => {
            var l = {};
            Object.assign(l, res);
            //console.log("res", l);
            if (res.value.length > 0) {
                let tmp = [];
                Object.assign(tmp, res.value);
                await db.insertIntoTechnologyGroup(tmp);
            }
            dispatch(getTechnologyGroups(FarmID));
        }).catch(() => {
            dispatch(getTechnologyGroups(FarmID));
        });
    }
}

export function getCreateDay() {
    return function (dispatch) {
        dispatch({
            type: "GET_CREATE_DAY",
            payload: db.getCreateDay()
        })
    }
}

export function getTechnologyGroups(farmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_TECHNOLOGY_GROUPS",
            payload: db.getTechnologyGroups(farmID)
        })
    }
}
