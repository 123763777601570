import NewIOT from "../NewIOT"
import {SeparationCageCommandTypes} from "../../constans/mqttMessages";
import store from "../../store/store";
import {changeShadowState} from "../../actions/shadowsActions";
import _ from "lodash";
import {getIOTAttributes} from "../../utils/IOTUtils";
import {addNotification} from "reapop";
import {updateDeviceDynamoDB} from "../../actions/devicesActions";
import i18next from "i18next";

export function setRTC(dev, time = new Date().getTime()) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            time: time
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_RTC, data, {
            loading: {
                title: i18next.t("IOT.changeRTC"),
                message: i18next.t("IOT.setTimeOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setTimeOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setTimeOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getRTC(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_RTC);
    }
}

export function simulateWeights(dev, weight, state) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            weight: weight,
            state: state
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SIMULATE_WEIGHTS, data, {
            loading: {
                title: i18next.t("IOT.weightSimulation"),
                message: i18next.t("IOT.weightSimulationOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setWeightSimulationOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setWeightSimulationOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getCurrentData(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_CURRENT_DATA);
    }
}

export function getExpeditionAnimalCount(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_EXPEDITION_ANIMAL_COUNT);
    }
}

export function getSeparationAnimalsNumber(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_SEPARATION_ANIMALS_NUMBER);
    }
}

export function getWeight(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_WEIGHT);
    }
}

export function getSensorsAndValves(dev, onSuccess, onError, onSend) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_SENSORS_AND_VALVES, null, null, onSuccess, onError, onSend);
    }
}

export function getDate(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_DATE);
    }
}

export function getWorkType(dev, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_WORK_TYPE);
    }
}

export function getDefaultExit(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_DEFAULT_EXIT);
    }
}

export function getDefaultExitExpedition(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_DEFAULT_EXIT_EXPEDITION);
    }
}

export function getWorkTypeDuration(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_WORK_TYPE_DURATION);
    }
}

export function getExpeditionWorkType(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_EXPEDITION_WORK_TYPE);
    }
}

export function getExpeditionWeightRange(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_EXPEDITION_WEIGHT_RANGE);
    }
}

export function getExpeditionCounter(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_EXPEDITION_COUNTER);
    }
}

export function getSeparationCounter(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_SEPARATION_COUNTER);
    }
}

export function getSeparationWeightRange(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_SEPARATION_WEIGHT_RANGE);
    }
}

export function getScaleFactors(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_SCALE_FACTORS);
    }
}

export function getAverageWeightClosed(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_AVERAGE_WEIGHT_CLOSED);
    }
}

export function setWorkType(dev, workType, onSuccess = null) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            workType: workType
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_WORK_TYPE, data, {
            loading: {
                title: i18next.t("worktype"),
                message: i18next.t("IOT.setOperationMode", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setOperationModeDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setOperationModeFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess);
    }
}

/**
 *
 * @param dev
 * @param data {object} ilosc dni danego teningu
 * @param data.training1
 * @param data.training2
 * @param data.training3
 * @param data.separation
 * @param data.expedition
 * @returns {string}
 */
export function setWorkTypeDuration(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_WORK_TYPE_DURATION, data, {
            loading: {
                title: i18next.t("IOT.operatingModeTime"),
                message: i18next.t("IOT.setOperatingModeTimeOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setOperatingModeTimeOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setOperatingModeTimeFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, msg => {
            let deviceIDs = _.isArray(msg.DevID) ? msg.DevID : [msg.DevID];
            for (let id of deviceIDs) {
                let shadow = _.cloneDeep(store.getState().shadows.shadows.get(id));
                if (shadow && shadow.configuration) {
                    shadow.configuration.workTypeDuration = msg.CData;
                    let map = new Map();
                    map.set(id, shadow);
                    store.dispatch(changeShadowState(map));
                }
            }
        });
    }

}

export function setValve(dev, valve, status) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            number: valve,
            value: status
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_VALVE, data, {
            loading: {
                title: i18next.t("IOT.valve"),
                message: i18next.t("IOT.valveStatusChange") + valve + i18next.t("IOT.on", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.valveStatusChangeDone") + valve + i18next.t("IOT.on", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.valveStatusChangeFailed") + valve + i18next.t("IOT.on", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, msg => {
            console.log(msg);
            let deviceIDs = _.isArray(msg.DevID) ? msg.DevID : [msg.DevID];
            for (let id of deviceIDs) {
                let shadow = _.cloneDeep(store.getState().shadows.shadows.get(id));
                if (shadow && shadow.valves) {
                    let map = new Map();
                    shadow.valves[msg.CData.number - 1].valve = msg.CData.status;
                    map.set(id, shadow);
                    store.dispatch(changeShadowState(map));
                }
            }
        });
    }
}

export function reset(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.RESET, null, {
            loading: {
                title: i18next.t("IOT.resetting"),
                message: i18next.t("IOT.resetting", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.resetInProgress", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.resetFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function setDefaultExit(dev, defaultExit) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            defaultExit: defaultExit
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_DEFAULT_EXIT, data, {
            loading: {
                title: i18next.t("defaultExit"),
                message: i18next.t("IOT.changeDefaultExitOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.changeDefaultExitOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.changeDefaultExitOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, msg => {
            let deviceIDs = _.isArray(msg.DevID) ? msg.DevID : [msg.DevID];
            for (let id of deviceIDs) {
                let shadow = _.cloneDeep(store.getState().shadows.shadows.get(id));
                if (shadow && shadow.configuration) {
                    shadow.configuration.defaultExit = msg.CData.defaultExit;
                    let map = new Map();
                    map.set(id, shadow);
                    store.dispatch(changeShadowState(map));
                }
            }

        });
    }

}

export function setDefaultExitExpedition(dev, defaultExit) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            defaultExit: defaultExit
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_DEFAULT_EXIT_EXPEDITION, data, {
            loading: {
                title: i18next.t("IOT.defaultExitExpedition"),
                message: i18next.t("IOT.theDefaultOutputChangeTo", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.theDefaultOutputChangeToDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.theDefaultOutputChangeToFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function setExpeditionAnimalNumber(dev, animalNumber, onSuccess = null) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            animalsNumber: +animalNumber
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_EXPEDITION_ANIMALS_NUMBER, data, {
            loading: {
                title: i18next.t("IOT.animalAmountExpedition"),
                message: i18next.t("IOT.changeAnimalAmountExpeditionOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.changeAnimalAmountExpeditionOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.changeAnimalAmountExpeditionOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess);
    }

}

export function setExpeditionWeightRange(dev, min, max, onSuccess = null) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            minWeight: +min,
            maxWeight: +max
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_EXPEDITION_WEIGHT_RANGE, data, {
            loading: {
                title: i18next.t("IOT.weightRangeExpedition"),
                message: i18next.t("IOT.weightRangeExpeditionChangeOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.weightRangeExpeditionChangeOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.weightRangeExpeditionChangeOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess);
    }

}

export function setExpeditionWorkType(dev, workType, onSuccess = null) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            workType: workType
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_EXPEDITION_WORK_TYPE, data, {
            loading: {
                title: i18next.t("IOT.operatingModeExpedition"),
                message: i18next.t("IOT.operatingModeExpeditionChangeOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.operatingModeExpeditionChangeOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.operatingModeExpeditionChangeOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess);
    }
}

export function setSeparationWeightRange(dev, min, max) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            minWeight: +min,
            maxWeight: +max
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_SEPARATION_WEIGHT_RANGE, data, {
            loading: {
                title: i18next.t("IOT.weightRangeSeparation"),
                message: i18next.t("IOT.weightRangeSeparationChangeOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.weightRangeSeparationChangeOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.weightRangeSeparationChangeOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export  function setSeparationClearErrors(dev){
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_CLEAR_ERRORS,{}, {
            loading: {
                title: i18next.t("IOT.clearCageErrors"),
                message: i18next.t("IOT.clearCageErrorsText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.clearCageErrorsSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.clearCageErrorsError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setSeparationAnimalCount(dev, number) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            animalsNumber: +number
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_SEPARATION_ANIMAL_COUNT, data, {
            loading: {
                title: i18next.t("IOT.setSeparationAnimalCount"),
                message: i18next.t("IOT.setSeparationAnimalCountText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSeparationAnimalCountSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSeparationAnimalCountError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function setScale(dev, scale, onSuccess = null, onFailure = null) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            scale
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_SCALE, data, {
            loading: {
                title: i18next.t("IOT.setScale"),
                message: i18next.t("IOT.setScaleOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setScaleOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setScaleOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

export function setScaleFactor(dev, sw, sd) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            SW: +sw,
            SD: +sd
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_SCALE_FACTOR, data, {
            loading: {
                title: i18next.t("weightSettings"),
                message: i18next.t("IOT.weightSettingsChangeOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.weightSettingsChangeOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.weightSettingsChangeOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

/**
 * Funkcja resetuje ustawienia rzutu i ustawia nowe wartości
 * @param dev           urządzenie
 * @param inserted      ilość wprowadzonych loch
 * @param weight        waga wprowadzonych loch
 * @param time          data wprowadzenia w timestampie
 * @return {string}
 */
export function createNewInsertion(dev, inserted, weight, time) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_INSERTED, {inserted}, {
            loading: {
                title: i18next.t("animalCount"),
                message: i18next.t("IOT.setAnimalAmountOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAnimalAmountOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAnimalAmountOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, (msg) => {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_AVERAGE_HERD_WEIGHT, {weight}, {
                loading: {
                    title: i18next.t("IOT.averageHerdWeight"),
                    message: i18next.t("IOT.setAverageHerdWeightOn", {name: Name}),
                    status: 'loading',
                    dismissible: false,
                    dismissAfter: 0
                },
                success: {
                    message: i18next.t("IOT.setAverageHerdWeightOnDone", {name: Name}),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "success"
                },
                error: {
                    message: i18next.t("IOT.setAverageHerdWeightOnFailed", {name: Name}),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "error"
                },
                DevID: DevID
            }, (msg) => {
                NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.CLEAR_INSERTION_DATA, null, {
                    loading: {
                        title: i18next.t("IOT.clearingInsertionHistory"),
                        message: i18next.t("IOT.clearingInsertionHistoryOn", {name: Name}),
                        status: 'loading',
                        dismissible: false,
                        dismissAfter: 0
                    },
                    success: {
                        message: i18next.t("IOT.clearingInsertionHistoryOnDone", {name: Name}),
                        dismissible: true,
                        dismissAfter: 3000,
                        status: "success"
                    },
                    error: {
                        message: i18next.t("IOT.clearingInsertionHistoryOnFailed", {name: Name}),
                        dismissible: true,
                        dismissAfter: 3000,
                        status: "error"
                    },
                    DevID: DevID
                });
                console.log("ZAPISANO");
                dev.addInsertion(time, inserted);
                let notif = {
                    success: () => {
                        let notifi = {
                            title: i18next.t("popNotifications.sepaCageSettings"),
                            message: i18next.t("popNotifications.saveSuccess"),
                            status: 'success',
                            dismissible: true,
                            dismissAfter: 5000,
                        };
                        store.dispatch(addNotification(notifi));
                    },
                    error: () => {
                        let notifi = {
                            title: i18next.t("popNotifications.sepaCageSettings"),
                            message: i18next.t("popNotifications.editionErr"),
                            status: 'success',
                            dismissible: true,
                            dismissAfter: 5000,
                        };
                        store.dispatch(addNotification(notifi));
                    }
                };
                store.dispatch(updateDeviceDynamoDB(dev.prepareBeanToSave(), dev.FarmID, ClientID, LocalUserID, notif));
            });
        });
    }
}

export function clearInsertionData(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.CLEAR_INSERTION_DATA, null, {
            loading: {
                title: i18next.t("IOT.clearingInsertionHistory"),
                message: i18next.t("IOT.clearingInsertionHistoryOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.clearingInsertionHistoryOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.clearingInsertionHistoryOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getErrors(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_ERRORS);
    }
}

export function getVisitsInWeightsYesterday(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_VISITS_IN_WEIGHTS_YESTERDAY);
    }
}

export function getAverageWeightAllExitsYesterday(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_AVERAGE_WEIGHT_ALL_EXITS_YESTERDAY);
    }
}

export function getDailyVisitsNumberYesterdayForExit(dev, exit) {
    let command = "GET_DAILY_VISITS_NUMBER_YESTERDAY_EXIT" + exit;
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes[command]);
    }
}

export function getVisitsNumberYesterday(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_VISITS_NUMBER_YESTERDAY);
    }
}

export function setInserted(dev, inserted) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            inserted: inserted
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_INSERTED, data, {
            loading: {
                title: i18next.t("animalCount"),
                message: i18next.t("IOT.setAnimalAmountOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAnimalAmountOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAnimalAmountOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getISMAD(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_ISMAD);
    }
}

export function getDailyVisitsNumberNow(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_DAILY_VISITS_NUMBER_NOW);
    }
}

export function getDailyVisitsNumberNowForExit(dev, exit) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let command = "GET_DAILY_VISITS_NUMBER_NOW_EXIT" + exit;
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes[command]);
    }
}

export function getReturnToDefaultExit(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_RETURN_TO_DEFAULT_EXIT);
    }
}

export function setReturnToDefaultExit(dev, state) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            returnToDefaultExit: !!state
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_RETURN_TO_DEFAULT_EXITL, data, {
            loading: {
                title: i18next.t("IOT.returnToDefaultExit"),
                message: i18next.t("IOT.setReturnToDefaultExitOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setReturnToDefaultExitOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setReturnToDefaultExitOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getAttemptsNumber(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_ATTEMPTS_NUMBER);
    }
}

export function setAttemptsNumber(dev, number) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            attemptsNumber: +number
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_ATTEMPTS_NUMBER, data, {
            loading: {
                title: i18next.t("amountTries"),
                message: i18next.t("IOT.setAmountOfTriesOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAmountOfTriesOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAmountOfTriesOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, msg => {
            let deviceIDs = _.isArray(msg.DevID) ? msg.DevID : [msg.DevID];
            for (let id of deviceIDs) {
                let shadow = _.cloneDeep(store.getState().shadows.shadows.get(id));
                if (shadow && shadow.configuration) {
                    shadow.configuration.attemptsNumber = msg.CData.number;
                    let map = new Map();
                    map.set(id, shadow);
                    store.dispatch(changeShadowState(map));
                }
            }
        });
    }

}

export function getExitTime(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_EXIT_TIME);
    }
}

export function setExitTime(dev, time) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            exitTime: +time
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_EXIT_TIME, data, {
            loading: {
                title: i18next.t("exitTime"),
                message: i18next.t("IOT.setExitTimeOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setExitTimeOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setExitTimeOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getMessagesState(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_MESSAGES_STATE);
    }
}

export function setMessagesState(dev, state) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            state: state
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_MESSAGES_STATE, data, {
            loading: {
                title: i18next.t("IOT.messageStatus"),
                message: i18next.t("IOT.setMessageStatusOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setMessageStatusOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setMessageStatusOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function getTareStatus(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.GET_TARE_STATUS);
    }
}

export function setTareStatus(dev, status) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            tareStatus: !!status
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_TARE_STATUS, data, {
            loading: {
                title: i18next.t("IOT.automaticTaring"),
                message: i18next.t("IOT.automaticTaringChangeOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.automaticTaringChangeOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.automaticTaringChangeOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function doTare(dev, onSuccess = null, onFailure = null) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.DO_TARE, null, {
            loading: {
                title: i18next.t("IOT.weightTaring"),
                message: i18next.t("IOT.weightTaringOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.weightTaredOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.weightTaredOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure)
    }
}

export function resetDailyVisits(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.RESET_DAILY_VISITS, null, {
            loading: {
                title: i18next.t("IOT.resetDailyPasses"),
                message: i18next.t("IOT.resetDailyPassesOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.resetDailyPassesOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.resetDailyPassesOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        })
    }
}

export function setAverageHerdWeight(dev, weight) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_AVERAGE_HERD_WEIGHT, {weight: weight}, {
            loading: {
                title: i18next.t("IOT.averageHerdWeight"),
                message: i18next.t("IOT.setAverageHerdWeightOn", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAverageHerdWeightOnDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAverageHerdWeightOnFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        })
    }
}

export function setAverageWeightYesterday(dev, weight) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_AVERAGE_WEIGHT_YESTERDAY, {yesterday: weight}, {
            loading: {
                title: i18next.t("IOT.setAverageWeightYesterday"),
                message: i18next.t("IOT.setAverageWeightYesterdayText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAverageWeightYesterdaySuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAverageWeightYesterdayFailure", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        })
    }
}

/**
 *
 * @param dev
 * @param data
 * @param data.exit1
 * @param data.exit3
 * @returns {string}
 */
export function setNumbersSendToExits(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_NUMBERS_SEND_TO_EXITS, data, {
            loading: {
                title: i18next.t("amountAnimalsToExit"),
                message: i18next.t("IOT.setNumbersSendToExitsText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setNumbersSendToExitsSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setNumbersSendToExitsFailure", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        })
    }
}

export function setExpeditionData(dev, animalCount, minWeight, maxWeight, expeditionWorkType, keepCounter) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            animalsNumber: +animalCount,
            minWeight: +minWeight,
            maxWeight: +maxWeight,
            keepCounter,
            workType: expeditionWorkType
        };
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_EXPEDITION_DATA, data, {
            loading: {
                title: i18next.t("IOT.setExpeditionData"),
                message: i18next.t("IOT.setExpeditionDataText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setExpeditionDataSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setExpeditionDataFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        })
    }
}

/**
 *
 * @param dev
 * @param data
 * @param data.number - nr markera
 * @param data.time - czas spryskiwania 100-2000ms
 * @param data.workType - 0 - wył; 1 - znakuj wy1, 2 - znakuj wy2, 3 - znakuj wy3, 4 - znakuj wszystkie
 * @param data.minWeight - waga min w g min 10000
 * @param data.maxWeight - waga max w g max 180000
 * @returns {string}
 */
export function setMarkingConfig(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_MARKING_CONFIG, data, {
            loading: {
                title: i18next.t("IOT.setMarkingConfig"),
                message: i18next.t("IOT.setMarkingConfigText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setMarkingConfigSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setMarkingConfigFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        })
    }
}


export function resetMarkerUsage(dev, {marker1 = false, marker2 = false}) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, SeparationCageCommandTypes.SET_RESET_DYE_USAGE, {markers: [!!marker1, !!marker2]}, {
            loading: {
                title: i18next.t("IOT.resetMarker"),
                message: i18next.t("IOT.resetMarkerText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.resetMarkerSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.resetMarkerFailure", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        })
    }
}