export function inventoryInit(animals = [], groups = [], technoGroups = [], animalTypeMap = new Map()) {
    return function (dispatch) {
        dispatch({
            type: "INVENTORY_INITIALIZE",
            payload: {
                animals: animals,
                groups: groups,
                technoGroups: technoGroups,
                animalTypeMap: animalTypeMap
            }
        })
    }
}

export function inventoryDestroy() {
    return function (dispatch) {
        dispatch({
            type: "INVENTORY_DESTROY",
        })
    }
}

export function inventoryFilter(inventoryFilter) {
    return function (dispatch) {
        dispatch({
            type: "INVENTORY_FILTER",
            payload: {
                inventoryFilter: inventoryFilter
            }
        })
    }
}

export function inventorySort(inventorySort) {
    return function (dispatch) {
        dispatch({
            type: "INVENTORY_SORT",
            payload: {
                inventorySort: inventorySort
            }
        })
    }
}

export function inventoryLocationFilter(location = null) {
    return function (dispatch) {
        dispatch({
            type: "INVENTORY_LOCATION_FILTER",
            payload: {
                location: location
            }
        })
    }
}

export function inventoryShowDead(showDead = false) {
    return function (dispatch) {
        dispatch({
            type: "INVENTORY_SHOW_DEAD",
            payload: {showDead}

        })
    }
}

export function inventoryShowCollapsed(showCollapsed = false) {
    return function (dispatch) {
        dispatch({
            type: "INVENTORY_SHOW_COLLAPSED",
            payload: {showCollapsed}

        })
    }
}
