import store from "../store/store";
import {DevType} from "../constans/devices";
import {ping} from "../IOT/device-functions/GatewayFunctions";
import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import {get} from "lodash";

export function clearStatuses(){
    return function (dispatch) {
        dispatch({
            type: "MQTT_CLEAR_STATUS"
        })
    }
}

export function onPingSuccess(data, GatewayID) {
    return function (dispatch) {
        dispatch({
            type: "MQTT_ON_PING_SUCCESS",
            payload: {
                GatewayID,
                compilation: get(data, 'CAnsw.compilation', undefined),
                version: get(data, 'CAnsw.version', undefined),
            }
        })
    }
}

export function onPingSend(GatewayID) {
    return function (dispatch) {
        dispatch({
            type: "MQTT_ON_PING_SEND",
            payload: GatewayID
        })
    }
}

export function onPingFailure(GatewayID) {
    return function (dispatch) {
        dispatch({
            type: "MQTT_ON_PING_FAILURE",
            payload: GatewayID
        })
    }
}

export function pingGateways(overrideDevices) {
    let devices = overrideDevices || store.getState().farmDevices.devices;
    return function (dispatch) {
        dispatch(clearStatuses());
        devices.forEach(dev=>{
            if(dev.DevType === DevType.GATEWAY){
                ping(dev.DevID, (data) => dispatch(onPingSuccess(data, dev.DevID)), () => dispatch(onPingFailure(dev.DevID)), ()=>dispatch(onPingSend(dev.DevID)));
            }
        })
    }
}

export function registerIOT() {
    return async function (dispatch) {
        dispatch({
            type: "REGISTER_IOT",
            payload: invokeApig({...Paths.registerIOT()})
        });
    }
}

