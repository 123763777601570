import settingsDB from "../database/settingsDB";
import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import {SettingTypes} from "../constans/settingTypes";
import {loadingNotification, onErrorNotification, onSuccessNotification} from "../utils/ReapopNotificationUtils";
import _ from "lodash";
import {cutUselessPrefixes} from "../utils/DevicesUtils";
import {getActiveFarmID} from "../utils/BuildingUtils";
import store from "../store/store";
// import {loadingNotification} from "../utils/ReapopNotificationUtils";
// import _ from "lodash";
// import {getLocationName} from "../utils/BuildingUtils";
import i18n from "../i18n"

const t = (val) => i18n.t(val);

export function getSettings(ClientID, farmID = getActiveFarmID()) {
    return function (dispatch) {
        let user = store.getState().user.user;
        console.log(farmID);
        dispatch({
            type: "GET_SETTINGS_DATA", payload: invokeApig({
                ...Paths.listSettings({
                    clientID: ClientID
                }),
                queryParams: {DtaModTime: settingsDB.getModificationTime(farmID).DtaModTime}
            }).then(async res => {
                var l = {};
                Object.assign(l, res);
                //console.log("res", l);
                if (res.length > 0) {
                    //console.log("wchodze do ifa");
                    let tmp = [];
                    Object.assign(tmp, res);
                    // console.log("tmp", tmp);
                    await settingsDB.insertIntoSettings(tmp);
                }
                if (farmID) {
                    dispatch({
                        type: "LIST_ALL_SETTINGS",
                        payload: settingsDB.getAllSettings(farmID),
                        meta: {
                            user
                        }
                    })
                }
            }).catch(error => {
                if (farmID) {
                    dispatch({
                        type: "LIST_ALL_SETTINGS",
                        payload: settingsDB.getAllSettings(farmID),
                        meta: {
                            user
                        }
                    })
                }
            })
        });
    }
}

/**
 *
 * @param values - pojedynczy setting lub tablica (?)
 * @param onSuccess
 * @param onFailure
 * @returns {Function}
 */
export function createSettingDynamoDB(values, onSuccess, onFailure) {
    console.log("createSettingDynamoDB", values, onSuccess, onFailure);
    let setting = cutUselessPrefixes(values, ["$loki", "meta"]);
    return function (dispatch) {
        let notification = getNotification(setting);
        if (notification) {
            notification = loadingNotification("Dodawanie", notification);
        }
        dispatch({
            type: "CREATE_SETTING", payload: invokeApig({
                ...Paths.createSetting(),
                body: setting
            })
        }).then(res => {
            notification && onSuccessNotification("Dodano pomyślnie", notification);
            if (_.isFunction(onSuccess)) {
                try {
                    onSuccess(res);
                } catch (e) {
                    console.error(e);
                }
            }
        }).catch(err => {
            console.error(err);
            notification && onErrorNotification("Błąd dodawania", notification);
            if (_.isFunction(onFailure)) {
                try {
                    onFailure(err);
                } catch (e) {
                    console.error(e);
                }
            }
        });
        // dispatch({type: "CREATE_SETTING", payload: setting})
    }
}

/**
 * Funkcja updatująca ustawienia - notyfikacje są dodawane automatycznie
 * @param values - ustawienie pojedyncze / lub (?) array
 * @param onSuccess - funkcja wykonujaca się przy pomyslnej aktualizacji
 * @param onFailure - funkcja wykonujaca się przy bledzie aktualizacji
 * @returns {Function}
 */
export function updateSettingDynamoDB(values, onSuccess, onFailure) {
    console.log("updateSettingDynamoDB", values, onSuccess, onFailure);
    let setting = cutUselessPrefixes(values, ["$loki", "meta"]);
    let notification = getNotification(setting);
    if (notification) {
        notification = loadingNotification(isDelete(setting) ? "Usuwanie" : "Aktualizacja", notification);
    }
    return function (dispatch) {
        dispatch({
            type: "UPDATE_SETTING", payload: invokeApig({
                ...Paths.updateSetting(),
                body: setting
            })
        }).then(res => {
            notification && onSuccessNotification(isDelete(setting) ? "Usunięto pomyślnie" : "Zaktualizowano pomyślnie", notification);
            if (_.isFunction(onSuccess)) {
                try {
                    onSuccess(res);
                } catch (e) {
                    console.error(e);
                }
            }

        }).catch(err => {
            console.error(err);
            notification && onErrorNotification(isDelete(setting) ? "Błąd usuwania" : "Błąd dodawania", notification);
            if (_.isFunction(onFailure)) {
                try {
                    onFailure(err);
                } catch (e) {
                    console.error(e);
                }
            }
        });
    }
}

function getNotification(setting) {
    if (_.isArray(setting) && setting.length > 1) {
        return t('settings.settings');
    } else {
        let type = _.isArray(setting) ? setting[0].SetType : setting.SetType;
        switch (type) {
            case SettingTypes.FEEDING_CURVE:
                return t("feedingCurve");
            case SettingTypes.FEEDING_SCHEDULE:
                return t("notificationsSettings.feedingSchedule");
            case SettingTypes.FEEDING_FORAGE:
                return t("forage");
            case SettingTypes.FARM_MAP:
                return t("map");
            case SettingTypes.GENERAL:
                return t("notificationsSettings.mainSettings");
            case SettingTypes.NOTIFICATIONS:
                return t("notifications.notifications");
            case SettingTypes.LAYOUT:
                return t("notificationsSettings.dashBoard");
            case SettingTypes.YEARLY_PLANS:
                return t("notificationsSettings.annualPlan");
            case SettingTypes.ECONOMY:
                return t("notificationsSettings.sectorWithForage");
            default:
                return null;
        }
    }

}

function isDelete(setting) {
    if (_.isArray(setting)) {
        return !!setting[0].DtaDelTime;
    } else {
        return !!setting.DtaDelTime;
    }

}

//const mapStateToProps= state => ({content: state.language.lang.page.newSettings.headers.notifications});

