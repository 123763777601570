import NewIOT from "../NewIOT";
import {DispenserNRFCommandTypes, GatawayCommandTypes} from "../../constans/mqttMessages";
import store from "../../store/store";
import {getIOTAttributes} from "../../utils/IOTUtils";
import {removeRFIDsFromAnimalShadow} from "../../actions/shadowsActions";
import _ from "lodash"
import i18n from "../../i18n";
import i18next from "i18next";

export function setNutritionCurve(dev, curve, updateAPI, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid && curve && curve.convertToBackend) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_NUTRITION_CURVE, curve.convertToBackend(updateAPI), {
            loading: {
                title: i18next.t("feedingCurve"),
                message: i18next.t("IOT.setTheFeedingCurveOnAllDevicesUnder", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setTheFeedingCurveOnAllDevicesUnderDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setTheFeedingCurveOnAllDevicesUnderFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }

}

export function setSchedule(dev, schedule, updateAPI) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid && schedule && schedule.convertToBackend) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_SCHEDULE, schedule.convertToBackend(updateAPI), {
            loading: {
                title: i18next.t("schedule"),
                message: i18next.t("IOT.setScheduleOnAllDevicesUnder", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setScheduleOnAllDevicesUnderDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setScheduleOnAllDevicesUnderFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }

}

export function setFeedEFCTime(dev, forage) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid && forage && forage.convertToBackend) {
        console.log(forage);
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_FEED_EFC_TIME, forage.convertToBackend(), {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setEfficiencyTime", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setEfficiencyTimeDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setEfficiencyTimeFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }

}

export function getPig(dev, RFID, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.GET_PIG, {
            type: "RFID",
            RFID: RFID
        }, null, onSuccess, onFailure);
    }
}

export function getDailyUsage(dev, locationID, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            PlcmntID: locationID
        };
        return NewIOT.createAndSendMessageObjectToStateTopic(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.GET_DAILY_USAGE, data, null, onSuccess, onFailure);

    }
}


export function setChamberDevices(dev, chamberDevices = {}, onSuccess = undefined, onFailure = undefined) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_CHAMBER_DEVICES, chamberDevices, null, onSuccess, onFailure);
    }
}

export function addressWST(dev, data, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.START_ADR_WST, data, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: i18n.t("IOT.startAddressing", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.startAddressingDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.startAddressingFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }

}

export function addressNRF(dev, data, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.START_ADDRESSING_NRF, data, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: i18n.t("IOT.startAddressing", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.startAddressingDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.startAddressingFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }

}

export function subaddressNRF(dev, start, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid, DevID} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, GatawayCommandTypes.ADDR_START_SUBADDRESSING_NRF, {
            state: start ? 1 : 0
        }, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: start ? i18n.t("IOT.startSubAddressing", {name: Name}) : i18n.t("IOT.stopSubAddressing", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: start ? i18n.t("IOT.startSubAddressingDone", {name: Name}) : i18n.t("IOT.stopSubAddressingDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: start ? i18n.t("IOT.startSubAddressingFailed", {name: Name}) : i18n.t("IOT.stopSubAddressingFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }

}

export function stopAddressWST(dev, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.STOP_ADR_WST, null, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: i18n.t("IOT.stopAddressing", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.stopAddressingDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.stopAddressingFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }

}

export function stopAddressNRF(dev, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.STOP_ADDRESSING_NRF, null, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: i18n.t("IOT.stopAddressing", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.stopAddressingDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.stopAddressingFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }

}

export function getTime(dev, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_GATEWAY_TIME, null, null, onSuccess, onFailure);
    }

}

export function openSSH(dev, port) {
    const {ClientID, LocalUserID, GatewayID, isValid, Name} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.OPEN_SSH, {port}, {
            loading: {
                title: i18next.t("IOT.openSSH"),
                message: i18next.t("IOT.openSSH", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.openSSHSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.openSSHFailure", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }
}

export function ping(dev, onSuccess, onFailure, onSend) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.PING, null, null, onSuccess, onFailure, onSend);
    }
}


/**
 *
 * @param dev
 * @param rfids tablica stringów z numerami RFID do usunięcia
 * @param onSuccess
 * @param onFailure
 * @returns {string}
 */
export function deletePig(dev, rfids, onSuccess = () => {
    store.dispatch(removeRFIDsFromAnimalShadow(rfids));
}, onFailure = () => {
}) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    //console.log(ClientID, LocalUserID, GatewayID, DevID, Name, isValid);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.DELETE_PIG, {RFIDs: rfids}, {
            loading: {
                title: i18next.t("IOT.setDeletePig"),
                message: i18next.t("IOT.setDeletePigText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setDeletePigSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setDeletePigFailure", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }

}

export function synchronizeDevicesOnBackend(dev, DevIDs, showNotification) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_SYNC_DEVICES, {devices: DevIDs}, showNotification && {
            loading: {
                title: i18next.t("IOT.syncDevices"),
                message: i18next.t("IOT.syncDevicesText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.syncDevicesSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.syncDevicesFailure", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }
}

export function getNRFStats(dev, BridgeID, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_NRF_STATS, {DevID: BridgeID}, null, onSuccess, onFailure);
    }
}

export function setPlcmnts(dev, plcmnts) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_PLCMNTS, plcmnts, {
            loading: {
                title: i18next.t("IOT.setPlcmnts"),
                message: i18next.t("IOT.setPlcmntText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setPlcmntSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setPlcmntFailure", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }
}

/**
 *
 * @param dev
 * @param type
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @returns {string}
 */
export function getSchema(dev, type, onSend = () => {
}, onSuccess = () => {
}, onFailure = () => {
}) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_SCHEMA, {
            type: type || "DI_NRF"
        }, {
            loading: {
                title: i18next.t("IOT.getSchema"),
                message: i18next.t("IOT.getSchemaText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.getSchemaSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.getSchemaFailure", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }
}

export function getQueuesLastSuccess(dev, onSuccess, onFailed) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_QUEUES_LAST_SUCCESS, null, null, onSuccess, onFailed);
    }
}

/**
 * Pobiera dane na rozne sposoby
 * 1. {RFID} - zwraca dane dla zwierzecia na podstawie RFID
 * 2. {AnmID} - zwraca dane dla zwierzecia na podstawie AnmID
 * 3. {PlcmntID, sortBy [consumption | RFID], acending [true | false], page, pageSize} - pobieranie zwierząt dla lokalizacji z mozliwoscia sortowania, i paginacji
 * @param dev
 * @param data
 * @param onSuccess
 * @param onFailed
 * @return {string}
 */
export function getPigsData(dev, data, onSuccess, onFailed) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_PIGS_DATA, data, null, onSuccess, onFailed);
    }
}

/**
 * Pobiera dane dla klimatu ascii
 * @param dev {Device}
 * @param start {number}
 * @param stop {number}
 * @param granularity {number}
 * @param onSuccess {callback}
 */
export function getAsciiClimateTestingData(dev, start, stop, granularity, onSuccess) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_ASCII_CLIMATE_TESTING_DATA, {
            start,
            stop,
            granularity
        }, null, onSuccess);
    }
}

export function setScheduleOutput(dev, data, onSend = () => {
}, onSuccess = () => {
}, onFailure = () => {
}) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_SCHEDULE_OUTPUT, data, {
            loading: {
                title: i18next.t("IOT.setScheduleOutput"),
                message: i18next.t("IOT.setScheduleOutputText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setScheduleOutputSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setScheduleOutputError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }
}

export function setScheduleOutputMode(dev, data, onSend = () => {
}, onSuccess = () => {
}, onFailure = () => {
}) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_SCHEDULE_OUTPUT_MODE, data, {
            loading: {
                title: i18next.t("IOT.setScheduleOutputMode"),
                message: i18next.t("IOT.setScheduleOutputModeText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setScheduleOutputModeSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setScheduleOutputModeError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }
}

/**
 *
 * @param dev - gateway
 * @param DeviceIDs {array} {DevID: "asd" - zawsze, number: 1 - opcja}
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @return {string}
 */
export function clearAlarmsOnDevices(dev, {DeviceIDs} = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_CLEAR_PROBLEM, {DeviceIDs}, {
            loading: {
                title: i18next.t("IOT.clearDispenserNRFAlarm"),
                message: i18next.t("IOT.clearDispenserNRFAlarmText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.clearDispenserNRFAlarmSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.clearDispenserNRFAlarmError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }

}

/**
 *
 * @param dev - gateway
 * @param devices: [
 {
                //NRF
                DevID: 'asd',	//string
                correctionChange: -10 // {-50, -45, ..., 45, 50}
            },
 {
                //WST
                DevID: 'qwe',	//string
                dispensers: [
                    {
                        number: 1,	//1-20
                        correctionChange: -15	// {-50, -45, ..., 45, 50}
                    },
                    ...1 - 20
                ]
            },
 ...∞
 ]
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @return {string}
 */
export function changeDispensersDoseCorrection(dev, {devices} = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_CHANGE_DISPENSERS_DOSE_CORRECTION, {devices}, {
            loading: {
                title: i18next.t("IOT.setChangeDispensersDoseCorrection"),
                message: i18next.t("IOT.setChangeDispensersDoseCorrectionText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setChangeDispensersDoseCorrectionSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setChangeDispensersDoseCorrectionError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }

}

/**
 *
 * @param dev - gateway
 * @param devices: [
 {
		//NRF
		DevID: 'asd',	//string
		correction: -10 // {-50, -45, ..., 45, 50}
	},
 {
		//WST
		DevID: 'qwe',	//string
		dispensers: [
			{
				number: 1,	//1-20
				correction: -15	// {-50, -45, ..., 45, 50}
			},
			...1 - 20
		]
	},
 ...∞
 ]
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @return {string}
 */
export function setDispensersCondition(dev, {devices} = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_DISPENSERS_DOSE_CORRECTION, {devices}, {
            loading: {
                title: i18next.t("condition"),
                message: i18next.t("IOT.setConditionText", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setConditionSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setConditionError", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }

}

/**
 *
 * @param dev
 * @param devices devices: [
 {
		//NRF
		DevID: 'asd',	//string
		doses: 1, // {1 - 6}
		time: 456789987654 //opcjonalnie; timestamp
	},
 {
		//WST
		DevID: 'qwe',	//string
		dispensers: [
			{
				number: 1,	//1-20
				doses: 1, // {1 - 6}
				time: 456789987654 //opcjonalnie; timestamp
			},
			...1 - 20
		]
	},
 ...∞
 ]
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @return {string}
 */
export function skipDosesAfterTime(dev, {devices} = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_SKIP_DOSES_IN_TIME, {devices}, {
            loading: {
                title: i18next.t("IOT.skipDose"),
                message: i18next.t("IOT.setSkipDose", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSkipDoseDone", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSkipDoseFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }

}

export function getOnGoingAlarms(dev, {limit = 1000} = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_ALL_ONGOING_ALARMS, {
            limit
        }, undefined, onSuccess, onFailure, onSend);
    } else {
        if (_.isFunction(onFailure)) {
            onFailure();
        }
    }
}

export function getAllAlarms(dev, {limit = 0} = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_ALL_ALARMS, {
            limit
        }, undefined, onSuccess, onFailure, onSend);
    } else {
        if (_.isFunction(onFailure)) {
            onFailure();
        }
    }
}

export function getUsageForPig(dev, data, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_USAGE_FOR_PIG, data, null, onSuccess, onFailure);
    }
}

export function getFeedingDataForPig(dev, data, onSuccess, onFailure) {
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_FEEDING_DATA_FOR_PIG, data, null, onSuccess, onFailure);
    }
}

/**
 *
 * @param {Gateway} dev
 * @param {object} data
 * @param {array} data.animals tablica objektów {old: ..., new: ...} old i new maja postać {AnmID {string}, AnmNo1 {string|null}, RFID {string|null}, PlcmntID {string|null|array}}
 * @param {object} param2
 */
export function setAnimalChanges(dev, data, {onSuccess, onFailure} = {}) {
    console.log(data, "setAnimalChanges");
    const {ClientID, LocalUserID, GatewayID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_ANIMAL_CHANGES, data, null, onSuccess, onFailure);
    }
}

export function setNewInsertion(dev, data) {
    const {ClientID, LocalUserID, GatewayID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_NEW_INSERTION, data, {
            loading: {
                title: i18n.t("IOT.setNewInsertionTitle"),
                message: i18n.t("IOT.setNewInsertion", {name: Name}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setNewInsertionSuccess", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setNewInsertionFailed", {name: Name}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }

}
