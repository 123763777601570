import NewIOT from "../NewIOT";
import {ScaleHubCommandTypes} from "../../constans/mqttMessages";
import {getIOTAttributes} from "../../utils/IOTUtils";
import i18next from "i18next";

export function getWeightCalculated(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ScaleHubCommandTypes.GET_WEIGHT_CALCULATED);
    }
}

export function getConfiguration(dev, onSuccess, onFailure, onSend) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ScaleHubCommandTypes.GET_CONFIGURATION, null, null, onSuccess, onFailure, onSend);
    }
}

/**
 *
 * @param dev
 * @param data - dane do wyslania
 * @param data.sweepWeights - przemiatanie wyswietlanych wag na wyswietlaczu
 * @param data.isPin - czy panel jest chroniony przez pin
 * @param data.pin - pin od 0 do 9999
 * @param data.flashDisplay - czy ekran ma mrugac gdy przekroczymy minimalne wagi
 * @param data.minWeights - array obiektow {number: numerDozownika, value: minimalnaWaga}
 * @returns {string}
 */
export function setConfiguration(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ScaleHubCommandTypes.SET_CONFIGURATION, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setConfiguration") + Name,
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setConfigurationDone") + Name,
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setConfigurationFailed") + Name,
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function getWeightRow(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ScaleHubCommandTypes.GET_WEIGHT_RAW);
    }
}

export function getTaresScales(dev) {
    const {ClientID, LocalUserID, GatewayID, DevID, isValid} = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ScaleHubCommandTypes.GET_TARES_SCALES);
    }
}

/**
 *
 * @param dev
 * @param data - ustawienia tary
 * @param data.number - numer silosu
 * @param data.tare - wartosc tary
 * @returns {string}
 */
export function setTare(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ScaleHubCommandTypes.SET_TARE, data, {
            loading: {
                title: i18next.t("IOT.setTare"),
                message: i18next.t("IOT.setTareOn", {name: Name}) + i18next.t("IOT.scaleNr", {number: (data.number + 1)}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setTareOnDone", {name: Name}) + i18next.t("IOT.scaleNr", {number: (data.number + 1)}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setTareOnFailed", {name: Name}) + i18next.t("IOT.scaleNr", {number: (data.number + 1)}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setScale(dev, data) {
    const {ClientID, LocalUserID, GatewayID, DevID, Name, isValid} = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, ScaleHubCommandTypes.SET_SCALE, data, {
            loading: {
                title: i18next.t("IOT.setScale"),
                message: i18next.t("IOT.setScaleOn", {name: Name}) + i18next.t("IOT.scaleNr", {number: (data.number + 1)}),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setScaleOnDone", {name: Name}) + i18next.t("IOT.scaleNr", {number: (data.number + 1)}),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setScaleOnFailed", {name: Name}) + i18next.t("IOT.scaleNr", {number: (data.number + 1)}),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}