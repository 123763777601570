import {invokeApig} from "../libs/awsLib";
import {addNotification} from "reapop";
import store from "../store/store";
import {EventTypes} from "../constans/eventTypes";
import db from "../database/eventsDB";
import eventsDB from "../database/eventsDB";
import Paths from "../api/paths";
import {isFunction, cloneDeep} from "lodash";
import {EventSchema} from "validators-schema";
import i18next from "i18next";

const Ajv = require('ajv');

export function getAllEvents(FarmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_EVENTS",
            payload: db.getAllEventsForFarm(FarmID)
        })
    }
}

export function prepareEventsToUpdate(events) {
    let _events = cloneDeep(events);
    if (Array.isArray(_events)) {
        for (let event of _events) {
            delete event.$loki;
        }
    } else {
        delete _events.$loki;
    }
    return _events;
}

export function updateEvent(events, clientID, localUserID, onSuccess) {
    let _events = cloneDeep(events);
    _events = prepareEventsToUpdate(_events);
    return function (dispatch) {
        let notifi = {};
        dispatch({
            type: "UPDATE_EVENT", payload: invokeApig({
                ...Paths.updateEvent({clientID: clientID, localUserID: localUserID}),
                body: _events
            })
        }).then(() => {
            notifi.title = i18next.t("success");
            notifi.message = i18next.t("popNotifications.changesSaved");
            notifi.status = 'success';
            notifi.dismissible = true;
            notifi.dismissAfter = 3000;
            store.dispatch(addNotification(notifi));
            if (isFunction(onSuccess)) {
                onSuccess();
            }
        }).catch(err => {
            notifi.title = i18next.t("error");
            notifi.message = err + "";
            notifi.status = 'error';
            notifi.dismissible = true;
            notifi.dismissAfter = 3000;
            store.dispatch(addNotification(notifi));
        });

    }
}

export function createEvent(values, props, shouldFetch = true, notifi = {}, onSuccess = () => {
}, onFailure = () => {
}) {
    return function (dispatch) {
        //console.log("createEvent:",values);
        const ajv = new Ajv();
        values.events.forEach((ev) => {
            const valid = ajv.validate(EventSchema, ev);
            if (!valid) {
                console.error("AJV ERROR:", ajv.errors);
                throw new Error("Event is not valid.");
            }
        });
        dispatch({
            type: "CREATE_EVENT", payload: invokeApig({
                ...Paths.createEvent(),
                body: values
            })
        }).then(() => {
            if (props && props.hideModal) props.hideModal();
            let msg = i18next.t("animalsActions.successfullyAdded");
            if (props.type === EventTypes.FALL) msg += i18next.t("fall");
            if (props.type === EventTypes.TRANSFER) msg += i18next.t("transfer");
            if (props.type === EventTypes.SELL) msg += i18next.t("sale");
            if (props.type === EventTypes.GRAFTING) msg += i18next.t("animalsActions.grafting");
            if (props.type === EventTypes.INSEMINATION) msg += i18next.t("animalsActions.insemination");
            if (props.type === EventTypes.NO_PREGNANCY) msg += i18next.t("noPregnancy");
            if (props.type === EventTypes.PARTURITION) msg += i18next.t("birth");
            if (props.type === EventTypes.SEPARATION_TO_MOMMY) msg += i18next.t("animalsActions.mommy");
            if (props.type === EventTypes.USG) msg += i18next.t("usgResult");
            if (props.type === EventTypes.SEPARATION) msg += i18next.t("animalsActions.separation");
            if (props.type === EventTypes.JOIN) msg += i18next.t("animalsActions.merge");
            if (props.type === EventTypes.CHANGE_CURVE) msg += i18next.t("animalsActions.curveChange");
            if (props.type === EventTypes.MOMMY) msg += i18next.t("animalsActions.assignmentMommy");
            if (props.type === EventTypes.CONDITION) msg += i18next.t("animalsActions.condition");
            if (props.type === EventTypes.INCIDENT) msg += i18next.t("animalsActions.incident");
            if (props.type === EventTypes.SELECTION) msg += i18next.t("animalsActions.selection");
            if (props.type === EventTypes.TREATMENT) msg += i18next.t("animalsActions.grafting");
            if (props.type === EventTypes.WEIGHTING) msg += i18next.t("weighting");
            if (props.type === EventTypes.ACTIVE_NIPPLES) msg += i18next.t("activeNipplesAmount");
            if (props.type === EventTypes.HEAT) msg += i18next.t("animalsActions.heat");
            if (props.type === EventTypes.SOW_CYCLES) msg += i18next.t("animalsActions.sow_Cycles");
            notifi = {
                ...notifi,
                title: i18next.t("success"),
                message: msg,
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(notifi));
            eventsDB.insertIntoEvents(values.events);
            onSuccess();
        }).catch((e) => {
            console.error(e);
            onFailure();
        });

        dispatch({
            type: "CREATE_EVENT_TMP", payload: values
        })

    }
}

export function getUpdateCycleList(animal) {
    return function (dispatch) {
        dispatch({
            type: "LIST_EVENTS_FOR_ANIMALS_CYCLE_UPDATE",
            meta: {AnmID: animal.AnmID, animal: animal}
        })
    }
}

export function getEventsForAnimal(animal, LastEvaluatedKey = undefined) {
    return function (dispatch) {
        if (animal) {
            let querryParams = {FarmID: animal.FarmID, AnmID: animal.AnmID, partial: true};
            if (LastEvaluatedKey) {
                querryParams.DtaModTime = 0;
                querryParams.ESKDtaModTime = LastEvaluatedKey.DtaModTime;
                querryParams.ESKEvID = LastEvaluatedKey.EvID;
            }
            dispatch({
                type: "LIST_EVENT_4_ANM", payload: invokeApig({
                    ...Paths.listEvent({farmID: animal.FarmID}),
                    queryParams: querryParams
                }), meta: {AnmID: animal.AnmID, animal: animal}
            }).then(async res => {
                if (res.value.LastEvaluatedKey) {
                    dispatch(getEventsForAnimal(animal, res.value.LastEvaluatedKey))
                }
                dispatch({
                    type: "LIST_EVENT_4_ANIMAL_FULFILLED",
                    payload: res.value,
                    meta: {AnmID: animal.AnmID, animal: animal}
                });
            }).catch(error => {
                console.error(error);
                dispatch({
                    type: "LIST_EVENT_4_ANIMAL_FULFILLED",
                    payload: {
                        items: db.getAllEvents4Animal(animal.AnmID)
                    },
                    meta: {AnmID: animal.AnmID, animal: animal}
                })
            });
            dispatch({
                type: "LIST_EVENT_4_ANIMAL_LOADING",
                payload: animal.AnmID
            });

        } else {
            dispatch({
                type: "SET_ALL_ANIMALS_EMPTY"
            })
        }
    }
}

/**
 *
 * @param animalIDs
 * @param FarmID
 * @param meta doatkowa meta AnmGrp - przy pobieraniu z grup prosze cos takiego zamiescic
 * @return {function(...[*]=)}
 */
export function getEventsForAnimals(animalIDs, {FarmID = store.getState().location.farm, meta = {}} = {}) {
    return function (dispatch) {
        console.log(FarmID, animalIDs);
        dispatch({
            type: "LIST_EVENTS_FOR_ANIMALS",
            payload: invokeApig({
                ...Paths.listEventsForAnimals(),
                body: {
                    AnmIDs: animalIDs
                },
                queryParams: {
                    FarmID
                }
            }),
            meta: {...meta, AnmIDs: animalIDs}
        });
        dispatch({
            type: "LIST_EVENT_4_ANIMALS_LOADING",
            payload: animalIDs
        });
    }
}

export function getEvents(FarmID, LastEvaluatedKey = undefined) {
    return function (dispatch) {
        let querryParams = {FarmID: FarmID, DtaModTime: db.getModificationTime(FarmID).DtaModTime, partial: true};
        if (LastEvaluatedKey) {
            querryParams.DtaModTime = 0;
            querryParams.ESKDtaModTime = LastEvaluatedKey.DtaModTime;
            querryParams.ESKEvID = LastEvaluatedKey.EvID;
        }
        dispatch({
            type: "LIST_EVENT", payload: invokeApig({
                ...Paths.listEvent({farmID: FarmID}),
                queryParams: querryParams
            })
        }).then(async res => {
            let l = {};
            Object.assign(l, res);
            if (res.value.items && res.value.items.length > 0) {
                let tmp = [];
                Object.assign(tmp, res.value.items);
                await db.insertIntoEvents(tmp);
                dispatch(getAllEvents(FarmID));
            }
            if (res.value.LastEvaluatedKey) {
                dispatch(getEvents(FarmID, res.value.LastEvaluatedKey));
            }
        }).catch((e) => {
            console.error(e);
            dispatch(getAllEvents(FarmID))
        })
    }
}
