import {invokeApig} from '../libs/awsLib';
import devicesDB from "../database/devicesDB"
import {cutUselessPrefixes} from "../utils/DevicesUtils";
import Paths from "../api/paths";
import {synchronizeDevicesOnBackend} from "../IOT/device-functions/GatewayFunctions";
import {DevType} from "../constans/devices";
import * as TableNames from "validators-schema/Api/constants/tableNames";
import i18next from "i18next";
import {addNotification} from "reapop";

export function fetchDevices(farmID) {
    return function (dispatch) {
        dispatch({
            type: "FETCH_DEVICES", payload: invokeApig({
                ...Paths.listDevices({farmID: farmID}),
                queryParams: {
                    DtaModTime: "" + devicesDB.getModificationTime(farmID).DtaModTime
                    , table: "Devices"
                }
            })
        }).then(async res => {
            //console.log("CONNECTION",res);
            let l = {};
            Object.assign(l, res);
            //console.log("res", l);
            if (res.value.length > 0) {
                let tmp = [];
                Object.assign(tmp, res.value);
                await devicesDB.insertIntoDevices(tmp)
            }
            dispatch(getDevices(farmID));
        }).catch(error => {
            dispatch(getDevices(farmID));
        });
    }
}

export function createDeviceDynamoDB(values, FarmID, onSuccess = null) {
    let devices = cutUselessPrefixes(values);
    return function (dispatch) {
        dispatch({
            type: "CREATE_DEVICE", payload: invokeApig({
                ...Paths.createDevices({farmID: FarmID}),
                body: devices,
                queryParams: {table: TableNames.DEVICES}
            })
        }).then(() => {
            const notify = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.added"),
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(notify));
            if (onSuccess) onSuccess();
            let devices = Array.isArray(values) ? values : [values];
            let map = new Map();
            for (let device of devices) {
                let dev = device;
                if (device.DevType === DevType.BROADCAST || device.DevType === DevType.DI_TIME || device.DevType === DevType.BRIDGE_CONF || device.DevType === DevType.BRIDGE_WORK) {
                    dev = devices.find(item => item.DevType === DevType.BRIDGE) || devicesDB.getDeviceByID(device.ParentID);
                }
                let gw = devicesDB.getGateway(dev);
                let array = map.get(gw.DevID) || [];
                array.push(device);
                map.set(gw.DevID, array);
            }
            for (let [gatewayID, values] of map.entries()) {
                synchronizeDevicesOnBackend(gatewayID, values.map(device => device.DevID));
            }
        }).catch((e) => {
            console.error(e);
            const notify = {
                title: i18next.t("error"),
                message: e.message,
                status: 'error',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(addNotification(notify));
        });
        dispatch({type: "CREATE_DEVICE", payload: devices})
    }
}

export function updateDeviceDynamoDB(values, FarmID, clientID, localUserID, notifications, onSuccess, showSynchronizePopup = true) {
    let devices = cutUselessPrefixes(values);
    return function (dispatch) {
        dispatch({
            type: "UPDATE_DEVICE", payload: invokeApig({
                ...Paths.updateDevices({farmID: FarmID, clientID: clientID, localUserID: localUserID}),
                method: "PUT",
                body: devices,
                queryParams: {table: "Devices"}
            })
        }).then(() => {
            if (notifications && notifications.success) notifications.success();
            if (onSuccess) onSuccess();
            let devices = Array.isArray(values) ? values : [values];
            let map = new Map();
            for (let device of devices) {
                try {
                    let gw = devicesDB.getGateway(device);
                    let array = map.get(gw.DevID) || [];
                    array.push(device);
                    map.set(gw.DevID, array);
                } catch (e) {
                    console.error(e);
                }
            }
            for (let [gatewayID, values] of map.entries()) {
                synchronizeDevicesOnBackend(gatewayID, values.map(device => device.DevID), showSynchronizePopup);
            }
        }).catch(() => {
            if (notifications && notifications.error) notifications.error();
        });
        dispatch({type: "UPDATE_DEVICE", payload: devices})
    }
}

export function getDevices(farmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_DEVICES",
            payload: devicesDB.getDevices(farmID)
        });
    }
}

export function changeAddressingState(state) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_ADDRESSING_STATE",
            payload: state
        })
    }
}

export function updateDeviceParam(values, FarmID, clientID, localUserID, notifications, onSuccess, showSynchronizePopup = true) {
    return function (dispatch) {
        dispatch({
            type: "UPDATE_DEVICE_PARAMS", payload: invokeApig({
                ...Paths.deviceParam({farmID: FarmID, clientID: clientID, localUserID: localUserID}),
                body: {params: values}
            })
        }).then(() => {
            let gateways = new Map();
            for (let value of values) {
                let device = devicesDB.getDeviceByID(value.DevID);
                let gw = gateways.get(device.GatewayID) || new Set();
                gw.add(device.DevID);
                gateways.set(device.GatewayID, gw);
            }
            for (let [gatewayID, set] of gateways.entries()) {
                synchronizeDevicesOnBackend(gatewayID, [...set.values()], showSynchronizePopup);
            }
            if (notifications && notifications.success) notifications.success();
            if (onSuccess) onSuccess();
        }).catch(() => {
            if (notifications && notifications.error) notifications.error();
        });
    }
}