export function scannedRFID(rfid) {
    return function(dispatch) {
        dispatch({
            type: "SCANNED_RFID",
            payload: rfid
        })
    }
}

/**
 * Metoda dodaje wpis do ostatnich skanowanych rfid
 * @param {Object} rfidData - dane RFID
 * @param {string} rfidData.RFID - numer RFID
 * @param {Animal} rfidData.animal - obiekt zwierzęcia
 * @param {Object | null} rfidData.data - dane zdarzenia
 * @param {string} rfidData.type - typ zdarzenia
 */
export function pushLastScannedRFID(rfidData) {
    return function (dispatch) {
        dispatch({
            type: "PUSH_RFID_VALUES",
            payload: rfidData
        })
    }
}