import {invokeApig} from '../libs/awsLib';
import buildingsDB from "../database/buildingsDB"
import _ from "lodash";
import {loadingNotification, onErrorNotification, onSuccessNotification} from "../utils/ReapopNotificationUtils";
import {getLocationName} from "../utils/BuildingUtils";
import Paths from "../api/paths"
import {getFarm} from "../selectors/farmSelector";
import store from "../store/store"

/*
    ZAMIENIONO NA NOWE API 2019.04
 */

/**
 * Pobranie budynków z AWSa dla danej Farmy
 * @param FarmID
 * @returns {Function}
 */
export function fetchBuildingsByFarmID(FarmID) {
    return function (dispatch) {
        dispatch({
            type: "FETCH_BUILDINGS_BY_FARM_ID",
            payload: invokeApig({
                ...Paths.listBuilding({farmID: FarmID}),
                queryParams: {
                    FarmID: FarmID,
                    DtaModTime: parseInt(buildingsDB.getModificationTime(FarmID).DtaModTime)
                }
            })
        }).then(async res => {
                let l = {};
                Object.assign(l, res);
                if (res.value.length > 0) {
                    let tmp = [];
                    Object.assign(tmp, res.value);
                    await buildingsDB.insertIntoBuildings(tmp);
                }
                dispatch(getAllBuildings(FarmID));
            }
        ).catch(error => {
            dispatch(getAllBuildings(FarmID));
            console.error(error);
        })
    }
}


/**
 * Pobranie budynków z lokiego i wrzucenie ich do stora
 * @param id
 * @returns {Function}
 */
export function getAllBuildings(id) {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_BUILDINGS", payload: buildingsDB.getAllBuildingsForFarm(id)
        });
    }
}

/**
 * Funkcja usuwająca śmieci które są chyba dodawana do stora - po ogarnięciu stora można to usunać
 * @param buildingCopy2
 * @returns {*}
 */
export function deleteLocations(buildingCopy2) {
    const buildingCopy = _.cloneDeep(buildingCopy2);
    delete buildingCopy.$loki;
    delete buildingCopy.meta;
    delete buildingCopy.location;
    for (let i = 0; i < buildingCopy.Sectors.length; i++) {
        delete buildingCopy.Sectors[i].location;
        for (let j = 0; j < buildingCopy.Sectors[i].Chambers.length; j++) {
            delete buildingCopy.Sectors[i].Chambers[j].location;
            if (buildingCopy.Sectors[i].Chambers[j].Boxes) {
                for (let k = 0; k < buildingCopy.Sectors[i].Chambers[j].Boxes.length; k++) {
                    delete buildingCopy.Sectors[i].Chambers[j].Boxes[k].CID;
                    delete buildingCopy.Sectors[i].Chambers[j].Boxes[k].location;
                    delete buildingCopy.Sectors[i].Chambers[j].Boxes[k].curveNumber;
                }
            }
        }
    }
    return buildingCopy;
}

//todo: porobic nowe funkcje do zarzadzania budynkami zeby nie przyjmowaly 100 parametrow i byly proste w obsludze
/**
 * Funkcja która przyjmuje lokacje z zakresu: Building, Sector, Chamber, Box i updatują ją na AWS
 * @param location
 * @param onSuccess
 * @param onFailure
 */
export function updateLocation(location, onSuccess, onFailure) {
    //pobierz budynek w ktorym znajduje sie lokalizacja
    let notification = loadingNotification('Aktualizacja lokalizacji', getLocationName(location));
    let building = buildingsDB.getBuildingByLocationID(location.BgID || location.SID || location.CID || location.BID);
    if (!building) {
        throw new Error("Nie znaleziono budynku dla danej lokalizacji");
    }
    let locationCopy = _.cloneDeep(location);
    let buildingCopy = _.cloneDeep(building);
    //sprawdzamy jaki klucz identyfikacjiny lokalizacji ma wartosc
    let IDKey = ["BgID", "SID", "CID", "BID"].filter(key => locationCopy[key])[0];
    //pobieramy wartosc danego klucza
    const locationID = locationCopy[IDKey];
    if (!locationID) {
        throw new Error("Nie znaleziono numeru po którym mam zidentyfikować lokalizacje");
    }
    //sprawdzamy czy edytowana lokacja to glowny budynek
    if (IDKey === "BgID" && buildingCopy.BgID === locationID) {
        buildingCopy = locationCopy;
    } else {
        //jesli to nie byl budynek to sprawdzamy po kolei:
        //sektory
        if (buildingCopy.hasOwnProperty("Sectors")) {
            buildingCopy.Sectors.forEach((sector, sectorIndex) => {
                if (IDKey === "SID" && sector.SID === locationID) {
                    buildingCopy.Sectors[sectorIndex] = locationCopy;
                } else {
                    //komory
                    if (sector.hasOwnProperty("Chambers")) {
                        sector.Chambers.forEach((chamber, chamberIndex) => {
                            if (IDKey === "CID" && chamber.CID === locationID) {
                                buildingCopy.Sectors[sectorIndex].Chambers[chamberIndex] = locationCopy;
                            } else {
                                //boksy
                                if (chamber.hasOwnProperty("Boxes")) {
                                    chamber.Boxes.forEach((box, boxIndex) => {
                                        if (IDKey === "BID" && box.BID === locationID) {
                                            buildingCopy.Sectors[sectorIndex].Chambers[chamberIndex].Boxes[boxIndex] = locationCopy;
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
        }
    }
    buildingCopy = deleteLocations(buildingCopy);
    buildingCopy.DtaModTime = new Date().getTime();
    return function (dispatch) {
        dispatch({
            type: "UPDATE_LOCATION",
            payload: invokeApig({
                ...Paths.updateBuilding({farmID: buildingCopy.FarmID}),
                body: buildingCopy
            })
        }).then((res) => {
            onSuccess && onSuccess(res);
            onSuccessNotification('Zaktualizowano pomyślnie', notification);
            if(buildingCopy.FarmID==="CypressFarm1") {
                dispatch(fetchBuildingsByFarmID(buildingCopy.FarmID));
            }
        }).catch((e) => {
            onFailure && onFailure(e);
            onErrorNotification('Błąd aktualizacji', notification);
        });
    };
}

/**
 * Funkcja która usuwa podaną lokacje
 * @param location
 * @param onSuccess
 * @param onFailure
 */
export function deleteLocation(location, onSuccess, onFailure) {
    //pobranie LocalUserID oraz ClientID prosto ze stora zeby nie przekazywac tego bez sensu
    //dodanie notyfikacji - ladowanie
    let notification = loadingNotification('Usuwanie lokalizacji', (_.isArray(location) ? location.map(loc => getLocationName(loc)).join(",") : getLocationName(location)));
    //pobranie budynku w ktorym znajduje sie dana lokalizacja
    let building = buildingsDB.getBuildingByLocationID(location.BgID || location.SID || location.CID || location.BID);
    //jesli nie znajdziemy budynku wyrzucamy blad
    if (!building) {
        throw new Error("Nie znaleziono budynku dla danej lokalizacji");
    }
    //kopiujemy znaleziony budynek oraz lokalizacje zeby nic nie popsuc w obiektach
    let locationCopy = _.cloneDeep(location);
    let buildingCopy = _.cloneDeep(building);
    //wyszukujemy klucz ID dla danej lokalizacji
    let IDKey = ["BgID", "SID", "CID", "BID"].filter(key => locationCopy[key])[0];
    //pobieramy wartosc danego klucza
    const locationID = locationCopy[IDKey];
    //jesli nie znaleziono klucza wyrzucamy blad
    if (!locationID) {
        throw new Error("Nie znaleziono numeru po którym mam zidentyfikować lokalizacje");
    }
    //sprawdzamy czy usuwana lokacja to glowny budynek
    if (IDKey === "BgID" && buildingCopy.BgID === locationID) {
        buildingCopy.DtaDelTime = +new Date();
    } else {
        //jesli to nie byl budynek to sprawdzamy po kolei:
        //sektory
        if (buildingCopy.hasOwnProperty("Sectors")) {
            buildingCopy.Sectors.forEach((sector, sectorIndex) => {
                if (IDKey === "SID" && sector.SID === locationID) {
                    buildingCopy.Sectors.splice(sectorIndex, 1);
                } else {
                    //komory
                    if (sector.hasOwnProperty("Chambers")) {
                        sector.Chambers.forEach((chamber, chamberIndex) => {
                            if (IDKey === "CID" && chamber.CID === locationID) {
                                buildingCopy.Sectors[sectorIndex].Chambers.splice(chamberIndex, 1);
                            } else {
                                //boksy
                                if (chamber.hasOwnProperty("Boxes")) {
                                    chamber.Boxes.forEach((box, boxIndex) => {
                                        if (IDKey === "BID" && box.BID === locationID) {
                                            buildingCopy.Sectors[sectorIndex].Chambers[chamberIndex].Boxes.splice(boxIndex, 1);
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
        }
    }
    //usuniecie smieci typu metadane, dodanie daty modyfikacji itp
    buildingCopy = deleteLocations(buildingCopy);
    return function (dispatch) {
        dispatch({
            type: "DELETE_LOCATION",
            payload: invokeApig({
                ...Paths.updateBuilding({farmID: buildingCopy.FarmID}),
                body: buildingCopy
            })
        }).then((res) => {
            onSuccess && onSuccess(res);
            onSuccessNotification('Usunięto pomyślnie', notification);
            if(buildingCopy.FarmID==="CypressFarm1") {
                dispatch(fetchBuildingsByFarmID(buildingCopy.FarmID));
            }
        }).catch((e) => {
            onFailure && onFailure(e);
            onErrorNotification('Błąd usuwania', notification);

        })
    };
}

/**
 * Funkcja która dodaję lokacje do podanego ParentID
 * @param location
 * @param ParentID
 * @param onSuccess
 * @param onFailure
 * @returns {Function}
 */
export function addLocation(location, ParentID, onSuccess, onFailure) {
    let notification = loadingNotification('Dodawanie lokalizacji', (_.isArray(location) ? location.map(loc => getLocationName(loc)).join(",") : getLocationName(location)));
    //sprawdz do czego podpieta jest nowa lokacja
    let isFarm = getFarm(store.getState(), ParentID);
    //jesli jest to nowy budynek (parentID to farma) to tworzymy nowy budynek
    if (isFarm) {
        //todo: create new building
        return function (dispatch) {
            dispatch({
                    type: "ADD_LOCATION",
                    payload: invokeApig({
                        ...Paths.createBuilding(),
                        body: location
                    })
                }
            ).then(res => {
                onSuccess && onSuccess(res);
                onSuccessNotification('Dodano pomyślnie', notification);
                if(isFarm.FarmID==="CypressFarm1") {
                    dispatch(fetchBuildingsByFarmID(isFarm.FarmID));
                }
            }).catch((e) => {
                onFailure && onFailure(e);
                onErrorNotification('Błąd dodawania', notification);
            });
        }
    } else {
        let building = buildingsDB.getBuildingByLocationID(ParentID);
        if (!building) {
            throw new Error("Nie znaleziono budynku dla danej lokalizacji");
        }
        let locationCopy = _.cloneDeep(location);
        if (!_.isArray(locationCopy)) {
            locationCopy = [locationCopy];
        }
        let buildingCopy = _.cloneDeep(building);
        //sprawdzamy jaki klucz identyfikacjiny lokalizacji ma wartosc
        //pobieramy wartosc danego klucza
        if (!ParentID) {
            throw new Error("Nie znaleziono numeru po którym mam zidentyfikować lokalizacje");
        }
        //sprawdzamy czy dodajemy do glownego budynku
        if (buildingCopy.BgID === ParentID) {
            let sectors = _.get(buildingCopy, "Sectors", []);
            sectors.push(...locationCopy);
            buildingCopy.Sectors = sectors;
        } else {
            if (buildingCopy.hasOwnProperty("Sectors")) {
                buildingCopy.Sectors.forEach((sector, sectorIndex) => {
                    if (sector.SID === ParentID) {
                        let chambers = _.get(sector, "Chambers", []);
                        chambers.push(...locationCopy);
                        buildingCopy.Sectors[sectorIndex].Chambers = chambers;
                    } else {
                        //komory
                        if (sector.hasOwnProperty("Chambers")) {
                            sector.Chambers.forEach((chamber, chamberIndex) => {
                                if (chamber.CID === ParentID) {
                                    if (!chamber.IndividualFeeding) {
                                        throw new Error("Komora z grupowym karmieniem nie może mieć stanowisk")
                                    }
                                    let boxes = _.get(chamber, "Boxes", []);
                                    boxes.push(...locationCopy);
                                    buildingCopy.Sectors[sectorIndex].Chambers[chamberIndex].Boxes = boxes;
                                } else {
                                    //boksy
                                    if (chamber.hasOwnProperty("Boxes")) {
                                        chamber.Boxes.forEach((box) => {
                                            if (box.BID === ParentID) {
                                                throw new Error("Box to najniższa lokalizacja i nie może być rodzicem")
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })
            }
        }
        buildingCopy = deleteLocations(buildingCopy);
        return function (dispatch) {
            dispatch({
                type: "ADD_LOCATION",
                payload: invokeApig({
                    ...Paths.updateBuilding(),
                    body: buildingCopy
                })
            }).then((res) => {
                onSuccess && onSuccess(res);
                onSuccessNotification('Dodano pomyślnie', notification);
                if(buildingCopy.FarmID==="CypressFarm1") {
                    dispatch(fetchBuildingsByFarmID(buildingCopy.FarmID));
                }
            }).catch((e) => {
                onFailure && onFailure(e);
                onErrorNotification('Błąd dodawania', notification);
            })
        };
    }

}

